import React from 'react';
import useAuth from "../hooks/useAuth";
import {useAppState} from "../state/AppStateProvider";
import {Box} from "@mui/material";
import {AlmanakLogo} from "../components/AlmanakLogo";
import Chip from "@mui/material/Chip";
import UserDropdown from "../components/wallet-connect/UserDropdown";
import Sidebar from "../components/layout/Sidebar";
import {FlexBox} from "../views/simulation/create-simulation/styled";
import {Badge, Button, Flex, IconButton} from "@radix-ui/themes";
import SocialButtons from "../components/layout/SocialButtons";
import {ExitIcon} from "@radix-ui/react-icons";

type WhitelistManagerProps = {
    children: React.ReactNode;
};

export const earlyAccessUrl = "https://almanakai.typeform.com/beta-access";

const WhitelistManager = ({children}: WhitelistManagerProps): JSX.Element | null => {
    const {user, isWalletConnecting} = useAppState();

    const userPublicKey = user?.publicKey ?? localStorage.getItem("userPublicKey");

    const {logout} = useAuth();
    const isWhitelisted = user && user?.isWhitelisted;

    const applyForAccess = () => {
        window.open(earlyAccessUrl, "_blank");
    }



    if (user && !isWalletConnecting && !isWhitelisted) {
        return <div className="container text-center" style={{width: "auto"}}>
            <Box sx={{display: "flex", marginTop: "40px", marginBottom: "24px", alignItems: "center"}}>
                <AlmanakLogo size={160}/>
                <Badge color={"green"} size={"3"} ml={"-26px"} mt={"-20px"}> Beta</Badge>
            </Box>

            <span style={{textAlign: "center", fontSize: "28px", marginTop: "-8px"}}>Almanak is currently in Closed Beta.</span>

            <span style={{wordBreak:"break-all", fontWeight: "bold", textAlign: "center", fontSize: "16px", marginTop: "16px",}}>
                {userPublicKey} is not whitelisted.
            </span>


            <span style={{textAlign: "center", fontSize: "16px", marginTop: "16px", maxWidth: "420px"}}>
                Follow us on socials to stay updated on our progress and be the first to know when we open up!
            </span>

            <Flex p={"4"} width={"200px"} gap={"4"} direction={"column"} align={"center"}>
                <SocialButtons/>
                <Button onClick={applyForAccess}>Apply for Beta Access</Button>

                <div>
                    <Button variant={"outline"} onClick={logout}><ExitIcon/>Logout</Button>
                </div>
            </Flex>


        </div>
    }

    return (
        <>
            {children}
        </>
    );
}

export default WhitelistManager;
