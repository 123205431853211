import {Card, Spinner} from "@radix-ui/themes";
import { UpdateIcon, CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { VersionSecurityStatus } from "../../widgets/StrategyLibraryWidget";

export const getScanStatusDisplay = (status: VersionSecurityStatus) => {
    switch(status) {
    case VersionSecurityStatus.AWAITING_SECURITY_SCAN:
        return {
        icon: <UpdateIcon />,
        color: "var(--gray-9)",
        label: "Awaiting Scan"
        };
    case VersionSecurityStatus.SCANNING:
        return {
        icon: <Spinner size="1" />,
        color: "var(--blue-9)", 
        label: "Scanning"
        };
    case VersionSecurityStatus.SECURITY_SCAN_SUCCESS:
        return {
        icon: <CheckCircledIcon />,
        color: "var(--green-9)",
        label: "Scan Passed"
        };
    case VersionSecurityStatus.SECURITY_SCAN_FAIL:
        return {
        icon: <CrossCircledIcon />,
        color: "var(--red-9)",
        label: "Scan Failed"
        };
    }
};