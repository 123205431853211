import useSWR from "swr";
import {fetchAgentLogs} from "../../api/hasura";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Callout, Flex, Spinner, Text} from "@radix-ui/themes";
import {formatPubKey} from "../../utils/commonUtils";
import LogsFilter from "./LogsFilter";

type AgentLog = {
    id: string;
    timestamp: number;
    message: string;
};

export type AgentLogsResponse = {
    nextPageToken?: string;
    logs: AgentLog[];
};

type LogsFilter = {
    text?: string;
    severity?: string;
    toTop?: () => void;
    toBottom?: () => void;
}

export const AgentLogs = ({agentId, toggleFullscreen, isFullscreen}: { agentId?: string, toggleFullscreen?: () => void, isFullscreen?: boolean}) => {
    const logsEndRef = useRef<HTMLDivElement | null>(null);
    const logsContainerRef = useRef<HTMLDivElement | null>(null);
    const [displayedAgentLogs, setDisplayedAgentLogs] = useState<any>(null);
    const [nextPageToken, setNextPageToken] = useState<string | any>(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [initiallyScrolledDown, setInitiallyScrolledDown] = useState(false);
    const scrollPositionRef = useRef<number | null>(null);
    const [logsFilter, setLogsFilter] = useState<LogsFilter | null>(null)

    const [isFiltering, setIsFiltering] = useState(false);

    const fetchNewAgentLogs = async (agentId: string, severity?: string, text?: string) => {
        if (!agentId) {
            return;
        }
        const newestFetch = await fetchAgentLogs(agentId, undefined, severity, text);

        if (displayedAgentLogs === null) {
            setDisplayedAgentLogs(newestFetch.logs);

            if (!nextPageToken) {
                setNextPageToken(newestFetch.nextPageToken);
            }
        } else {

            const uniqueLogs = newestFetch.logs.filter((log: any) => !displayedAgentLogs?.some((dLog: any) => (dLog.id === log.id)));

            setDisplayedAgentLogs((prevState: any) => {
                return [...(prevState ?? []), ...uniqueLogs]
            });
        }
    }


    const {
        data: agentLogsData,
        isLoading: isLogsLoading,
        mutate: mutateAgentLogs,
        isValidating: isLogsValidating,
        error: agentLogsError,
    } = useSWR<any>(
        `${agentId}-logs`,
        agentId ? () => fetchNewAgentLogs(agentId, logsFilter?.severity, logsFilter?.text) : null,
        {
            // This will automatically refetch the data 5 seconds
            refreshInterval: isFiltering ? 0 : 5000
        }
    );

    const loadMoreLogs = async () => {


        if (!nextPageToken || !agentId) {
            console.error("No page token or no ID");
            return;
        }

        setIsLoadingMore(true);
        try {
            const nextPageData = await fetchAgentLogs(agentId, nextPageToken, logsFilter?.severity, logsFilter?.text);
            const newLogs = nextPageData.logs.filter((log: any) => !displayedAgentLogs.some((dLog: any) => (dLog.id === log.id)));

            setDisplayedAgentLogs((prevLogs: any) => [
                ...newLogs,
                ...(prevLogs ?? []),
            ]);
            setNextPageToken(nextPageData.nextPageToken);


        } catch (error) {
            console.error("Error loading more logs:", error);
        } finally {
            setIsLoadingMore(false);
        }
    };

    const handleScroll = useCallback(() => {
        if (logsContainerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = logsContainerRef.current;
            if (scrollTop === 0 && !isLoadingMore && nextPageToken) {
                setIsLoadingMore(true);
                loadMoreLogs();
            } else {
                scrollPositionRef.current = scrollTop;
            }
        }
    }, [logsContainerRef, isLoadingMore, nextPageToken]);

    useEffect(() => {
        const container = logsContainerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [logsContainerRef, handleScroll]);

    // useEffect(() => {
    //     if (scrollPositionRef.current !== null && logsContainerRef.current) {
    //         logsContainerRef.current.scrollTop = scrollPositionRef.current;
    //     }
    // }, [displayedAgentLogs]);

    useEffect(() => {
        //handle the initial scroll down when first page is loaded
        if (logsEndRef.current && displayedAgentLogs?.length > 0 && !initiallyScrolledDown) {
            logsEndRef.current.scrollIntoView();
            setInitiallyScrolledDown(true);
        }

        //handle the case where there's already logs available, and the user scrolls up to load more logs
        if (scrollPositionRef.current !== null && logsContainerRef.current) {
            logsContainerRef.current.scrollTop = scrollPositionRef.current;
        }



    }, [displayedAgentLogs, initiallyScrolledDown]);


    const onFilterChange = async (text: string, severity: string) => {

        setIsFiltering(true); // Stop auto-refresh
        setLogsFilter({ text, severity });

        setDisplayedAgentLogs(null);

        if (agentId) {
            await fetchNewAgentLogs(agentId, severity, text);
            mutateAgentLogs(); // Manually refetch
        }

        setIsFiltering(false); // Resume auto-refresh after filtering
    };

    return (
        <>
            {!isLogsLoading && agentLogsError && (
                <Callout.Root color="red">
                    <Flex align={"center"} gap={"8px"}>
                        <Callout.Text>Error fetching logs! {agentLogsError?.message}</Callout.Text>
                    </Flex>
                </Callout.Root>
            )}

            {!nextPageToken && displayedAgentLogs?.length === 0 && (
                <Callout.Root color="blue">
                    <Flex align={"center"} gap={"8px"}>
                        <Callout.Text>No logs available!</Callout.Text>
                    </Flex>
                </Callout.Root>
            )}

            {/*floating logs filter container*/}
            <LogsFilter onFilterChange={(text, severity) => onFilterChange(text, severity)}
                        toTop={() => logsContainerRef.current?.scrollTo({top: 0})}
                        toBottom={() => logsEndRef.current?.scrollIntoView()}
                        toggleFullscreen={toggleFullscreen}
                        isFullscreen={isFullscreen}
            />


            {!agentLogsError && (
                <div ref={logsContainerRef} style={{height: isFullscreen ? "calc(100vh - 190px)" : "calc(100vh - 520px)", overflowY: "scroll"}}>
                    {isLoadingMore && (
                        <Flex direction="row" align="center" justify="center" gap="3" py="8">
                            Loading... <Spinner size="2"/>
                        </Flex>
                    )}

                    {!nextPageToken && displayedAgentLogs?.length > 0 ? (
                        <Flex direction="row" align="start" justify="start" gap="3" pb="2">
                            <Text weight={"bold"} size="2">Start of logs data...</Text>
                        </Flex>
                    ) : null}

                    <div className="flex-1 overflow-y-auto p-4">
                        {displayedAgentLogs?.map((log: any, index: number) => (
                            <div key={index + log.id} className="text-sm mb-2">
                                <Text color={log.severity === "ERROR" ? "red" : "gray"}>
                                    <div>
                                        {new Date(log.timestamp).toLocaleTimeString('en-GB', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}{" "}
                                        <Text>{log.message}</Text>
                                    </div>
                                </Text>
                            </div>
                        ))}
                        {<Flex direction="row" justify="center" gap="3" py="2">
                            {isLogsValidating ? <Flex gap={"3"} justify={"center"} align={"center"}>Loading... <Spinner size="1"/></Flex> : <Flex py={"2"}/>}
                        </Flex>}
                        <div ref={logsEndRef}/>
                    </div>
                </div>
            )}
        </>
    );
};
