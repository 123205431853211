import { Box } from "@mui/material";
import { Card, Button, Text } from "@radix-ui/themes";
import { Info } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useAppState } from "../../state/AppStateProvider";
import { useEffect, useState } from "react";
import { updateUser, isTelegramConnected, getTelegramRegistrationToken, disconnectTelegram } from "../../api/hasura";
import { isStageEnv, LDKeyTelegram } from "../../components/layout/Sidebar";
import { CopyIcon } from "@radix-ui/react-icons";
import useSWR from "swr";

export default function NotificationSettings() {
    const { user, setUser, setSnackBar, featureFlags } = useAppState();
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [telegramToken, setTelegramToken] = useState("");
    const [tokenLoading, setTokenLoading] = useState(false);
    const [manualChecking, setManualChecking] = useState(false);

    const [emailForm, setEmailForm] = useState({
        notification_email: user?.notification_email ?? "",
    });

    const isTelegramEnabled = featureFlags?.[LDKeyTelegram];

    const { 
        data: telegramStatus, 
        mutate: mutateTelegramStatus,
        isValidating: isValidatingTelegram
    } = useSWR(
        isTelegramEnabled ? 'telegram-connection-status' : null,
        async () => {
            // Start both API calls simultaneously
            const connectionPromise = isTelegramConnected();
            
            // Always start the token fetch if we don't have one
            let tokenPromise = null;
            if (!telegramToken) {
                setTokenLoading(true);
                tokenPromise = getTelegramRegistrationToken()
                    .then(result => {
                        if (result.valid) {
                            setTelegramToken(result.token);
                        }
                        return result;
                    })
                    .catch(error => {
                        console.error("Failed to get registration token:", error);
                    })
                    .finally(() => {
                        setTokenLoading(false);
                    });
            }
            
            // Wait for the connection status
            const result = await connectionPromise;
            
            // If there was a token promise, make sure it completes too
            if (tokenPromise) {
                await tokenPromise;
            }
            
            return result;
        },
        {
            revalidateOnFocus: true,
            revalidateOnReconnect: true,
            keepPreviousData: true,
            revalidateOnMount: true
        }
    );

    // Only show loading state on initial load, not during revalidation
    const checkingConnection = !telegramStatus && isValidatingTelegram;

    const handleCheckStatus = async () => {
        setManualChecking(true);
        await mutateTelegramStatus();
        setManualChecking(false);
    };

    const handleEmailUpdate = async () => {
        if (!user?.id) return;

        try {
            setIsLoading(true);
            await updateUser(user.id, {
                notification_email: emailForm.notification_email,
            });

            setUser({
                ...user,
                notification_email: emailForm.notification_email,
            });

            setSnackBar({
                open: true,
                message: "Email updated successfully",
                severity: "success",
            });
            setIsEditing(false);
        } catch (error: any) {
            setSnackBar({
                open: true,
                message: error.message || "Failed to update email",
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyCommand = () => {
        const registrationCommand = `/register ${telegramToken}`;
        navigator.clipboard.writeText(registrationCommand);
        setSnackBar({
            open: true,
            message: "Command copied to clipboard",
            severity: "success",
        });
    };

    const handleOpenTelegram = () => {
        const botName = isStageEnv ? "AlmanakNotificationsStagingBot" : "AlmanakNotificationsBot";
        window.open(`https://t.me/${botName}`, '_blank');
    };

    const handleDeregister = async () => {
        try {
            setIsLoading(true);
            const result = await disconnectTelegram();
            
            if (result.valid) {
                // Then refresh the status and get a new token if needed
                await mutateTelegramStatus();
                
                setSnackBar({
                    open: true,
                    message: "Telegram disconnected successfully",
                    severity: "success",
                });
            } else {
                throw new Error(result.message || "Failed to disconnect Telegram");
            }
        } catch (error: any) {
            setSnackBar({
                open: true,
                message: error.message || "Failed to disconnect Telegram",
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const inputStyles = {
        width: "100%",
        padding: "12px",
        borderRadius: "8px",
        border: "1px solid var(--gray-6)",
        fontSize: "14px",
        fontFamily: "inherit",
    };

    return (
        <Box sx={{ maxWidth: "740px", width: "100%" }}>
            <Box sx={{ my: 2, display: "flex", justifyContent: "space-between" }}>
                <Text size="5" weight="bold">Notification Settings</Text>
            </Box>

            {/* Notification Email */}
            <Card style={{ padding: '24px', marginBottom: '16px' }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Text size="2" weight="bold">Notification Email</Text>
                        <Tooltip title="You will receive notifications when your simulations finish to this e-mail address">
                            <IconButton size="small">
                                <Info sx={{ width: 16, height: 16 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {isEditing ? (
                        <Box>
                            <input
                                type="email"
                                placeholder="Enter notification email"
                                value={emailForm.notification_email}
                                onChange={(e) =>
                                    setEmailForm({
                                        notification_email: e.target.value,
                                    })
                                }
                                style={inputStyles}
                            />
                        </Box>
                    ) : (
                        <Text size="3">
                            {user?.notification_email || "No email set"}
                        </Text>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                        {isEditing ? (
                            <>
                                <Button
                                    size="2"
                                    variant="soft"
                                    onClick={() => {
                                        setIsEditing(false);
                                        setEmailForm({
                                            notification_email: user?.notification_email ?? ""
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    size="2"
                                    onClick={handleEmailUpdate}
                                    loading={isLoading}
                                >
                                    Save Changes
                                </Button>
                            </>
                        ) : (
                            <Button
                                size="2"
                                variant="soft"
                                onClick={() => setIsEditing(true)}
                            >
                                Edit
                            </Button>
                        )}
                    </Box>
                </Box>
            </Card>
            
            {/* Telegram */}
            {isTelegramEnabled && (
                <Card style={{ padding: '24px' }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Text size="2" weight="bold">Telegram</Text>
                            <Tooltip title="Connect your Telegram account to receive notifications">
                                <IconButton size="small">
                                    <Info sx={{ width: 16, height: 16 }} />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        {checkingConnection ? (
                            <Text size="3">Checking connection status...</Text>
                        ) : !telegramStatus?.connected ? (
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                <Box>
                                    <Text size="2" weight="bold" mb="2">1. Copy registration command:</Text>
                                    <Box sx={{
                                        display: "flex",
                                        gap: 1,
                                        alignItems: "center",
                                        backgroundColor: "var(--gray-3)",
                                        padding: "12px",
                                        borderRadius: "8px",
                                        mt: 1
                                    }}>
                                        {tokenLoading || !telegramToken ? (
                                            <Text size="2" style={{ color: 'var(--gray-10)' }}>Loading token...</Text>
                                        ) : (
                                            <>
                                                <input
                                                    readOnly
                                                    value={`/register ${telegramToken}`}
                                                    style={{
                                                        ...inputStyles,
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        padding: "0",
                                                        outline: "none",
                                                        cursor: "text"
                                                    }}
                                                />
                                                <IconButton onClick={handleCopyCommand} size="small">
                                                    <CopyIcon width={19} height={19}/>
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                </Box>

                                <Box>
                                    <Text size="2" weight="bold" mb="2">2. Start chat:</Text>
                                    <Box sx={{ mt: 2 }}>
                                        <Button
                                            size="2"
                                            onClick={handleOpenTelegram}
                                        >
                                            Open Chat
                                        </Button>
                                    </Box>
                                </Box>

                                <Text size="2" weight="bold">3. Paste command in the chat</Text>
                                
                                <Box sx={{ 
                                    display: "flex", 
                                    justifyContent: "flex-end",
                                    mt: 2 
                                }}>
                                    <Button
                                        size="2"
                                        variant="soft"
                                        onClick={handleCheckStatus}
                                        loading={manualChecking}
                                    >
                                        Check Connection Status
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                <Text size="3">Connected</Text>
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        size="2"
                                        variant="soft"
                                        color="red"
                                        onClick={handleDeregister}
                                        loading={isLoading}
                                    >
                                        Deregister
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Card>
            )}
        </Box>
    );
}