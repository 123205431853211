import {SafeEventEmitterProvider} from "@web3auth/base";
import {useAppState} from "../state/AppStateProvider";
import jwt from 'jwt-decode'
import * as LDClient from 'launchdarkly-js-client-sdk';


import {getApiAccessToken, getUserTeam} from "../api/hasura";
import EthereumRpc from "../auth/web3RPC";
import {useNavigate} from "react-router-dom";
import LogRocket from "logrocket";
import axios from "axios";
import {EthereumPrivateKeyProvider} from "@web3auth/ethereum-provider";
import {CoinbaseAdapter} from "@web3auth/coinbase-adapter";
import {usePrivy, useLogin, useWallets} from "@privy-io/react-auth";
import {useEffect, useMemo} from "react";


export type UserData = {
    isWhitelisted: boolean,
    id: string,
    username: string;
    userPlatformId: string,
    main_name_service?: string;
    publicKey: string, // Personal use, auth-related
    isOnboarded: boolean,
    organisationId: string,
    teamId: string,
    is_admin?: boolean;
    "https://hasura.io/jwt/claims": {
        "x-hasura-default-role": string,
        "x-hasura-allowed-roles": string[],
        "x-hasura-user-id": string,
        "x-hasura-user-platform-id": string,
    },
    iss: string,
    "aud": "starry-agency-339312",
    auth_time: number,
    user_id: string,
    email?: string,
    notification_email?: string;
    email_verified: boolean,
    firebase?: {
        identities: {
            email: string[]
        },
        sign_in_provider: string
    },
    profile_url?: string,
    bio?: string,
    created_at: string,
    wallet_address: string // Used for displaying profile of other users
    x_handle?: string,
}
const useAuth = () => {
    const {
        // provider,
        web3Auth,
        setProvider,
        setWeb3Auth,
        setUser,
        setIsWalletConnecting,
        setSnackBar,
        setReloadCreditBalance,
        cliAuthPort,
        cliAuthentication,
        setFeatureFlags
    } = useAppState();

    const {logout: logoutPrivy, user, connectWallet} = usePrivy();
    const {ready, wallets} = useWallets();

    const navigate = useNavigate();

    const getAccounts = async (provider: SafeEventEmitterProvider) => {
        if (!provider) {
            throw new Error("getAccounts Provider not initialized yet");
        }
        const rpc = new EthereumRpc(provider);
        return await rpc.getAccounts();
    };

    const initFirebaseSession = async (idToken: string, userAddress?: string, inviteCode?: string) => {
        if (!idToken || !userAddress) {
            web3Auth?.logout()
            setSnackBar({
                open: true,
                message: "No id token or no user address defined. Logged user out",
                severity: "error"
            });
            throw new Error("No id token or no user address defined")
        }
        return await getApiAccessToken(idToken, userAddress, inviteCode);
    }


    useEffect(() => {
        const fetchProvider = async () => {

            if (!wallets) {
                return;
            }

            try {
                const connectedWallet = wallets?.[0];
                const provider = await connectedWallet?.getEthereumProvider();


                // const provider = await wallets.find(wallet => wallet.address === userPublicKey)?.getWeb3jsProvider();
                // console.log("provider", provider);

                if (provider) {
                    setProvider(provider);
                }
            } catch (error) {
                console.error("Error fetching provider:", error);
            }
        };

        fetchProvider();
    }, [wallets, user]);


    const provider = useMemo(() => {
        const userPublicKey = user?.wallet?.address;

        if (!userPublicKey || !wallets) {
            return null;
        }

        return wallets.find(wallet => wallet.address === userPublicKey)?.getEthereumProvider();

    }, [wallets, user])

    const initSessionWithPrivy = async (idToken: string, userPublicKey: string) => {

        try {

            const inviteCode = localStorage.getItem("inviteCode") ?? "";
            const firebaseSession = await initFirebaseSession(idToken, userPublicKey, inviteCode);

            localStorage.setItem("accessToken", firebaseSession.data.verifyWeb3Auth.jwt)
            localStorage.setItem("userPublicKey", userPublicKey);

            let userFromJwt: any = jwt(firebaseSession.data.verifyWeb3Auth.jwt);
            let userId = await userFromJwt["https://hasura.io/jwt/claims"]["x-hasura-user-platform-id"];
            const userOrganization = await getUserTeam(userId);

            //only record user sessions in production
            if (process.env.REACT_APP_ENVIRONMENT === "production") {
                LogRocket.identify(userPublicKey, {
                    address: userPublicKey,
                    platformUserId: userId,
                    whitelisted: true
                });
            }

            //only track launch darkly flags if there's an id
            if (process.env.REACT_APP_LAUNCHDARKLY_ID) {
                const LDUser = {
                    "kind": "user",
                    "key": userId,
                    "name": userFromJwt.name,
                    "email": userFromJwt.email
                };
                const ldclient = LDClient.initialize(process.env.REACT_APP_LAUNCHDARKLY_ID, LDUser);
                ldclient.on("ready", () => {
                    const flagData = ldclient.allFlags();
                    setFeatureFlags(flagData);
                });
            }

            setUser({
                ...userFromJwt,
                ...userOrganization?.user,
                id: userId,
                publicKey: userPublicKey as string,
                isOnboarded: true,
                organisationId: userOrganization?.organisation_id,
                teamId: userOrganization?.team_id,
                isWhitelisted: true
            })
            setReloadCreditBalance(true);

        } catch (error: any) {
            if (error.message === "User is not whitelisted") {
                console.error("User is not whitelisted.", userPublicKey)
                setSnackBar({open: true, message: "User is not whitelisted", severity: "error"});
                // @ts-ignore
                setUser(prevState => ({...prevState, isWhitelisted: false, publicKey: userPublicKey}));
                if (process.env.REACT_APP_ENVIRONMENT === "production") {
                    LogRocket.identify(userPublicKey, {
                        whitelisted: false,
                        address: userPublicKey
                    });
                }
            }
        } finally {
            setIsWalletConnecting(false);
        }
    };


    const logout = async () => {
        try {
            setIsWalletConnecting(true);
            await logoutPrivy();
            localStorage.clear();
            setProvider(null);
            setUser(null);
            setReloadCreditBalance(true);
            navigate("/onboarding");
        } finally {
            setIsWalletConnecting(false);
        }

    };

    return {
        web3Auth,
        provider,
        setProvider,
        setWeb3Auth,
        logout,
        initSessionWithPrivy
    }

}
export default useAuth;

