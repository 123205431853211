import React, {useState} from "react";
import {Callout, Card, Flex, Spinner} from "@radix-ui/themes";
import useSWR from "swr";
import {fetchArtifactFilesFolderStructure, getAgentFiles, getLiveAgentById} from "../../../api/hasura";
import {Agent} from "../AgentDetails";
import "react-folder-tree/dist/style.css";
import FileFolderSidebar from "./FileFolderSiderbar";
import AgentFileEditor from "./AgentFileEditor";
import {ExclamationTriangleIcon} from "@radix-ui/react-icons";

export type FileData = {
    fileName: string;
    metadata: any;
    path: string;
}

const AgentOrStrategyFilesReader = ({agentId, artifactId, versionId}: { agentId?: string, artifactId?: string, versionId?: string }) => {


    const [selectedFile, setSelectedFile] = useState<FileData | null>(null);

    const {
        data: agentFiles,
        mutate: mutateAgentFiles,
        isLoading: isLoadingAgentFiles,
        error: agentFilesError
    } = useSWR(agentId ? `agents/${agentId}/files` : null, () => getAgentFiles(agentId as string));

    const {
        data: strategyFiles,
        mutate: mutateStrategyFiles,
        isLoading: isLoadingStrategyFiles,
        error: strategyFilesError
    } = useSWR(artifactId && versionId ? `artifact/${artifactId}/${versionId}/files` : null, () => fetchArtifactFilesFolderStructure(artifactId!, versionId!));

    const files = agentId ? agentFiles : strategyFiles;
    const mutateFiles = agentId ? mutateAgentFiles : mutateStrategyFiles;
    const filesError = agentId ? agentFilesError : strategyFilesError;
    const isLoadingFiles = agentId ? isLoadingAgentFiles : isLoadingStrategyFiles;


    const onFileSelect = (file: FileData) => {

        setSelectedFile(file);
    }
    return <Flex gap={"1"} direction={"column"}>

        {filesError &&
            <Callout.Root color="red">
                <Callout.Icon>
                    <ExclamationTriangleIcon/>
                </Callout.Icon>
                <Callout.Text>
                    {filesError.toString()}
                </Callout.Text>
            </Callout.Root>
        }

        <Flex gap={"1"} direction={"row"}>

            {isLoadingFiles && <Spinner />}
            {files && <FileFolderSidebar fileData={files} onFileSelect={onFileSelect}/>}

            {selectedFile && <AgentFileEditor agentId={agentId} file={selectedFile} onUpdate={() => {
                mutateFiles(null)
                setSelectedFile(null)
            }}/>}

        </Flex>
    </Flex>

}

export default AgentOrStrategyFilesReader;
