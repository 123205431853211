import {Button, Dialog, Flex, TextField} from "@radix-ui/themes";
import React, {useEffect} from "react";
import useSWR from "swr";
import {
    getLiveAgentById,
    getLiveAgents,
    renameAlmanakAgent,
} from "../api/hasura";
import {useAppState} from "../state/AppStateProvider";
import {Agent} from "../views/agents/AgentDetails";

const RenameAgentModal = ({agentId}: { agentId: string}) => {

    const {setAgentIdCurrentlyBeingRenamed, setSnackBar} = useAppState();


    const {
        data: agentDetails,
        isLoading,
        mutate: mutateAgent,
    } = useSWR<Agent>(agentId ? `agents/${agentId}` : null, () =>
        getLiveAgentById(agentId)
    );

    const { mutate: mutateAgents } = useSWR<Agent[]>("live-agents", getLiveAgents);


    const [agentName, setAgentName] = React.useState<string | undefined>(agentDetails?.name);
    const [isUpdatingAgentName, setIsUpdatingAgentName] = React.useState(false);


    useEffect(() => {
        if (agentName === undefined) {
            setAgentName(agentDetails?.name);
        }
    }, [agentDetails, agentName]);

    const isInvalid = agentName?.trim() === "";


    const renameAgent = async () => {
        setIsUpdatingAgentName(true);
        try {
            const res = await renameAlmanakAgent(agentId, agentName!);

            setAgentIdCurrentlyBeingRenamed(null);
            mutateAgent();
            mutateAgents();
            setSnackBar({open: true, message: "Agent Renamed", severity: "success"});

        } catch (err: any) {
            console.error(err);
            setSnackBar({open: true, message: err.message, severity: "error"});
        } finally {
            setIsUpdatingAgentName(false);
        }
    }
    return <Dialog.Root open={true}>
        <Dialog.Content>
            <Dialog.Title>Rename Agent</Dialog.Title>

            <Flex direction={"column"} gap={"1"} my="2" mt="4">

                <Flex direction={"column"}>
                    <span>Agent Name</span>
                    <TextField.Root placeholder="Agent Name" value={agentName}
                                    onChange={(e: any) => setAgentName(e.target.value)}>
                    </TextField.Root>


                </Flex>

            </Flex>

            <Flex gap="3" justify="end">
                <Dialog.Close>
                    <Button onClick={() => setAgentIdCurrentlyBeingRenamed(null)} variant="soft" color="red">
                        Cancel
                    </Button>
                </Dialog.Close>
                <Button disabled={isInvalid} loading={isUpdatingAgentName}
                        style={{borderRadius: "4px", padding: "4px 12px"}}
                        onClick={renameAgent}>Rename</Button>
            </Flex>
        </Dialog.Content>
    </Dialog.Root>;
}


export default RenameAgentModal;
