import {Button, Dialog, Flex, Text, TextField} from "@radix-ui/themes";
import React, {useEffect} from "react";
import useSWR from "swr";
import {getSmartWalletById, getSmartWallets, renameAlmanakWallet} from "../api/hasura";
import {useAppState} from "../state/AppStateProvider";

const RenameWalletModal = ({walletId}: { walletId: string}) => {

    const {setWalletIdCurrentlyBeingRenamed, setSnackBar} = useAppState();

    const {
        data: walletDetails,
        isLoading: isWalletLoading,
        isValidating: isWalletValidating,
        mutate: mutateWalletDetails,
    } = useSWR(walletId ? `wallets/${walletId}` : null, () => getSmartWalletById(walletId));


    const {
        mutate: mutateWallets,
    } = useSWR(`ALL-user-wallets`, () => getSmartWallets("ALL"));


    const [walletName, setWalletName] = React.useState<string | undefined>(walletDetails?.name);
    const [isUpdatingWalletName, setIsUpdatingWalletName] = React.useState(false);

    const isInvalid = walletName?.trim() === "";



    useEffect(() => {
        if (walletName === undefined) {
            setWalletName(walletDetails?.name);
        }
    }, [walletDetails, walletName]);


    const renameWallet = async () => {
        setIsUpdatingWalletName(true);
        try {
            const res = await renameAlmanakWallet(walletId, walletName!);

            setWalletIdCurrentlyBeingRenamed(null);
            mutateWalletDetails();
            mutateWallets();
            setSnackBar({open: true, message: "Wallet Renamed", severity: "success"});

        } catch (err: any) {
            console.error(err);
            setSnackBar({open: true, message: err.message, severity: "error"});
        } finally {
            setIsUpdatingWalletName(false);
        }
    }
    return <Dialog.Root open={true}>
        <Dialog.Content>
            <Dialog.Title>Rename Wallet</Dialog.Title>

            <Flex direction={"column"} gap={"1"} my="2" mt="4">

                <Flex direction={"column"}>
                    <span>Wallet Name</span>
                    <TextField.Root placeholder="Wallet Name" value={walletName}
                                    onChange={(e: any) => setWalletName(e.target.value)}>
                    </TextField.Root>


                </Flex>

            </Flex>

            <Flex gap="3" justify="end">
                <Dialog.Close>
                    <Button onClick={() => setWalletIdCurrentlyBeingRenamed(null)} variant="soft" color="red">
                        Cancel
                    </Button>
                </Dialog.Close>
                <Button disabled={isInvalid} loading={isUpdatingWalletName}
                        style={{borderRadius: "4px", padding: "4px 12px"}}
                        onClick={renameWallet}>Rename</Button>
            </Flex>
        </Dialog.Content>
    </Dialog.Root>;
}


export default RenameWalletModal;
