import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAppState} from "../../state/AppStateProvider";
import * as React from "react";
import UserDropdown from "../../components/wallet-connect/UserDropdown";
import {AlmanakLogo} from "../../components/AlmanakLogo";
import {Badge, Button, Flex, Text} from "@radix-ui/themes";
import SocialButtons from "../../components/layout/SocialButtons";
import {earlyAccessUrl} from "../../auth/WhitelistManager";
import {OpenInNewWindowIcon} from "@radix-ui/react-icons";


const applyForAccess = () => {
    window.open(earlyAccessUrl, "_blank");
}

const Home = () => {
    let navigate = useNavigate();

    const {user, theme, setCliAuthPort, setCliAuthentication} = useAppState();
    const isAuthenticated = !!localStorage.getItem("accessToken");

    if (isAuthenticated && user?.isOnboarded) {
        navigate("/home");
    }
    //check if there's params in the url
    //look for params "cli"=true and port
    //if true save the cli port
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("cli") === "true") {
        const cliPort = Number(urlParams.get("port"));
        if (cliPort) {
            window.history.replaceState({}, document.title, "/");
            setCliAuthPort(cliPort);
            setCliAuthentication(true);

        }
    }



    return (
        <div className="container text-center">
            <Box sx={{display: "flex", marginTop: "40px", marginBottom: "24px", alignItems: "center"}}>
                <AlmanakLogo size={160}/>
                <Badge color={"green"} size={"3"} ml={"-26px"} mt={"-20px"}>Beta</Badge>
            </Box>

            <span style={{textAlign:"center", fontSize: "28px", marginTop: "-8px"}}>Almanak is currently in Closed Beta.</span>


            <span style={{textAlign: "center", fontSize: "16px", marginTop: "16px", maxWidth: "420px"}}>
                Follow us on socials to stay updated on our progress and be the first to know when we open up!
            </span>

            <Flex p={"4"} width={"200px"} gap={"4"} direction={"column"} align={"center"}>
                <Button size="3" color={"green"} variant={"soft"} onClick={applyForAccess}>Apply for Beta Access <OpenInNewWindowIcon /></Button>
            </Flex>

            <Text weight={"bold"} size={"4"} my={"6"}>Already Whitelisted?</Text>

            {!user?.publicKey && <UserDropdown/>}

            <Flex position={"absolute"} bottom={"20px"} width={"320px"}>
                <SocialButtons/>

            </Flex>

        </div>);
};

export default Home;
