import { Link } from "react-router-dom";
import { formatPubKey, toChecksumAddress } from "../../utils/commonUtils";

interface UserProfileLinkProps {
    walletAddress: string | null | undefined;
    nameService: string | null | undefined;
}

export const UserProfileLink = ({ walletAddress, nameService }: UserProfileLinkProps) => {
    const displayText = nameService || (walletAddress ? formatPubKey(toChecksumAddress(walletAddress)) : "-");

    if (!walletAddress) return "-";

    return (
        <Link to={`/user/${walletAddress}/profile`} style={{ textDecoration: "none" }}>
            {displayText}
        </Link>
    );
};