import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import {Link, matchRoutes, useLocation} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import WalletIcon from '@mui/icons-material/WalletOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LocalLibrary from '@mui/icons-material/LocalLibrary';
import Header from "./Header";
import {AppDrawer} from "./styled";
import {useAppState} from "../../state/AppStateProvider";
import {styled, Tooltip} from "@mui/material";
import {Button} from "@radix-ui/themes";
import {useEffect, useMemo, useState} from "react";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import {IconButton} from "@radix-ui/themes";
import {FlexBox} from "../../views/simulation/create-simulation/styled";
import {AlmanakLogo} from "../AlmanakLogo";
import jwt from "jwt-decode";
import useAuth from "../../hooks/useAuth";
import {useIdentityToken, getAccessToken, useUser} from "@privy-io/react-auth";
import { GearIcon } from "@radix-ui/react-icons";
import SocialButtons from "./SocialButtons";

const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";

const adminMenuItems = [
    // {title: "Price Simulator", icon: AutoGraphIcon, route: "/price-simulation"}
    {title: "Agent Simulator (Demo)", icon: SlideshowIcon, route: "/demo"},
    {title: "Price Simulator", icon: AutoGraphIcon, route: "/price-simulation"},
]
const strategyLibraryItem = {title: "Strategies", icon: LocalLibrary, route: "/strategy-library"}
const agentsMenuItem = {title: "Agents", icon: SensorOccupiedIcon, route: "/agents"};
const walletsMenuItem = {title: "Wallets", icon: WalletIcon, route: "/wallets"};
const docsItem = {title: "Docs", icon: AssignmentIcon, route: "/docs"};
const settingsMenuItem = {title: "Settings", icon: GearIcon, route: "/settings/profile"};
const testMenuItem = {title: "Tests", icon: HistoryIcon, route: "/simulation-history"};
const vaultsMenuItem = {title: "Vaults", icon: HistoryIcon, route: "/vaults"};


const ThemedLink = styled(Link)(({theme}) => ({
    // textDecoration: "none",
    // color: theme.palette.text.primary,
    // margin: "8px"
    "> button": {
        justifyContent: "flex-start"
    }
}));


export const fullScreenRoutes = ['/simulation/:id', '/simulation/:id/results', '/simulation/:id/agent/:id',
    '/simulation/:id/agent/:id/strategies', '/simulation/:id/results/agent/:id', '/user/:walletAddress/profile'];

export const openAlmanakDocs = async (subUrl?: string) => {
    // const response = await getDocsAccessToken();
    // if (response.customToken) {
    const stageUrl = "https://pde-docs.web.app/"; //stage
    const prodUrl = "https://docs.almanak.co/"; //prod
    const url = isStageEnv ? stageUrl : prodUrl;
    // window.open(`${url}${subUrl ? subUrl : ""}?token=${response.customToken}`, "_blank")
    window.open(`${url}${subUrl ? subUrl : ""}`, "_blank")
    // }
}
export const LDKeyStrategyLibraryWidget = "frontend_strategy-library-widget"
export const LDKeyWallets = "frontend_wallets";
export const LDKeyAgents = "frontend_agents";
export const LDKeyTests = 'frontend_tests';
export const LDKeyVaults = 'frontend_vaults';
export const LDKeyAgentPnL = 'frontend_agent_pnl';
export const LDKeyAgentTransactionsInsteadSafe = 'frontend_agent_trx_instead_safe_trx';
export const LDKeyTelegram = 'frontend_telegram_notifications';
export const LDKeyPointsReferral = 'frontend_points_leaderbord_referrals'
export const isStageEnv = process.env.REACT_APP_ENVIRONMENT !== "production";

export default function Sidebar() {
    const location = useLocation()
    const {user, setSnackBar, featureFlags, theme} = useAppState();
    const {initSessionWithPrivy} = useAuth();
    const {identityToken} = useIdentityToken();

    const {refreshUser} = useUser();


    const isAdminUser = !!user?.is_admin;
    const [isDocsTokenGenerating, setIsDocsTokenGenerating] = useState(false);

    const isStrategyLibraryEnabled = true; //featureFlags?.[LDKeyStrategyLibraryWidget];
    const isWalletsEnabled = true; // featureFlags?.[LDKeyWallets];
    const isAgentsEnabled = true; //featureFlags?.[LDKeyAgents];
    const isTestEnabled = featureFlags?.[LDKeyTests];
    const isVaultsEnabled = featureFlags?.[LDKeyVaults];

    const menuItems1 = useMemo(() => {

        let returnList = [
            {title: "Home", icon: HomeIcon, route: "/home"},
        ];

        if (isStrategyLibraryEnabled) {
            returnList.push(strategyLibraryItem)
        }

        // returnList.push({title: "Tests", icon: HistoryIcon, route: "/simulation-history"});

        if (isWalletsEnabled) {
            returnList.push(walletsMenuItem)
        }

        if (isAgentsEnabled) {
            returnList.push(agentsMenuItem)
        }

        return returnList;

    }, [isStrategyLibraryEnabled, isWalletsEnabled, isAgentsEnabled])

    const comingSoonItems = useMemo(() => {
        let returnList = [];

        if (!isTestEnabled) {
            returnList.push(testMenuItem)
        }

        if (!isAgentsEnabled) {
            returnList.push(agentsMenuItem)
        }

        if (!isWalletsEnabled) {
            returnList.push(walletsMenuItem)
        }

        if (!isStrategyLibraryEnabled) {
            returnList.push(strategyLibraryItem)
        }

        if (!isVaultsEnabled) {
            returnList.push(vaultsMenuItem)
        }

        return returnList;

    }, [isVaultsEnabled, isTestEnabled, isAgentsEnabled, isWalletsEnabled, isStrategyLibraryEnabled])


    const initSessionAndLogin = async () => {

        const userPublicKey = user?.publicKey ?? localStorage.getItem("userPublicKey");
        // let privyIdToken = localStorage.getItem("privy:id_token");
        let privyIdToken = localStorage.getItem("privy:id_token")?.replaceAll("\"", "");


        try {
            //refreshes ID token
            const newUser = await refreshUser();
            privyIdToken = localStorage.getItem("privy:id_token")?.replaceAll("\"", "");

        } catch (e: any) {
            console.error("Error refreshing user", e);
        }

        if (!privyIdToken || !userPublicKey) {
            // logout()
            return;
        }
        await initSessionWithPrivy(privyIdToken, userPublicKey);
    }

    const checkTokenExpiration = async () => {

        const token = localStorage.getItem("accessToken");

        if (!token) {
            // setSnackBar({open: true, message: "Session expired, restoring..", severity: "info"});
            initSessionAndLogin();
            return;
        }
        const convertedToJWT = jwt(token) as any;
        const expiryTime = convertedToJWT.exp;

        const tenMinutesInTheFuture = Date.now() / 1000 + 600;
        const isTokenAlmostExpired = expiryTime < tenMinutesInTheFuture;
        const isTokenExpired = expiryTime < Date.now() / 1000;

        if (isTokenAlmostExpired || isTokenExpired) {
            initSessionAndLogin();
        }

    };

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (!token) {
            return;
        }

        // Check immediately when the component mounts
        checkTokenExpiration();

        // Set interval to check every 5 minutes (300000ms)
        const intervalId = setInterval(checkTokenExpiration, 300000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);

    }, []);


    const openDocs = async () => {
        setIsDocsTokenGenerating(true);
        try {
            await openAlmanakDocs();
        } catch (err: any) {
            setSnackBar({open: true, message: "Could not open docs, please contact support", severity: "error"});
        } finally {
            setIsDocsTokenGenerating(false);
        }
    }



    const isOnboarded = user?.isOnboarded;
    const isAuthenticated = !!localStorage.getItem("accessToken");
    const matchedRoute = matchRoutes(fullScreenRoutes.map(r => {
        return {path: r}
    }), location)?.[0]
    const isFullScreenRoute = fullScreenRoutes.includes(matchedRoute?.route.path!)


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Header/>
            {!isFullScreenRoute && isAuthenticated ? <AppDrawer
                variant="permanent"
                anchor="left"
            >

                <Box sx={{margin: "8px"}}></Box>
                <AlmanakLogo size={69}/>


                <Box sx={{margin: "8px"}}></Box>
                {menuItems1.map((item, index) => (
                    <ThemedLink key={item.title} to={item.route}>
                        <Button style={{textAlign: "start", boxShadow: "none", margin: "4px", width: "228px"}}
                                size={"3"}
                                variant={location?.pathname?.includes(item.route) ? "soft" : "outline"}>
                            {item.title}
                        </Button>
                    </ThemedLink>))
                }


                {comingSoonItems.map((item, index) => (
                    <Tooltip key={item.title} title="Coming soon!" placement={"left-start"}>
                        <ThemedLink key={item.title} to={"#"}>
                            <Button disabled style={{boxShadow: "none", margin: "4px", width: "228px"}} size={"3"}
                                    variant={location?.pathname?.includes(item.route) ? "soft" : "outline"}>
                                {item.title}
                            </Button>
                        </ThemedLink>
                    </Tooltip>
                ))}


                <Divider/>
                {isAdminUser && adminMenuItems.map((item, index) => (
                    <ThemedLink key={item.title} to={item.route}>
                        <Button style={{boxShadow: "none", margin: "4px", width: "228px"}} size={"3"}
                                variant={location?.pathname?.includes(item.route) ? "soft" : "outline"}>
                            {item.title}
                        </Button>
                    </ThemedLink>
                ))}

                <ThemedLink to={"#"}>
                    <Button onClick={openDocs} loading={isDocsTokenGenerating}
                            style={{boxShadow: "none", margin: "4px", width: "228px"}} size={"3"}
                            variant={"outline"}>
                        {docsItem.title}
                    </Button>
                </ThemedLink>
                {/*<ListItem disabled={isDocsTokenGenerating} key={docsItem.title} disablePadding onClick={openDocs}>*/}
                {/*    <ListItemButton>*/}
                {/*        <ListItemIcon>*/}
                {/*            {<docsItem.icon/>}*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={docsItem.title}/>*/}
                {/*        {isDocsTokenGenerating && <CircularProgress size={18}/>}*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}

                <Divider sx={{ mt: "auto" }} />
                <ThemedLink to={settingsMenuItem.route}>
                    <Button
                        style={{
                            boxShadow: "none",
                            margin: "4px",
                            width: "228px",
                        }}
                        size={"3"}
                        variant={location?.pathname?.includes(settingsMenuItem.route) ? "soft" : "outline"}>
                        {settingsMenuItem.title}
                    </Button>
                </ThemedLink>

                <SocialButtons />

            </AppDrawer> : null}

        </Box>
    );
}
