import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {
    CenteredRowFlexBox,
    FlexCol,
} from "../views/simulation/create-simulation/styled";
import Box from "@mui/material/Box";
import {
    Alert,
    CircularProgress,
    MenuItem,
    Modal,
    Select,
    Tooltip,
} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import { useNavigate, useParams} from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import {useEffect, useState, useMemo} from "react";
import {useAppState} from "../state/AppStateProvider";
import {python} from "@codemirror/lang-python";
import {ArtifactVersionItem} from "../views/strategy/ArtifactVersionItem";
import useSWR from "swr";
import {
    fetchArtifactFilesWithUrl,
    getArtifactDetails,
    toggleStrategyAccess,
    starStrategy,
    unstarStrategy,
    cloneArtifactStrat, deleteStrategy,
    getPublicArtifactDetails, fetchArtifactFilesFolderStructure,
} from "../api/hasura";
import {
    LibraryArtifact,
    LibraryArtifactVersion,
    ArtifactStar,
    ArtifactFile,
} from "./StrategyLibraryWidget";
import {formatDateValue} from "../views/simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";

import {
    Badge,
    Button, Callout,
    DropdownMenu,
    Flex,
    IconButton,
    Spinner, Text,
} from "@radix-ui/themes";
import {
    ArrowLeftIcon,
    ChevronDownIcon,
    StarIcon,
    StarFilledIcon,
    ChevronLeftIcon,
    DotsHorizontalIcon,
    ExitIcon,
    PersonIcon,
    DotsVerticalIcon, CopyIcon, ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import {Select as RadixSelect, Card} from "@radix-ui/themes";
import {AlmanakModalContent} from "../modals/ScenarioCustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {formatPubKey, toChecksumAddress} from "../utils/commonUtils";
import TextField from "@mui/material/TextField";
import { getScanStatusDisplay } from "../views/strategy/ScanStatusDisplay";
import AgentOrStrategyFilesReader from "../views/agents/AgentFilesReader/AgentOrStrategyFilesReader";

export const StrategyLibraryDetailsWidget = () => {
    const navigate = useNavigate();
    let {artifactId} = useParams();

    const {theme, user, setSnackBar} = useAppState();

    // Fetching a single artifact instead of the entire list as it should be more performant
    // And would not revalidate the entire cache when starring
    const {data: artifact, mutate, isLoading} = useSWR(
        artifactId ? `artifact-${artifactId}` : null,
        () => getArtifactDetails(artifactId as string)
    );

    const {data: publicArtifact} = useSWR(
        artifactId && artifact?.is_public
            ? `public-artifact-${artifactId}`
            : null,
        () => getPublicArtifactDetails(artifactId as string)
    );


    const isStarred = artifact?.artifact_stars?.some(
        (star: ArtifactStar) => star.user_id === user?.id
    );
    const isUserAuthor = artifact?.author === user?.id;
    const isPublic = artifact?.is_public;
    const artifactName = artifact?.name;



    const [isStarring, setIsStarring] = useState(false);
    const [selectedVersionId, setSelectedVersionId] = useState<string | null>(null);
    const [code, setCode] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState<ArtifactFile | null>(null);
    const [isChangeAccessModalOpen, setIsChangeAccessModalOpen] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isChangeAccessLoading, setIsChangeAccessLoading] = useState(false);
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
    const [cloneName, setCloneName] = useState("");
    const [isCloning, setIsCloning] = useState(false);


    const handleStarToggle = async () => {
        setIsStarring(true);
        try {
            if (isStarred) {
                await unstarStrategy(artifactId as string);
                setSnackBar({
                    open: true,
                    message: "Successfully unstarred strategy",
                    severity: "success",
                });
            } else {
                await starStrategy(artifactId as string);
                setSnackBar({
                    open: true,
                    message: "Successfully starred strategy",
                    severity: "success",
                });
            }
            await mutate();
        } catch (e: any) {
            setSnackBar({
                open: true,
                message: `Error: ${e?.message}`,
                severity: "error",
            });
        } finally {
            setIsStarring(false);
        }
    };

    const onDeleteStrategy = async () => {
        setIsDeleteModalVisible(false);
        try {
            const res = await deleteStrategy(artifact?.id as string);
            if (res.message) {
                setSnackBar({
                    open: true,
                    message: "Successfully deleted strategy",
                    severity: "success",
                });
                navigate("/strategy-library");
            } else {
                setSnackBar({
                    open: true,
                    message: "Error deleting strategy. Make sure the strategy is not being run by any agent before deleting.",
                    severity: "error",
                });
            }
        } catch (e: any) {
            setSnackBar({
                open: true,
                message: `Error deleting strategy. Make sure the strategy is not being run by any agent before deleting. ${e?.message}`,
                severity: "error",
            });
        }
    }


    // Create a memoized artifactVersion that's always in sync with the artifact data
    const artifactVersion = useMemo(() => {
        if (!artifact?.artifact_versions) return null;

        // If user selected a specific version, try to find it
        if (selectedVersionId) {
            const selectedVersion = artifact.artifact_versions.find(
                ( v : LibraryArtifactVersion )=> v.id === selectedVersionId
            );
            if (selectedVersion) return selectedVersion;
        }

        // Default to latest version
        return artifact.latest_version_artifact || null;
    }, [artifact, selectedVersionId]);

    const isEmptyStrategy = !isLoading && (!artifact || !artifactVersion);


    const {data: artifactFilesWithUrl} = useSWR(
        artifactVersion ? `${artifactVersion?.id}-files` : null,
        () => fetchArtifactFilesWithUrl(artifactName, artifactVersion?.name)
    );

    const selectedFileAuthenticatedUri = selectedFile && artifactFilesWithUrl?.find(
        (f: any) => f.id === selectedFile.id
    )?.presigned_url;
    const {data: selectedFileContents, isLoading: fileLoading} = useSWR(
        // Only make the request if we have a valid URI
        selectedFileAuthenticatedUri ? selectedFileAuthenticatedUri : null,
        async () => {
            const res = await fetch(selectedFileAuthenticatedUri);
            return res.text();
        }
    );

    const authorDisplay = useMemo(() => {
        if (artifact?.is_public && publicArtifact?.author_main_name_service) {
            return publicArtifact.author_main_name_service;
        }

        if (artifact?.author_user?.main_name_service) {
            return artifact.author_user.main_name_service;
        }

        if (artifact?.is_public && publicArtifact?.author_wallet_address) {
            return toChecksumAddress(publicArtifact.author_wallet_address);
        }

        if (artifact?.author_user?.wallet_address) {
            return toChecksumAddress(artifact.author_user.wallet_address);
        }

        return "User not available";
    }, [artifact?.author_user?.main_name_service, artifact?.is_public, publicArtifact?.author_main_name_service,
        publicArtifact?.author_wallet_address, artifact?.author_user?.wallet_address]);

    const onVersionChange = (versionId: string) => {
        setSelectedVersionId(versionId);
    };

    const toggleAccessChange = async () => {
        setIsChangeAccessModalOpen(false);
        setIsChangeAccessLoading(true);

        try {
            const res = await toggleStrategyAccess(
                artifact?.id,
                !artifact?.is_public
            );
            setSnackBar({
                open: true,
                message: "Strategy is now " + (res.is_public ? "Public" : "Private"),
                severity: "success",
            });
        } catch (e: any) {
            console.error("error", e);
            setSnackBar({
                open: true,
                message: `Error changing access: ${e?.message}`,
                severity: "error",
            });
        } finally {
            await mutate();
            setIsChangeAccessLoading(false);
        }
    };
    const handleClone = async () => {
        setIsCloning(true);
        try {
            const response = await cloneArtifactStrat(
                artifact?.id as string,
                cloneName
            );
            if (response.valid) {
                setSnackBar({
                    open: true,
                    message: "Successfully cloned strategy",
                    severity: "success",
                });
                setIsCloneModalOpen(false);
                navigate("/strategy-library");
            } else {
                setSnackBar({
                    open: true,
                    message: response.message ? `Error cloning strategy: ${response.message}` : "Error cloning strategy",
                    severity: "error",
                });
            }
        } catch (e: any) {
            setSnackBar({
                open: true,
                message: `Error cloning strategy: ${e?.message}`,
                severity: "error",
            });
        } finally {
            setIsCloning(false);
        }
    };


    // file selection
    useEffect(() => {
        if (artifactVersion) {
            // Only set the file if no file is selected or if current selection doesn't belong to this version
            const fileExists = selectedFile && artifactVersion.artifact_files?.some(
                (file : ArtifactFile )=> file.id === selectedFile.id
            );

            if (!fileExists) {
                // Select the first file of the new version
                setSelectedFile(artifactVersion.artifact_files?.[0] || null);
            }
        } else {
            // Reset selected file if no version is selected
            setSelectedFile(null);
        }
    }, [artifactVersion, selectedFile]);

    useEffect(() => {
        if (isCloneModalOpen && artifact?.name) {
            setCloneName(`Clone of ${artifact.name}`);
        }
    }, [isCloneModalOpen, artifact?.name]);


    const onCopySdkCommand = () => {
        navigator.clipboard.writeText(`almanak strat push ${artifact.id}`);
        setSnackBar({
            open: true,
            message: "SDK command copied to clipboard",
            severity: "success",
        });
    }
    if (isLoading) {
        return (
            <PageContentContainer>
                <Flex gap={"2"} align={"center"} justify={"center"} my={"8"}>
                    Loading strategy details <Spinner />
                </Flex>
            </PageContentContainer>
        );
    }




    const StrategyHeader = <Flex justify={"between"} align={"center"}>
        <Flex align={"center"} gap={"16px"}>
            <Button variant="outline" onClick={() => navigate(-1)}>
                <ArrowLeftIcon/>
                Back
            </Button>
            <h2>{artifact?.name}</h2>
        </Flex>
        <Flex align={"center"} gap={"16px"}>
            <Button
                variant="soft"
                loading={isStarring}
                onClick={handleStarToggle}
            >
                {isStarred ? <StarFilledIcon/> : <StarIcon/>}
                {isStarred ? "Starred" : "Star"}
                <span style={{marginLeft: "4px"}}>
                            {artifact?.stars_count || 0}
                         </span>
            </Button>

            <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                    <Button variant={"soft"} loading={isChangeAccessLoading}>
                        <DotsVerticalIcon/>
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content style={{minWidth: "144px"}}>
                    <DropdownMenu.Item onClick={() => setIsCloneModalOpen(true)}>
                        Clone Strategy
                    </DropdownMenu.Item>
                    {/*Only show the make public option. once strategy is public there's no making it private*/}
                    {isUserAuthor && (
                        <>
                            {!artifact?.is_public && <DropdownMenu.Item
                                onClick={() => setIsChangeAccessModalOpen(true)}
                            >
                                Make {artifact?.is_public ? "Private" : "Public"}
                            </DropdownMenu.Item>}

                            <DropdownMenu.Item
                                color={"red"}
                                onClick={() => setIsDeleteModalVisible(true)}
                            >
                                Delete Strategy
                            </DropdownMenu.Item>
                        </>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </Flex>
    </Flex>;


    const StrategyDetails = <Flex direction={"column"} gap={"4"}>

        <Flex direction={"row"} gap={"4"}>
            <Flex direction={"column"}>
                <Text size="2" weight={"bold"}>
                    Creation Date
                </Text>
                <Text size={"2"}>
                    {formatDateValue(artifact?.date_created)}
                </Text>
            </Flex>

            <Flex direction={"column"}>
                <Text size="2" weight={"bold"}>
                    Access
                </Text>
                <div>
                    <Badge
                        color={artifact?.is_public ? "green" : "gray"}
                        radius={"full"}
                        size={"1"}
                    >
                        {artifact?.is_public ? "Public" : "Private"}
                    </Badge>
                </div>
            </Flex>
        </Flex>

        <Flex direction={"column"}>
            <Text size="2" weight={"bold"}>
                Author
            </Text>
            <div>
                <Button
                    variant="ghost"
                    onClick={() => {
                        const authorWalletAddress = artifact?.is_public
                            ? toChecksumAddress(publicArtifact.author_wallet_address)
                            : toChecksumAddress(artifact?.author_user?.wallet_address);

                        if (authorWalletAddress) {
                            navigate(`/user/${authorWalletAddress}/profile`, { state: {} });
                        }
                    }}
                >
                    {authorDisplay}
                </Button>
            </div>
        </Flex>


        <Flex justify="between" direction={"column"}>
            <Text size="2" weight={"bold"}>
                Description
            </Text>
            <Text size={"2"}>
                {!!artifact?.description ? artifact.description : "No Description available"}
            </Text>
        </Flex>

        <Flex justify="between" direction={"column"}>
            <Text size="2" weight={"bold"}>
                Strategy ID
            </Text>
            <Flex align="center" gap="2">
                <Text size="2" style={{ fontFamily: "monospace" }}>
                    {artifact?.id}
                </Text>
                <IconButton
                    size="1"
                    variant="outline"
                    onClick={() => {
                        navigator.clipboard.writeText(artifact.id);
                        setSnackBar({
                            open: true,
                            message: "Strategy ID copied to clipboard",
                            severity: "success",
                        });
                    }}
                >
                    <CopyIcon />
                </IconButton>
            </Flex>
        </Flex>

        <Box>
            {artifactVersion?.metadata?.tags && (
                <Box>
                    <div
                        style={{
                            padding: "16px 0 8px 0",
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}
                    >
                        Tags
                    </div>
                    <CenteredRowFlexBox sx={{gap: "8px"}}>
                        {artifactVersion.metadata?.tags.map((tag: string) => (
                            <Chip label={tag}/>
                        ))}
                    </CenteredRowFlexBox>
                </Box>
            )}
        </Box>

    </Flex>


    const StrategyContent =   <FlexCol sx={{gap: "16px"}}>
        <Box>
            <Box sx={{my: 2}} style={{fontSize: "14px", fontWeight: "bold"}}>
                Strategy Versions
            </Box>
            <RadixSelect.Root
                onValueChange={onVersionChange}
                value={artifactVersion?.id || ""}
            >
                <RadixSelect.Trigger />
                <RadixSelect.Content>
                    {artifact?.artifact_versions?.map((version: LibraryArtifactVersion) => (
                        <RadixSelect.Item
                            key={version.id}
                            value={version.id}
                        >
                            <Flex align="center" gap="2">
                                <Box style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    color: getScanStatusDisplay(version.scan_status)?.color,
                                    flexShrink: 0
                                }}>
                                    {getScanStatusDisplay(version.scan_status)?.icon}
                                </Box>
                                <span>{version.name} - {formatDateValue(version.date_created)}</span>
                            </Flex>
                        </RadixSelect.Item>
                    ))}
                </RadixSelect.Content>
            </RadixSelect.Root>
        </Box>

        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <h2>Version Details</h2>
        </div>
        {artifactVersion && (
            <ArtifactVersionItem
                artifact={artifact}
                key={artifact.id}
                artifactVersion={artifactVersion}
                isDetails
                onVersionUpdate={mutate}
            />
        )}


        <h2>Strategy Files</h2>

        <AgentOrStrategyFilesReader key={`file-reader-${artifactVersion?.id}`} artifactId={artifact?.id} versionId={artifactVersion?.id} />
    </FlexCol>

    const EmptyStrategyView = <FlexCol sx={{gap: "16px"}}>
        <Callout.Root color="blue">
            <Callout.Icon>
                <ExclamationTriangleIcon/>
            </Callout.Icon>
            <Callout.Text>
                This strategy is empty. To push files to this strategy use the Almanak SDK command below.
            </Callout.Text>
        </Callout.Root>

        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            alignItems: "center",
            backgroundColor: "var(--gray-3)",
            padding: "12px",
            borderRadius: "4px",
            width: "100%"
        }}>

            <Text>
                almanak strat push {artifact?.id}
            </Text>
            <IconButton variant={"outline"} onClick={onCopySdkCommand}>
                <CopyIcon />
            </IconButton>

        </Box>

    </FlexCol>

    return (
        <PageContentContainer>


            {StrategyHeader}

            {StrategyDetails}

            {isEmptyStrategy ? EmptyStrategyView : StrategyContent}

            {isChangeAccessModalOpen && (
                <Modal
                    sx={{display: "flex"}}
                    open={isChangeAccessModalOpen}
                    onClose={() => setIsChangeAccessModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AlmanakModalContent>
                        <Box
                            sx={{
                                p: 3,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Make strategy {artifact?.is_public ? "Private" : "Public"}
                            <IconButton
                                variant="outline"
                                onClick={() => setIsChangeAccessModalOpen(false)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Alert severity={"info"} sx={{m: 1}}>
                            You are about to make this strategy{" "} {artifact?.is_public ? "Private" : "Public. This action cannot be undone"}.
                        </Alert>

                        <Box
                            sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}
                        >
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                variant="soft"
                                onClick={() => setIsChangeAccessModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                color="green"
                                onClick={toggleAccessChange}
                            >
                                Make {artifact?.is_public ? "Private" : "Public"}{" "}
                            </Button>
                        </Box>
                    </AlmanakModalContent>
                </Modal>
            )}

            {isDeleteModalVisible && (
                <Modal
                    sx={{display: "flex"}}
                    open={isDeleteModalVisible}
                    onClose={() => setIsDeleteModalVisible(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AlmanakModalContent>
                        <Box
                            sx={{
                                p: 3,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Are you sure you want to delete this strategy?
                            <IconButton
                                variant="outline"
                                onClick={() => setIsDeleteModalVisible(false)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Alert severity={"info"} sx={{m: 1}}>
                            You are about to delete this strategy! This action cannot be undone. You can only delete strategies which are not currently in use by any agents. All strategy versions will be removed as well.
                        </Alert>

                        <Box
                            sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}
                        >
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                variant="soft"
                                onClick={() => setIsDeleteModalVisible(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                color="red"
                                onClick={onDeleteStrategy}
                            >
                                Delete Strategy
                            </Button>
                        </Box>
                    </AlmanakModalContent>
                </Modal>
            )}

            {isCloneModalOpen && (
                <Modal
                    sx={{display: "flex"}}
                    open={isCloneModalOpen}
                    onClose={() => setIsCloneModalOpen(false)}
                    aria-labelledby="clone-modal-title"
                    aria-describedby="clone-modal-description"
                >
                    <AlmanakModalContent>
                        <Box
                            sx={{
                                p: 3,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Clone Strategy
                            <IconButton
                                variant="outline"
                                onClick={() => setIsCloneModalOpen(false)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Box sx={{p: 3}}>
                            <TextField
                                fullWidth
                                label="New Strategy Name"
                                value={cloneName}
                                onChange={(e) => setCloneName(e.target.value)}
                                placeholder="Enter name for cloned strategy"
                            />
                        </Box>

                        <Box
                            sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}
                        >
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                variant="soft"
                                onClick={() => setIsCloneModalOpen(false)}
                                disabled={isCloning}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                color="green"
                                onClick={handleClone}
                                disabled={!cloneName.trim() || isCloning}
                            >
                                {isCloning ? <Spinner/> : null}
                                {isCloning ? "Cloning..." : "Clone"}
                            </Button>
                        </Box>
                    </AlmanakModalContent>
                </Modal>
            )}
        </PageContentContainer>
    );
};
