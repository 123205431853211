import React from 'react';
import {ethers} from 'ethers';
import {Badge, Button, Dialog, Flex, Text, TextField} from '@radix-ui/themes';
import {useAppState} from "../state/AppStateProvider";
import {useWallets} from "@privy-io/react-auth";
import useSWR from "swr";
import {getCurrentEthPrice, getEthBalanceForWallet, getTopUpPaymentSmartContract} from "../api/hasura";


const TopUpComponent = ({onClose, chainId, eoaWalletAddress}: {onClose: any, chainId: string, eoaWalletAddress: string}) => {

    const {provider, setSnackBar} = useAppState();

    const {wallets} = useWallets();
    const connectedWallet = wallets?.[0];


    const {
        data: topUpPaymentSmartContract
    } = useSWR("topUpPaymentSmartContract", getTopUpPaymentSmartContract)
    const {data: currentEthPriceUsd} = useSWR("current-eth-price-usd", getCurrentEthPrice)
    const {data: connectedWalletEthBalanceGwei} = useSWR("connectedWalletEthBalance", () => getEthBalanceForWallet(connectedWallet?.address, chainId));

    const [topUpAmount, setTopUpAmount] = React.useState("0.005");

    const connectedWalletEthBalance = connectedWalletEthBalanceGwei ? ethers.utils.formatEther(connectedWalletEthBalanceGwei) : "0";
    const connectedWalletEthBalanceInUsd = currentEthPriceUsd ? (Number(connectedWalletEthBalance) * Number(currentEthPriceUsd)).toFixed(2) : "0";
    const topUpContract = topUpPaymentSmartContract?.find((contract: any) => contract.chain_id === chainId)
    const topUpABI = topUpContract?.abi;
    const topUpAddress = topUpContract?.address;

    const topUpAmountInUsd = currentEthPriceUsd ? (Number(topUpAmount) * Number(currentEthPriceUsd)).toFixed(2) : "0";


    const handleTopUp = async () => {

        setIsFundingEOA(true);
        onClose()

        try {

            await connectedWallet?.switchChain(Number(chainId));

            const walletProvider = await connectedWallet.getEthereumProvider();

            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = await provider.getSigner();
            const recipientAddress = eoaWalletAddress;

            // Request access to the wallet (if not already connected)
            await provider.send("eth_requestAccounts", []);

            const topUp = new ethers.Contract(topUpAddress, topUpABI, signer);

            const parsedTopUpAmount = ethers.utils.parseEther(topUpAmount?.toString());


            // Call the topUp function with the recipient address and send the specified amount
            const tx = await topUp.topUp(recipientAddress, {value: parsedTopUpAmount});

            const receipt = await tx.wait();

            setSnackBar({open: true, message: `Transaction Successful`, severity: "success"});

        } catch (error: any) {
            setSnackBar({open: true, message: `Failed: ${error?.message?.substr(0, 200)}`, severity: "error"});

            console.error("Error interacting with the contract:", error);
        } finally {
            setIsFundingEOA(false);
        }
    };


    const [isFundingEOA, setIsFundingEOA] = React.useState(false);


    const isEthBalanceSufficient = Number(connectedWalletEthBalance) >= Number(topUpAmount);

    return <Dialog.Root open={true}>
        <Dialog.Content>
            <Dialog.Title>Top Up EOA Wallet</Dialog.Title>


            <Flex direction={"column"} gap={"1"} my="2" mt="4">
                <Text weight="bold" size="4" my={"1"}>You are funding Almanak EOA wallet which will be performing
                    transactions for
                    you.</Text>


                <Flex direction={"column"}>
                    <span>ETH Amount to Top Up</span>
                    <TextField.Root placeholder="ETH amount to top up" value={topUpAmount}

                                    onChange={(e: any) => setTopUpAmount(e.target.value)}>
                        <Badge my="1" mr={"2"} color={"gray"}>${topUpAmountInUsd}</Badge>
                    </TextField.Root>


                    <Flex direction={"column"} gap={"1"} mt="1">
                        {!isEthBalanceSufficient && <Text color={"red"} size={"2"}>Insufficient Funds</Text>}
                        <Text
                            size={"2"}>Available: {connectedWalletEthBalance} (${connectedWalletEthBalanceInUsd})</Text>
                    </Flex>
                </Flex>

            </Flex>

            <Flex gap="3" justify="end">
                <Dialog.Close>
                    <Button onClick={onClose} variant="soft" color="red">
                        Cancel
                    </Button>
                </Dialog.Close>
                <Button disabled={!isEthBalanceSufficient} loading={isFundingEOA}
                        style={{borderRadius: "4px", padding: "4px 12px"}} color="green"
                        onClick={handleTopUp}>Top Up</Button>
            </Flex>
        </Dialog.Content>
    </Dialog.Root>;
};

export default TopUpComponent;
