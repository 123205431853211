import * as React from "react";
import {useAppState} from "../../state/AppStateProvider";
import {Pagination, useTheme} from "@mui/material";
import {PageContentContainer} from "../styled/styled";
import useSWR from "swr";
import {getPointsLeaderboard, getReferralInfo, getUserPointsBalance} from "../../api/hasura";
import {Button, Flex, IconButton, Table, Text} from "@radix-ui/themes"
import {CopyIcon, OpenInNewWindowIcon} from "@radix-ui/react-icons";
import {LDKeyPointsReferral, openAlmanakDocs} from "../../components/layout/Sidebar";
import {UserProfileLink} from "./PointsProfileLink";
import {TitleValueColumn} from "../../components/data-display/TitleValueColumn";

// Leaderboard interfaces
interface LeaderboardEntry {
    nameService: string | null;
    points: number;
    rank: number;
    referrerNameService: string | null;
    referrerWalletAddress: string | null;
    walletAddress: string;
}

interface PaginationData {
    currentPage: number;
    totalItems: number;
    totalPages: number;
}

interface LeaderboardData {
    message: string;
    success: boolean;
    data: {
        pagination: PaginationData;
        results: LeaderboardEntry[];
    };
}

const formatNumberWithCommas = (num: number) => {
    if (!num) return "0";
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const UserPoints = () => {
    const {featureFlags, user, setSnackBar} = useAppState();
    const [currentPage, setCurrentPage] = React.useState(1);

    const {
        data: userPoints,
        isLoading: isUserPointsLoading,
    } = useSWR(user ? "user-points-balance" : null, () => getUserPointsBalance(user?.id));

    const {
        data: leaderboardData,
        isLoading: isLeaderboardLoading,
        error: leaderboardError,
    } = useSWR<LeaderboardData>(
        ["points-leaderboard", currentPage],
        () => getPointsLeaderboard(currentPage),
        {revalidateOnFocus: false}
    );

    const {
        data: referralInfo
    } = useSWR(user ? `referral-info-${user.id}` : null, getReferralInfo);


    const handlePageChange = (page: number): void => {
        setCurrentPage(page);
    };

    const [leaderboard, pagination] = React.useMemo(() => [
        leaderboardData?.data,
        leaderboardData?.data?.pagination
    ], [leaderboardData]);

    const isReferralEnabled = featureFlags?.[LDKeyPointsReferral];

    const inviteCode = referralInfo?.invite_code;
    const referralCount = referralInfo?.referral_count;
    const referralPoints = referralInfo?.referral_points;
    const referralLink = `${window.location.origin}/invite?code=${inviteCode}`;


    return (
        <PageContentContainer>
            <Flex direction="column" gap="4" py="4">
                <Text size="6" weight="bold">
                    Leaderboard
                </Text>

                <Text size="4">
                    Points are rewarded for using the Almanak Platform.
                </Text>

                <div>
                    <Button variant="outline" onClick={() => openAlmanakDocs('docs/points')}>
                        Learn more <OpenInNewWindowIcon/>
                    </Button>
                </div>

                <Flex direction="row" align={"start"} justify={"between"} py="4">

                    <Flex direction="column" gap="2">
                        <TitleValueColumn
                            size="20px"
                            title="Your Points"
                            value={isUserPointsLoading ? "Loading..." : formatNumberWithCommas(userPoints)}
                            explanation={"Including points earned from referrals"}
                        />
                    </Flex>

                    {isReferralEnabled && <Flex direction="column" align={"start"} gap="2">
                        <Flex direction="row" gap="8">

                            <TitleValueColumn
                                size="20px"
                                title="Your Referrals"
                                value={referralCount}
                            />


                            {referralPoints && <TitleValueColumn
                                size="20px"
                                title="Your Referral Points"
                                value={formatNumberWithCommas(referralPoints)}
                            />}

                        </Flex>


                        <TitleValueColumn
                            size="16px"
                            title="Your Referral Link"
                            value={<Flex gap={"2"}>
                                <Text>{referralLink}</Text>
                                <IconButton
                                    size="1"
                                    variant="outline"
                                    onClick={() => {
                                        navigator.clipboard.writeText(referralLink);
                                        setSnackBar({
                                            open: true,
                                            message: "Referral Link copied to clipboard",
                                            severity: "success",
                                        });
                                    }}
                                >
                                    <CopyIcon/>
                                </IconButton>
                            </Flex>}
                        />

                    </Flex>}
                </Flex>

                <Text size="5" weight="bold" mt="4">
                    Leaderboard
                </Text>

                {isLeaderboardLoading ? (
                    <Flex align="center" justify="center" py="4">
                        <Text>Loading leaderboard...</Text>
                    </Flex>
                ) : leaderboardError ? (
                    <Flex align="center" justify="center" py="4">
                        <Text color="red">Error loading leaderboard.</Text>
                    </Flex>
                ) : (
                    <>
                        <Table.Root variant="surface">
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeaderCell>Rank</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>User</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Points</Table.ColumnHeaderCell>
                                    {isReferralEnabled && (
                                        <Table.ColumnHeaderCell>Invited By</Table.ColumnHeaderCell>
                                    )}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {leaderboard?.results.map((entry: LeaderboardEntry, index: number) => (
                                    <Table.Row key={entry.walletAddress}>
                                        <Table.Cell>
                                            <Text size={index < 3 ? "7" : "3"}>
                                                {index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : entry.rank}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <UserProfileLink
                                                walletAddress={entry.walletAddress}
                                                nameService={entry.nameService}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>{formatNumberWithCommas(entry.points)}</Table.Cell>
                                        {isReferralEnabled && (
                                            <Table.Cell>
                                                <UserProfileLink
                                                    walletAddress={entry.referrerWalletAddress}
                                                    nameService={entry.referrerNameService}
                                                />
                                            </Table.Cell>
                                        )}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>

                        {pagination && pagination.totalPages > 1 && (
                            <Flex justify="center" mt="4">
                                <Pagination
                                    count={pagination.totalPages}
                                    page={pagination.currentPage}
                                    onChange={(_, page) => handlePageChange(page)}
                                    color="primary"
                                    size="medium"
                                />
                            </Flex>
                        )}
                    </>
                )}
            </Flex>
        </PageContentContainer>
    );
};
