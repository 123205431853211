import React, { useState, useEffect } from "react";
import FolderTree, { NodeData } from "react-folder-tree";
import "react-folder-tree/dist/style.css";
import {FileData} from "./AgentOrStrategyFilesReader";

// Backend file structure types
interface FileMetadata {
    generation: string;
    timeCreated: string;
    contentType: string;
    presigned_url?: string;
}

export interface FileItem {
    path: string;
    fileName: string;
    metadata: FileMetadata;
}

interface FolderItem {
    name: string;
    files: FileItem[];
    folders: FolderItem[];
}

interface Props {
    fileData: FolderItem;
    onFileSelect: (file: FileData) => void;
}

// Function to transform the backend JSON into react-folder-tree structure
const transformData = (data: FolderItem): NodeData => {
    const mapFolder = (folder: FolderItem): NodeData => {
        return {
            name: folder.name.trim() !== "" ? folder.name : "root", // Fix empty root name
            children: [
                ...folder.files.map((file) => ({
                    type: "file",
                    name: file.fileName,
                    rawFileData: file,
                })),
                // Recursively process folders
                ...folder.folders.map(mapFolder),
            ],
        };
    };

    return mapFolder(data);
};


const FileFolderSidebar: React.FC<Props> = ({ fileData, onFileSelect }) => {
    const [treeData, setTreeData] = useState<NodeData | null>(null);

    // Transform data only when fileData changes
    useEffect(() => {
        setTreeData(transformData(fileData));
    }, [fileData]);

    const handleNodeClick = (node: any) => {
        if (node.nodeData?.type === "file" && node.nodeData?.rawFileData) {
            onFileSelect(node.nodeData?.rawFileData); // Send the file path to the parent component
        }
    };

    return (
        <div style={{whiteSpace: "nowrap"}}>
            {treeData && (
                <FolderTree
                    showCheckbox={false}
                    data={treeData}
                    onNameClick={(e) => handleNodeClick(e)}
                />
            )}
        </div>
    );
};

export default FileFolderSidebar;
