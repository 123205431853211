import {PageContentContainer} from "../styled/styled";
import {
    Alert,
    Box,
    Modal,
} from "@mui/material";

import { Sparklines, SparklinesLine } from "react-sparklines";


import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {formatMonetaryValue} from "../simulation/create-simulation/PoolDetails";
import {ChainBadge} from "./ChainBadge";
import {formatDateValue, formatPubKey} from "../../utils/commonUtils";
import {formatTokenAmount} from "../../utils/tokenUtils";
import useSWR from "swr";
import {
    getEthBalanceForWallet,
    getSafeTransactionHistory,
    getSmartWalletById,
    getWalletPositions,
    getSupportedChains, get24HourWalletBalance, getCurrentEthPrice
} from "../../api/hasura";
import {useEffect, useMemo} from "react";
import Safe from "@safe-global/protocol-kit";
import {useAppState} from "../../state/AppStateProvider";
import {ROLES_MOD_ADDRESS} from "./WalletCreationFlow";
import {useFundWallet, useWallets} from "@privy-io/react-auth";
import {IconButton, Badge, DropdownMenu} from "@radix-ui/themes";
import WalletStatus from "./WalletStatus";
import {encodeBytes32String} from "defi-kit";
import {fetchRole} from "zodiac-roles-deployments";
import {AlmanakModalContent} from "../../modals/ScenarioCustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {
    CopyIcon,
    ExclamationTriangleIcon,
    ExternalLinkIcon,
    CheckIcon,
    ArrowLeftIcon, ReloadIcon, PlusIcon, OpenInNewWindowIcon, Pencil1Icon, ChevronDownIcon, RocketIcon, DotsVerticalIcon
} from "@radix-ui/react-icons";
import {Tabs, Flex, Card, Text, Table, Button, Callout, Spinner} from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';
import {FlexBox} from "../simulation/create-simulation/styled";
import TopUpComponent from "../../components/TopUpComponent";
import TokenLink from "../../components/data-display/TokenLink";

import {arbitrum, base, Chain, mainnet, sepolia} from "viem/chains";

interface WalletHolding {
    id: number;
    asset: string;
    amount: number;
    usdValue: number;
    token?: Array<{
        symbol: string;
        total_token_amount: number;
        price_usd: number;
    }>;
}

export interface WalletTransaction {
    tx_hash: string;
    tx_name: string;
    timestamp: string;
    user_address: string;
    chain: string;
    chain_id: string;
    is_scam: boolean;
}

interface WalletDetails {
    id: string;
    name: string;
    address: string;
    chain_id: string;
    status: string;
    transactions?: WalletTransaction[];
    external_own_accounts: Array<{ address: string }>;
}

interface TokenData {
    symbol: string;
    total_token_amount: number;
    price_usd: number;
}

interface HoldingData {
    token: TokenData[];
    total_usd_balance?: number;
    profit_losses_display?: string;
    profit_losses_1day?: number;
}

interface TokenData {
    id: string;
    chain: string;
    name: string;
    symbol: string;
    display_symbol: string | null;
    optimized_symbol: string;
    decimals: number;
    logo_url: string | null;
    is_verified: boolean;
    is_core: boolean;
    price: number;
    time_at: number;
    amount: number;
    raw_amount: number;
}

interface ProtocolStats {
    asset_usd_value: number;
    debt_usd_value: number;
    net_usd_value: number;
}

interface ProtocolDetail {
    supply_token_list: TokenData[];
    reward_token_list?: TokenData[];
    borrow_token_list?: TokenData[];
}

interface ProtocolProject {
    id: string;
    name: string;
    site_url: string | null;
    logo_url: string | null;
}

interface ProtocolPool {
    controller: string;
    id: string;
    chain: string;
    project_id: string;
}

interface PortfolioItem {
    stats: ProtocolStats;
    update_at: number;
    name: string;
    detail_types: string[];
    detail: ProtocolDetail;
    proxy_detail?: {
        project: ProtocolProject;
        proxy_contract_id: string;
    };
    pool?: ProtocolPool;
    position_index?: string;
}

interface Protocol {
    id: string;
    chain: string;
    name: string;
    site_url: string | null;
    logo_url: string | null;
    has_supported_portfolio: boolean;
    portfolio_item_list: PortfolioItem[];
}

interface WalletPositionsData {
    valid: boolean;
    message: string;
    total_usd_value: number;
    tokens: TokenData[];
    protocols: Protocol[];
}

export const openTransaction = (tx: WalletTransaction): void => {

    switch (Number(tx.chain_id)) {
        case 1:
            window.open(`https://etherscan.io/tx/${tx.tx_hash}`)
            break;
        case 42161:
            window.open(`https://explorer.arbitrum.io/tx/${tx.tx_hash}`)
            break;
        case 11155111:
            window.open(`https://sepolia.etherscan.io/tx/${tx.tx_hash}`)
            break;
        case 8453:
            window.open(`https://basescan.org/tx/${tx.tx_hash}`)
            break;
    }
}

export const getViemChainById = (chainId: string): Chain => {
    switch (chainId) {
        case "1":
            return mainnet;
        case "42161":
            return arbitrum;
        case "11155111":
            return sepolia;
        case "8453":
            return base;
        default:
            return mainnet;
    }
}
export const WalletDetails = ({passedId, isEmbedded}: { passedId?: string, isEmbedded?: boolean }) => {

    const {wallets} = useWallets();
    const {fundWallet} = useFundWallet();

    const connectedWallet = wallets[0];

    const {id} = useParams();


    const safeWalletId = passedId || id;
    const {provider, setSnackBar, setWalletIdCurrentlyBeingRenamed} = useAppState();


    const [withdrawModalOpen, setWithdrawModalOpen] = React.useState(false);

    const [isRolesModEnabled, setIsRolesModEnabled] = React.useState(false);
    const [isRoleSetup, setIsRoleSetup] = React.useState(false);
    const [isFundEoaVisible, setIsFundEoaVisible] = React.useState(false);
    const [isFundingEOA, setIsFundingEOA] = React.useState(false);


    const [selectedTab, setSelectedTab] = React.useState<'holdings' | 'protocols' | 'transactions'>('holdings');


    const {
        data: walletDetails,
        isLoading: isWalletLoading,
        isValidating: isWalletValidating,
        mutate: mutateWalletDetails,
    } = useSWR<WalletDetails>(safeWalletId ? `wallets/${safeWalletId}` : null, () => getSmartWalletById(safeWalletId));

    const {data: currentEthPriceUsd} = useSWR("current-eth-price-usd", getCurrentEthPrice)

    const {
        data: walletPositions,
        isLoading: isPositionsLoading,
        isValidating: isPositionsValidating,
        mutate: mutatePositions
    } = useSWR<WalletPositionsData>(
        walletDetails ? ['wallet-positions', walletDetails.address, walletDetails.chain_id] : null,
        () => getWalletPositions(walletDetails!.address, walletDetails!.chain_id)
    );

    const {
        data: walletBalances24Hours
    } = useSWR(walletDetails?.address && walletDetails?.chain_id ? `wallets-${walletDetails.address}-balances-24-hours` : null, () => get24HourWalletBalance(walletDetails?.address, walletDetails?.chain_id));

    const firstValue = walletBalances24Hours?.[0]?.usd_value;
    // const lastValue = walletBalances24Hours?.[walletBalances24Hours?.length - 1]?.usd_value;
    const lastValue = walletPositions?.total_usd_value ?? 0;
    const walletSparklineData = walletBalances24Hours?.map((entry: {usd_value: number, timestamp: string}) => entry.usd_value) ?? [];

    const balanceChange24 = lastValue - firstValue;
    const changeIn24HoursPercentage = firstValue === 0 ? 100 : (balanceChange24 / firstValue) * 100;

    const isProfit = changeIn24HoursPercentage > 0;

    const {
        data: walletTransactions,
        isLoading: isTransactionsLoading,
        isValidating: isTransactionsValidating,
        mutate: mutateWalletTransactions
    } = useSWR<WalletTransaction[]>(walletDetails?.address ? `wallets-${walletDetails.address}-transactions` : null, () => getSafeTransactionHistory(walletDetails?.address, walletDetails?.chain_id));

    const {
        data: EOAWalletGasWei
    } = useSWR(walletDetails?.address && walletDetails?.chain_id ? `EOAWalletGas-${walletDetails?.address}` : null, () => getEthBalanceForWallet(walletDetails?.external_own_accounts[0].address!, walletDetails?.chain_id!))




    const {
        data: supportedChains,
        isLoading: chainsLoading,
    } = useSWR("supported-chains", getSupportedChains);


    const showWalletRenameModal = () => {
        setWalletIdCurrentlyBeingRenamed(walletDetails?.id!);
    }

    const currentChain = supportedChains?.find(chain => chain.chain_id === walletDetails?.chain_id);

    const formattedEOAWalletGas = useMemo(() => {
        if (!EOAWalletGasWei) {
            return "0";
        }

        //convert to ETH from wei
        const gas = Number(EOAWalletGasWei) / 10 ** 18;


        return gas?.toFixed(5);
    }, [EOAWalletGasWei]);


    const eoaGasInUsd = useMemo(() => {
        if (!currentEthPriceUsd || !EOAWalletGasWei) {
            return "0";
        }

        const gasInUsd = Number(currentEthPriceUsd) * Number(EOAWalletGasWei) / 10 ** 18;

        return gasInUsd.toFixed(2);
    }, [currentEthPriceUsd, EOAWalletGasWei]);

    const showEoaGas = Number(EOAWalletGasWei) > 0;
    const topUpEoaWallet = () => {
        const eoaWalletAddress = walletDetails?.external_own_accounts[0].address;
        if (!eoaWalletAddress) {
            console.error("No EOA Wallet Address");
            return;
        }

        //this opens the Almanak top up modal
        setIsFundEoaVisible(true);

        // this is privy top up which is functional and simply deposits eth
        // fundWallet(eoaWalletAddress, {amount: "0.02"});
    }


    const navigate = useNavigate()


    // const onHoldingSelect = (holding: WalletHolding): void => {
    //     console.log("Holding selected", holding);
    // }


    const isEOAWalletEmpty = Number(EOAWalletGasWei) === 0;
    const isWalletReady = walletDetails?.status === "READY" || walletDetails?.status === "IN_USE";
    const isRolesSetup = true;
    const isWalletSetupComplete = walletDetails?.address && isRolesModEnabled && isRolesSetup;


    const fetchRolesModStatus = async () => {
        if (!provider) {
            console.error("fetchRolesModStatus NO PROVIDER");
            return;
        }

        if (!walletDetails) {
            console.error("fetchRolesModStatus NO WALLET DETAILS");
            return;
        }

        const safe = await Safe.init({provider: provider, safeAddress: walletDetails.address});

        //this is not consistent, it does not always return true even though module is enabled.
        let isRolesModEnabled = await safe.isModuleEnabled(ROLES_MOD_ADDRESS);
        const modules = await safe.getModules();

        const roleName = "AlmanakAgentRole";
        const roleKey = encodeBytes32String(roleName);
        const role = await fetchRole({
            address: modules[0] as `0x${string}`,
            roleKey,
            chainId: Number(walletDetails.chain_id) as 1 | 10 | 100 | 137 | 42161 | 43114 | 8453 | 11155111,
        });


        const isRoleAdded = role !== null && role?.members[0] === walletDetails.external_own_accounts[0].address;

        isRolesModEnabled = modules?.length > 0;
        setIsRolesModEnabled(isRolesModEnabled);
        setIsRoleSetup(isRoleAdded);

    }
    useEffect(() => {
        if (walletDetails?.chain_id) {
            connectedWallet?.switchChain(Number(walletDetails.chain_id));
        }
        fetchRolesModStatus();
    }, [provider, walletDetails, connectedWallet]);

    const depositToWallet = async () => {
        if (!walletDetails?.address) {
            return;
        }

        await connectedWallet?.switchChain(Number(walletDetails.chain_id));

        const viemChain = getViemChainById(walletDetails.chain_id);

        await fundWallet(walletDetails.address, {chain: viemChain, amount: "0.1"});
    }

    const withdrawFromWallet = () => {
        setWithdrawModalOpen(true);
    }


    const onTabChange = (e: any) => {
        if (e.target.value) {
            setSelectedTab(e.target.value);
        }
    };

    const openSafeWallet = () => {
        if (!walletDetails?.address) {
            return;
        }

        const availableChains = {
            "1": "eth",
            "8453": "base",
            "42161": "arb1",
            "11155111": "sep",
        }

        //arb1 || sep || eth
        const safeChain = availableChains[walletDetails?.chain_id as keyof typeof availableChains];


        if (!safeChain) {
            setSnackBar({
                open: true,
                message: "The wallet chain is not supported, please contact support",
                severity: "error"
            });
            return;
        }

        window.open(`https://app.safe.global/home?safe=${safeChain}:${walletDetails?.address}`)
    }

    const refreshWallet = async () => {
        await mutateWalletDetails()
        fetchRolesModStatus()
        mutatePositions()
        mutateWalletTransactions()
    }

    const copyText = async (text?: string) => {
        if (!text) {
            return;
        }
        await navigator.clipboard.writeText(text)
        setSnackBar({open: true, message: "Copied to clipboard"})
    }

    const SafeSparklines = (Sparklines as any);
    const SafeSparklinesLine = (SparklinesLine as any);


    return (
        <PageContentContainer sx={{py: 2, ...(isEmbedded ? {paddingLeft: "16px"} : {})}}>
            {!isEmbedded && <FlexBox sx={{alignItems: "center", justifyContent: "space-between"}}>
                <FlexBox sx={{alignItems: "center", gap: "16px"}}>
                    <Button variant={"outline"} onClick={() => navigate("/wallets")}>
                        <ArrowLeftIcon/>Back</Button>
                    <h2>{walletDetails?.name}</h2>

                    {/*{!isWalletValidating && <WalletStatus wallet={walletDetails}></WalletStatus>}*/}

                    <IconButton variant="ghost" onClick={refreshWallet}>{isWalletValidating || isWalletLoading ?
                        <Spinner/> :
                        <ReloadIcon/>}</IconButton>

                </FlexBox>

                <Flex gap={"2"}>
                    <Button onClick={depositToWallet} variant={"solid"}>Deposit</Button>
                    <Button onClick={withdrawFromWallet} variant={"solid"}>Withdraw</Button>
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                            <Button variant="soft">
                                <DotsVerticalIcon />
                            </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content style={{ minWidth: "144px" }}>
                            <DropdownMenu.Item onClick={showWalletRenameModal}>
                                Rename
                            </DropdownMenu.Item>
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>

                </Flex>
            </FlexBox>}

            <>
                {isWalletLoading ? (
                    <Flex direction="column" align="center" gap="3" py="8">
                        <Text size="3" weight="medium" color="gray">
                            Loading Wallet Details
                        </Text>
                        <Spinner size="2"/>
                    </Flex>) : <>
                    {/*Upper side of wallet overview*/}


                    {!isWalletReady && (
                        <Callout.Root color="blue" style={{marginBottom: "8px"}}>
                            <Callout.Icon>
                                <ExclamationTriangleIcon/>
                            </Callout.Icon>
                            <Callout.Text>
                                It might take a minute for the wallet status to update after finishing the
                                setup.
                            </Callout.Text>
                        </Callout.Root>
                    )}


                    {Number(EOAWalletGasWei) === 0 && <Flex width={"100%"}>
                        <Callout.Root color="yellow">
                            <Callout.Icon>
                                <ExclamationTriangleIcon/>
                            </Callout.Icon>
                            <Callout.Text>
                                <Flex gap={"8"}>
                                    <Text>Externally Owned Wallet has no ETH in it. It is required to execute
                                        transactions.
                                        Please top up.</Text>

                                    {/*<TopUpComponent />*/}
                                    <Button onClick={topUpEoaWallet} variant={EOAWalletGasWei < 0 ? "ghost" : "solid"}>
                                        <PlusIcon/>
                                        Top Up Gas</Button>
                                </Flex>
                            </Callout.Text>
                        </Callout.Root>


                    </Flex>
                    }

                    {/*{!isEOAWalletEmpty && walletDetails?.status === "READY" && (*/}
                    {/*    <Callout.Root color="green">*/}
                    {/*        <Callout.Icon>*/}
                    {/*            <CheckIcon/>*/}
                    {/*        </Callout.Icon>*/}
                    {/*        <Callout.Text>*/}
                    {/*            Wallet is ready to be used.*/}
                    {/*        </Callout.Text>*/}
                    {/*    </Callout.Root>*/}
                    {/*)}*/}

                    <Box>
                        <Box sx={{my: 2}}>
                            <Flex direction="column">
                                <Text size="2" color="gray">
                                    Wallet Balance
                                </Text>
                                <Flex direction={"row"} gap={"4"}>
                                    <Box width={"300px"} height={"80px"} position="relative">
                                        <SafeSparklines
                                            width={300}
                                            height={80}
                                            data={walletSparklineData}
                                            limit={288}
                                            margin={5}
                                            style={{opacity: "0.4"}}
                                        >
                                            {/*@ts-ignore*/}
                                            <SafeSparklinesLine color={isProfit ? "#00713fde" : "red"} />
                                        </SafeSparklines>

                                        {/* Overlay the text on top of the chart */}
                                        <Flex
                                            position="absolute"
                                            top="0"
                                            left="0"
                                            width="100%"
                                            height="100%"
                                            direction="column"
                                            gap="1"
                                            justify="start"
                                            align="start"
                                        >
                                            <Flex align="end" direction="row" gap={"1"}>
                                                <Text size="8" weight="bold">
                                                    ${formatMonetaryValue(walletPositions?.total_usd_value ?? 0)}
                                                </Text>
                                                {!isNaN(changeIn24HoursPercentage) && (
                                                    <Text
                                                        size="4"
                                                        weight={"bold"}
                                                        style={{
                                                            color: isProfit ? "green" : "red",
                                                        }}
                                                    >
                                                        {isProfit && "+"}
                                                        {formatMonetaryValue(changeIn24HoursPercentage)}%
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </Flex>



                                {/* Wallet Details Card */}
                                <Card>
                                    <Flex direction="column" gap="4" p="4">
                                        <Flex justify="between" align="center">
                                            <Text size="2" color="gray">Wallet Name</Text>
                                            <Flex gap={"2"} align={"center"}><Text size="2">{walletDetails?.name || 'Unnamed Wallet'}</Text></Flex>
                                        </Flex>

                                        <Flex justify="between" align="center">
                                            <Text size="2" color="gray">Status</Text>
                                            <WalletStatus wallet={walletDetails}/>
                                        </Flex>

                                        <Flex justify="between" align="center">
                                            <Text size="2" color="gray">Chain</Text>
                                            <ChainBadge chainId={walletDetails?.chain_id}/>
                                        </Flex>

                                        <Flex justify="between" align="center">
                                            <Text size="2" color="gray">Safe Wallet Address</Text>
                                            <Flex gap="2" align="center">
                                                <Text size="2" style={{fontFamily: 'monospace'}}>
                                                    {formatPubKey(walletDetails?.address)}
                                                </Text>
                                                <IconButton variant="outline"
                                                            onClick={() => copyText(walletDetails?.address)}>
                                                    <CopyIcon/>
                                                </IconButton>
                                                <IconButton variant="outline" onClick={openSafeWallet}>
                                                    <ExternalLinkIcon/>
                                                </IconButton>
                                            </Flex>
                                        </Flex>

                                        <Flex justify="between" align="center">
                                            <Text size="2" color="gray">EOA Wallet Address</Text>
                                            <Flex gap="2" align="center">
                                                <Text size="2" style={{fontFamily: 'monospace'}}>
                                                    {formatPubKey(walletDetails?.external_own_accounts[0].address)}
                                                </Text>
                                                <IconButton variant="outline"
                                                            onClick={() => copyText(walletDetails?.external_own_accounts[0].address)}
                                                >
                                                    <CopyIcon/>
                                                </IconButton>
                                                {/*<IconButton variant="outline" onClick={openEOAWallet} >*/}
                                                {/*    <ExternalLinkIcon/>*/}
                                                {/*</IconButton>*/}
                                            </Flex>
                                        </Flex>

                                        <Flex justify="between" align="center">
                                            <Text size="2" color="gray">Wallet Gas</Text>
                                            {EOAWalletGasWei ? (
                                                <Flex align={"center"} gap={"1"}>
                                                    <Text color={Number(EOAWalletGasWei) === 0 ? "red" : "green"}
                                                          size="2">{formattedEOAWalletGas} ETH</Text>

                                                    {showEoaGas && <Text size="2">(${eoaGasInUsd})</Text>}
                                                    <Button onClick={topUpEoaWallet}
                                                            variant={EOAWalletGasWei < 0 ? "ghost" : "solid"}>
                                                        <PlusIcon/>
                                                        Top Up</Button>
                                                </Flex>
                                            ) : (
                                                <Spinner size="1"/>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Card>
                            </Flex>
                        </Box>


                        <FlexBox sx={{justifyContent: "space-between", width: "100%", gap: 4}}>

                            {/*Wallet holdings side*/}

                            <Box sx={{borderRight: "1px solid #eaeaea"}}/>


                            {/*Transactions side*/}
                            {/*<CenteredColumnFlexBox sx={{alignItems: "center", width: "100%"}}>*/}
                            {/*    <h3 style={{textAlign: "center"}}>Transactions</h3>*/}


                            {/*    <CenteredColumnFlexBox sx={{alignItems: "center", width: "100%", gap: 2}}>*/}

                            {/*        {transactions.map((transaction, index) => (*/}
                            {/*            <FlexBox key={index} sx={{*/}
                            {/*                gap: 2,*/}
                            {/*                width: "100%",*/}
                            {/*                justifyContent: "space-between",*/}
                            {/*                border: "1px solid #eaeaea",*/}
                            {/*                p: "8px 16px",*/}
                            {/*                borderRadius: "8px"*/}
                            {/*            }}>*/}
                            {/*                <FlexBox sx={{gap: 1}}>*/}
                            {/*                    <div>{transaction.type}</div>*/}
                            {/*                    <div>{transaction.amount}</div>*/}
                            {/*                </FlexBox>*/}
                            {/*                <CenteredColumnFlexBox sx={{gap: 1}}>*/}
                            {/*                    <div>{transaction.date}</div>*/}
                            {/*                </CenteredColumnFlexBox>*/}
                            {/*            </FlexBox>*/}
                            {/*        ))}*/}
                            {/*    </CenteredColumnFlexBox>*/}
                            {/*</CenteredColumnFlexBox>*/}

                        </FlexBox>


                        <Tabs.Root defaultValue="holdings" value={selectedTab}
                                   onValueChange={(value: string) => setSelectedTab(value as 'holdings' | 'protocols' | 'transactions')}>
                            <Tabs.List>
                                <Tabs.Trigger value="holdings">Wallet Holdings</Tabs.Trigger>
                                <Tabs.Trigger value="protocols">Protocol Positions</Tabs.Trigger>
                                <Tabs.Trigger value="transactions">Transactions</Tabs.Trigger>
                            </Tabs.List>

                            <Box pt="3">
                                <Tabs.Content value="holdings">
                                    {isPositionsLoading && !walletPositions?.tokens ? (
                                        <Flex align="center" style={{margin: "16px 8px"}} gap="8px">
                                            Loading holdings... <Spinner/>
                                        </Flex>
                                    ) : (
                                        <Table.Root>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.ColumnHeaderCell>Asset</Table.ColumnHeaderCell>
                                                    <Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
                                                    <Table.ColumnHeaderCell>Price</Table.ColumnHeaderCell>
                                                    <Table.ColumnHeaderCell>USD Value</Table.ColumnHeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {walletPositions?.tokens?.length === 0 ? (
                                                    <Table.Row>
                                                        <Table.Cell colSpan={4}>No tokens found</Table.Cell>
                                                    </Table.Row>
                                                ) : (
                                                    walletPositions?.tokens?.map((token: TokenData) => (
                                                        <Table.Row key={token.id}>
                                                            <Table.Cell>
                                                                <Flex gap="2" align="center">
                                                                    {token.logo_url && (
                                                                        <img src={token.logo_url} alt={token.symbol}
                                                                             style={{width: 24, height: 24}}
                                                                             loading="lazy"/>
                                                                    )}
                                                                    <Flex direction="column">
                                                                        <Text
                                                                            weight="medium">{token.optimized_symbol}</Text>
                                                                        <TokenLink token={token}
                                                                                   blockExplorerUrl={currentChain?.block_explorer_url ?? ''}/>
                                                                    </Flex>
                                                                </Flex>
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                style={{verticalAlign: 'middle'}}>{formatTokenAmount(token.amount, token.decimals)}</Table.Cell>
                                                            <Table.Cell
                                                                style={{verticalAlign: 'middle'}}>${token.price.toFixed(2)}</Table.Cell>
                                                            <Table.Cell
                                                                style={{verticalAlign: 'middle'}}>${formatMonetaryValue(token.amount * token.price)}</Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                )}
                                            </Table.Body>
                                        </Table.Root>
                                    )}
                                </Tabs.Content>

                                <Tabs.Content value="protocols">
                                    {isPositionsLoading ? (
                                        <Flex align="center" style={{margin: "16px 8px"}} gap="8px">
                                            Loading protocol positions... <Spinner/>
                                        </Flex>
                                    ) : (
                                        <Flex direction="column" gap="6" style={{paddingTop: '24px'}}>
                                            {(!walletPositions?.protocols || walletPositions.protocols.length === 0) ? (
                                                <Table.Root>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell colSpan={4}>No protocol positions
                                                                found</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table.Root>
                                            ) : (
                                                walletPositions?.protocols?.map((protocol: Protocol) => (
                                                    <Flex key={protocol.id} direction="column"
                                                          style={{marginBottom: '32px'}}>
                                                        <Flex justify="between" align="center" mb="4">
                                                            <Flex gap="3" align="center">
                                                                {protocol.logo_url && (
                                                                    <img src={protocol.logo_url} alt={protocol.name}
                                                                         style={{width: 32, height: 32}}/>
                                                                )}
                                                                <Flex direction="column">
                                                                    <Text weight="medium"
                                                                          size="5">{protocol.name}</Text>
                                                                    {protocol.site_url && (
                                                                        <a href={protocol.site_url} target="_blank"
                                                                           rel="noopener noreferrer">
                                                                            <Flex gap="1" align="center">
                                                                                <Text size="2"
                                                                                      color="gray">{protocol.site_url}</Text>
                                                                                <OpenInNewWindowIcon/>
                                                                            </Flex>
                                                                        </a>
                                                                    )}
                                                                </Flex>
                                                            </Flex>

                                                            <Flex direction="column" align="end">
                                                                <Text size="2" color="gray">Total Protocol Value</Text>
                                                                <Text size="4" weight="medium">
                                                                    ${formatMonetaryValue(
                                                                    protocol.portfolio_item_list.reduce(
                                                                        (sum, item) => sum + item.stats.net_usd_value,
                                                                        0
                                                                    )
                                                                )}
                                                                </Text>
                                                            </Flex>
                                                        </Flex>

                                                        <Flex direction="column" gap="3">
                                                            {protocol.portfolio_item_list.map((item: any, index: number) => (
                                                                <Card key={index} variant="classic">
                                                                    <Box p="3">
                                                                        <Flex justify="between" align="center" mb="2">
                                                                            <Text weight="medium">{item.name}</Text>
                                                                            <Badge>{item.detail_types.join(', ')}</Badge>
                                                                        </Flex>

                                                                        <Flex direction="column" gap="3">
                                                                            {/* Supply tokens */}
                                                                            {item.detail.supply_token_list.length > 0 && (
                                                                                <Box>
                                                                                    <Text size="2" mb="2">Supplied
                                                                                        Assets:</Text>
                                                                                    {item.detail.supply_token_list.map((token: any) => (
                                                                                        <Flex key={token.id}
                                                                                              justify="between"
                                                                                              align="center" mb="1">
                                                                                            <Flex gap="2"
                                                                                                  align="center">
                                                                                                {token.logo_url && (
                                                                                                    <img
                                                                                                        src={token.logo_url}
                                                                                                        alt={token.symbol}
                                                                                                        style={{
                                                                                                            width: 20,
                                                                                                            height: 20
                                                                                                        }}/>
                                                                                                )}
                                                                                                <Text>{token.optimized_symbol}</Text>
                                                                                                <TokenLink token={token}
                                                                                                           blockExplorerUrl={currentChain?.block_explorer_url ?? ''}/>
                                                                                            </Flex>
                                                                                            <Text>{formatTokenAmount(token.amount, token.decimals)}</Text>
                                                                                        </Flex>
                                                                                    ))}
                                                                                </Box>
                                                                            )}

                                                                            {/* Reward tokens */}
                                                                            {item.detail.reward_token_list && item.detail.reward_token_list.length > 0 && (
                                                                                <Box>
                                                                                    <Text size="2" mb="2">Reward
                                                                                        Assets:</Text>
                                                                                    {item.detail.reward_token_list.map((token: any) => (
                                                                                        <Flex key={token.id}
                                                                                              justify="between"
                                                                                              align="center" mb="1">
                                                                                            <Flex gap="2"
                                                                                                  align="center">
                                                                                                {token.logo_url && (
                                                                                                    <img
                                                                                                        src={token.logo_url}
                                                                                                        alt={token.symbol}
                                                                                                        style={{
                                                                                                            width: 20,
                                                                                                            height: 20
                                                                                                        }}/>
                                                                                                )}
                                                                                                <Text>{token.optimized_symbol}</Text>
                                                                                                <TokenLink token={token}
                                                                                                           blockExplorerUrl={currentChain?.block_explorer_url ?? ''}/>
                                                                                            </Flex>
                                                                                            <Text>{formatTokenAmount(token.amount, token.decimals)}</Text>
                                                                                        </Flex>
                                                                                    ))}
                                                                                </Box>
                                                                            )}

                                                                            {/* Borrow tokens */}
                                                                            {item.detail.borrow_token_list && item.detail.borrow_token_list.length > 0 && (
                                                                                <Box>
                                                                                    <Text size="2" mb="2" color="red">Borrowed
                                                                                        Assets:</Text>
                                                                                    {item.detail.borrow_token_list.map((token: any) => (
                                                                                        <Flex key={token.id}
                                                                                              justify="between"
                                                                                              align="center" mb="1">
                                                                                            <Flex gap="2"
                                                                                                  align="center">
                                                                                                {token.logo_url && (
                                                                                                    <img
                                                                                                        src={token.logo_url}
                                                                                                        alt={token.symbol}
                                                                                                        style={{
                                                                                                            width: 20,
                                                                                                            height: 20
                                                                                                        }}/>
                                                                                                )}
                                                                                                <Text>{token.optimized_symbol}</Text>
                                                                                                <TokenLink token={token}
                                                                                                           blockExplorerUrl={currentChain?.block_explorer_url ?? ''}/>
                                                                                            </Flex>
                                                                                            <Text>{formatTokenAmount(token.amount, token.decimals)}</Text>
                                                                                        </Flex>
                                                                                    ))}
                                                                                </Box>
                                                                            )}

                                                                            <Flex direction="column" gap="1" mt="2">
                                                                                <Text size="2" color="gray">Position
                                                                                    Value:</Text>
                                                                                <Text
                                                                                    weight="medium">${formatMonetaryValue(item.stats.net_usd_value)}</Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Box>
                                                                </Card>
                                                            ))}
                                                        </Flex>
                                                    </Flex>
                                                ))
                                            )}
                                        </Flex>
                                    )}
                                </Tabs.Content>

                                <Tabs.Content value="transactions">
                                    {isTransactionsLoading ? (
                                        <Flex align={"center"} style={{margin: "16px 8px"}} gap={"8px"}>
                                            Loading transactions... <Spinner/></Flex>) : (
                                        <Table.Root>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                                                    <Table.ColumnHeaderCell>TX Hash</Table.ColumnHeaderCell>
                                                    {/*<Table.ColumnHeaderCell>User Address</Table.ColumnHeaderCell>*/}
                                                    <Table.ColumnHeaderCell>Chain</Table.ColumnHeaderCell>
                                                    <Table.ColumnHeaderCell>Time</Table.ColumnHeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {!isTransactionsLoading && (!walletTransactions || walletTransactions.length === 0) ? (
                                                    <Table.Row>
                                                        <Table.Cell colSpan={5}>No transactions found</Table.Cell>
                                                    </Table.Row>
                                                ) : (
                                                    walletTransactions?.map((tx: WalletTransaction) => (
                                                        <Table.Row
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            key={tx.tx_hash}
                                                            onClick={() => openTransaction(tx)}
                                                        >
                                                            <Table.Cell>{tx.tx_name ?? "-"}</Table.Cell>
                                                            <Table.Cell>{formatPubKey(tx.tx_hash)} <IconButton ml={"2"}
                                                                variant="ghost"><OpenInNewWindowIcon/></IconButton></Table.Cell>
                                                            {/*<Table.Cell>{formatPubKey(tx.user_address)}</Table.Cell>*/}
                                                            <Table.Cell>{tx.chain}</Table.Cell>
                                                            <Table.Cell>{formatDateValue(new Date(tx.timestamp), true)}</Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                )}
                                            </Table.Body>
                                        </Table.Root>
                                    )}
                                </Tabs.Content>
                            </Box>
                        </Tabs.Root>


                    </Box>

                </>}
            </>

            <>
                {withdrawModalOpen &&
                    <Modal
                        sx={{display: "flex"}}
                        open={withdrawModalOpen}
                        onClose={() => setWithdrawModalOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >


                        <AlmanakModalContent>
                            <Box sx={{
                                p: 3,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "bold"
                            }}>
                                Withdraw from Wallet
                                <IconButton variant="outline"
                                            onClick={() => setWithdrawModalOpen(false)}><CloseIcon/></IconButton>
                            </Box>

                            <Alert severity={"info"} sx={{m: 1}}>
                                You will be redirected to app.safe.global to complete the withdrawal process.
                            </Alert>

                            <Box sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}>
                                <Button style={{borderRadius: "4px", padding: "4px 12px"}} variant="soft"
                                        onClick={() => setWithdrawModalOpen(false)}>Cancel</Button>
                                <Button style={{borderRadius: "4px", padding: "4px 12px"}} color="green"
                                        onClick={openSafeWallet}>Proceed</Button>
                            </Box>

                        </AlmanakModalContent>
                    </Modal>}

            </>

            <>
                {isFundEoaVisible && walletDetails &&
                    <TopUpComponent
                        chainId={walletDetails.chain_id}
                        eoaWalletAddress={walletDetails.external_own_accounts[0].address}
                        onClose={() => setIsFundEoaVisible(false)}/>
                }
            </>


        </PageContentContainer>
    )
}
