import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LDKeyAgentPnL, LDKeyAgents } from "../../components/layout/Sidebar";
import { useAppState } from "../../state/AppStateProvider";
import { PageContentContainer } from "../styled/styled";
import { Box } from "@mui/material";

import {Flex, Text, Table, Button, Card, Spinner, Select as RadixSelect, Tabs} from "@radix-ui/themes";
import useSWR from "swr";
import { getLiveAgents, fetchAllAgentsPnl, getSupportedChains } from "../../api/hasura";
import { AgentStatus } from "../../components/AgentStatus";
import { formatMonetaryValue } from "../../utils/commonUtils";
import { ChainBadge } from "../wallets/ChainBadge";
import {Pencil1Icon} from "@radix-ui/react-icons";

// Mock data for dashboard
const mockDashboardData = {
    totalProfitLoss: 2500,
    activeAgents: 2,
    totalBalance: 22500,
};

export const DashboardMetric = ({ label, value, isProfitLoss = false, isUsdValue = false, isPercentage = false }: {
    label: string;
    value: string | number;
    isProfitLoss?: boolean;
    isUsdValue?: boolean;
    isPercentage?: boolean;
}) => {
    const valueColor = isProfitLoss
        ? Number(value) >= 0 ? "green" : "red"
        : undefined;

    return (
        <Flex style={{ marginRight: "24px" }}>
            <Flex direction="column" align="start" gap="2">
                <Text size="2" color="gray">{label}</Text>
                <Text size="6" weight="bold"
                      color={valueColor}>{isUsdValue ? "$" : ""}{formatMonetaryValue(value, { maxFractionDigits: 2 })}{isPercentage ? "%" : ""}</Text>
            </Flex>
        </Flex>
    );
};

const AgentsDashboard = () => (
    <Flex gap="4" wrap="wrap">
        {/*<DashboardMetric*/}
        {/*    label="Total Profit/Loss"*/}
        {/*    value={"0"}*/}
        {/*    isProfitLoss={true}*/}
        {/*    isUsdValue*/}
        {/*/>*/}
        {/*<DashboardMetric label="Active Agents" value={"-"}/>*/}
        {/*<DashboardMetric label="Total Balance" value={`-`}/>*/}
    </Flex>
);

interface Agent {
    id: string;
    name: string;
    status: string;
    artifact_id_version_id: string;
    strategy_version: string | null;
    created_at: string;
    updated_at: string;
    config: string;
    user_id: string;
}

const remapAgents = (agents: any) => {
    try {
        return agents?.map((agent: any) => {
            // Extract platformWallets, safeAccountName, and chainId
            const platformWallets = agent.config.agent_config.ALMANAK_PLATFORM_WALLETS;
            const chainId = platformWallets[0].CHAIN_ID;

            // Extract the first key from strategy_configs (dynamic key)
            const artifact = agent?.artifact_id_version?.artifact;
            const strategyVersion = agent?.artifact_id_version?.name;
            const strategyName = artifact?.name;

            // Return the remapped agent with additional fields
            return {
                ...agent, // Spread the original agent properties
                chainId, // Add chainId
                strategyName, // Add strategyName
                strategyVersion
            };
        });
    } catch (e) {
        console.error("failed to remap agents:", e);
        return [];
    }
}
export const AgentsOverview = () => {
    const navigate = useNavigate();
    const { featureFlags, setAgentIdCurrentlyBeingRenamed } = useAppState();


    const { data: supportedChains } = useSWR("supported-chains", getSupportedChains);


    // Sorting state
    const [sortField, setSortField] = useState<string>('created_at');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    // Filter state
    const [selectedStatus, setSelectedStatus] = useState<string>("ALL");
    const [selectedChain, setSelectedChain] = useState<string>("ALL");
    const [selectedTab, setSelectedTab] = useState<string>("active");


    const isArchived = selectedTab === "archived";
    const { data: agents, isLoading: isLoadingAgents } = useSWR<Agent[]>(`${selectedTab}-live-agents`, () => getLiveAgents(isArchived));


    const remappedAgents = remapAgents(agents);

    const isAgentsEnabled = featureFlags?.[LDKeyAgents];
    const isPnLEnabled = featureFlags?.[LDKeyAgentPnL];

    const { data: pnlData, isLoading: isLoadingPnl } = useSWR(
        isPnLEnabled ? "all-agents-pnl" : null,
        fetchAllAgentsPnl
    );

    const onTabChange = (value: string) => {

        if (value === "archived") {
            setSelectedStatus("ALL");
        }
        setSelectedTab(value);
    }

    const handleSort = (field: string) => {
        if (sortField === field) {
            // Toggle direction if clicking the same field
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // Set new field and reset to ascending (except for created_at which defaults to desc)
            setSortField(field);
            setSortDirection(field === 'created_at' ? 'desc' : 'asc');
        }
    };

    const filteredAndSortedAgents = useMemo(() => {
        if (!remappedAgents) return [];

        // First apply filters
        let filtered = [...remappedAgents];

        // Filter by status
        if (selectedStatus !== "ALL") {
            filtered = filtered.filter(agent => agent.status === selectedStatus);
        }

        // Filter by chain
        if (selectedChain !== "ALL") {
            filtered = filtered.filter(agent => agent.chainId === selectedChain);
        }

        return filtered.sort((a, b) => {
            let valueA, valueB;

            // Handle special cases for different columns
            switch (sortField) {
                case 'name':
                    valueA = a.name || 'Unnamed Agent';
                    valueB = b.name || 'Unnamed Agent';
                    break;
                case 'status':
                    valueA = a.status || '';
                    valueB = b.status || '';
                    break;
                case 'apy':
                    const apyA = pnlData?.data?.agents?.find((agent: any) => agent.id === a.id)?.apy24h || 0;
                    const apyB = pnlData?.data?.agents?.find((agent: any) => agent.id === b.id)?.apy24h || 0;
                    return sortDirection === 'asc' ? apyA - apyB : apyB - apyA;
                case 'pnl':
                    const pnlA = pnlData?.data?.agents?.find((agent: any) => agent.id === a.id)?.pnlInUsd || 0;
                    const pnlB = pnlData?.data?.agents?.find((agent: any) => agent.id === b.id)?.pnlInUsd || 0;
                    return sortDirection === 'asc' ? pnlA - pnlB : pnlB - pnlA;
                case 'strategy':
                    valueA = `${a.strategyName || ''} ${a.strategy_version || ''}`;
                    valueB = `${b.strategyName || ''} ${b.strategy_version || ''}`;
                    break;
                case 'chain':
                    valueA = a.chainId || '';
                    valueB = b.chainId || '';
                    break;
                case 'wallet':
                    valueA = a.safeAccountName || '';
                    valueB = b.safeAccountName || '';
                    break;
                case 'created_at':
                    valueA = new Date(a.created_at || 0).getTime();
                    valueB = new Date(b.created_at || 0).getTime();
                    return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
                default:
                    valueA = a[sortField] || '';
                    valueB = b[sortField] || '';
            }

            // String comparison for text fields
            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return sortDirection === 'asc'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            return 0;
        });
    }, [remappedAgents, sortField, sortDirection, selectedStatus, selectedChain, pnlData]);


    const handleDeployAgent = () => {
        navigate("/agents/deploy");
    };

    const onRenameAgent = (event: any, agentId: string) => {
        event.stopPropagation();
        setAgentIdCurrentlyBeingRenamed(agentId);
    }

    return <PageContentContainer>
        <Flex direction="column" gap="2">
            <Flex justify="between" align="center">
                <h2>Agents</h2>
                <Button onClick={handleDeployAgent}>Create Agent</Button>
            </Flex>
            {isPnLEnabled && pnlData?.data?.agents?.length > 0 && <AgentsDashboard />}


            <Tabs.Root style={{marginBottom: "8px"}} defaultValue="private" value={selectedTab}
                       onValueChange={(value: string) => onTabChange(value)}>
                <Tabs.List>
                    <Tabs.Trigger value="active">Active Agents</Tabs.Trigger>
                    <Tabs.Trigger value="archived">Archived Agents</Tabs.Trigger>
                </Tabs.List>
            </Tabs.Root>

            <Flex wrap="wrap" gap="3">
                {/* Status filter */}
                <RadixSelect.Root disabled={selectedTab === "archived"} value={selectedStatus} onValueChange={setSelectedStatus}>
                    <RadixSelect.Trigger placeholder="Filter by status" />
                    <RadixSelect.Content>
                        <RadixSelect.Item value="ALL">All Statuses</RadixSelect.Item>
                        <RadixSelect.Item value="PENDING">Pending</RadixSelect.Item>
                        <RadixSelect.Item value="DEPLOYING">Deploying</RadixSelect.Item>
                        <RadixSelect.Item value="RUNNING">Running</RadixSelect.Item>
                        <RadixSelect.Item value="NORMAL_PAUSE">Normal Pause</RadixSelect.Item>
                        <RadixSelect.Item value="PAUSE_IN_PROGRESS">Pause In Progress</RadixSelect.Item>
                        <RadixSelect.Item value="ERROR_PAUSE">Error Pause</RadixSelect.Item>
                        <RadixSelect.Item value="TEARDOWN_IN_PROGRESS">Teardown In Progress</RadixSelect.Item>
                        <RadixSelect.Item value="USER_TRIGGERED_TEARDOWN">User Triggered Teardown</RadixSelect.Item>
                        <RadixSelect.Item value="TERMINATED">Terminated</RadixSelect.Item>
                        <RadixSelect.Item value="EMERGENCY_STOP_IN_PROGRESS">Emergency Stop In Progress</RadixSelect.Item>
                        <RadixSelect.Item value="EMERGENCY_STOPPED">Emergency Stopped</RadixSelect.Item>
                    </RadixSelect.Content>
                </RadixSelect.Root>

                {/* Chain filter */}
                <RadixSelect.Root value={selectedChain} onValueChange={setSelectedChain}>
                    <RadixSelect.Trigger placeholder="Filter by chain" />
                    <RadixSelect.Content>
                        <RadixSelect.Item value="ALL">All Chains</RadixSelect.Item>
                        {supportedChains?.map(chain => (
                            <RadixSelect.Item key={chain.chain_id} value={chain.chain_id}>
                                {chain.name}
                            </RadixSelect.Item>
                        ))}
                    </RadixSelect.Content>
                </RadixSelect.Root>
            </Flex>


            {isLoadingAgents ? (
                <Flex direction="column" align="center" gap="3" py="8">
                    <Text size="3" weight="medium" color="gray">
                        Loading Agents
                    </Text>
                    <Spinner size="2" />
                </Flex>
            ) : (
                <Table.Root>
                    <Table.Header>
                        <Table.Row>
                            <Table.ColumnHeaderCell onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                                Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                                Status {sortField === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </Table.ColumnHeaderCell>
                            {isPnLEnabled && (
                                <>
                                    <Table.ColumnHeaderCell onClick={() => handleSort('apy')} style={{ cursor: 'pointer' }}>
                                        APY (24h) {sortField === 'apy' && (sortDirection === 'asc' ? '↑' : '↓')}
                                    </Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell onClick={() => handleSort('pnl')} style={{ cursor: 'pointer' }}>
                                        PnL (USD) {sortField === 'pnl' && (sortDirection === 'asc' ? '↑' : '↓')}
                                    </Table.ColumnHeaderCell>
                                </>
                            )}
                            <Table.ColumnHeaderCell onClick={() => handleSort('strategy')} style={{ cursor: 'pointer' }}>
                                Strategy {sortField === 'strategy' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell onClick={() => handleSort('chain')} style={{ cursor: 'pointer' }}>
                                Chain {sortField === 'chain' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell onClick={() => handleSort('created_at')} style={{ cursor: 'pointer' }}>
                                Created At {sortField === 'created_at' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </Table.ColumnHeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoadingAgents || isLoadingPnl ? (
                            <Table.Row>
                                <Table.Cell colSpan={7}>Loading agents...</Table.Cell>
                            </Table.Row>
                        ) : filteredAndSortedAgents.length > 0 ? (
                            filteredAndSortedAgents.map((agent: any) => {
                                const agentPnl = pnlData?.data?.agents?.find((a: any) => a.id === agent.id);
                                const apy = agentPnl?.apy24h || 0;
                                const pnl = agentPnl?.pnlInUsd || 0;

                                return (
                                    <Table.Row key={agent.id} onClick={() => navigate(`/agents/${agent.id}`)}
                                               style={{ cursor: 'pointer' }}>
                                        <Table.Cell>
                                            <Flex align={"center"} gap={"2"}>
                                                {agent.name || 'Unnamed Agent'}
                                            </Flex>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <AgentStatus status={agent.status} />
                                        </Table.Cell>
                                        {isPnLEnabled && (
                                            <>
                                                <Table.Cell>
                                                    {apy === 0 ? (
                                                        <Text>-</Text>
                                                    ) : (
                                                        <Text color={apy > 0 ? 'green' : 'red'}>
                                                            {apy.toFixed(2)}%
                                                        </Text>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {pnl === 0 ? (
                                                        <Text>-</Text>
                                                    ) : (
                                                        <Text color={pnl > 0 ? 'green' : 'red'}>
                                                            {pnl < 0 ? '-' : ''}${formatMonetaryValue(Math.abs(pnl), { maxFractionDigits: 2 })}
                                                        </Text>
                                                    )}
                                                </Table.Cell>
                                            </>
                                        )}
                                        <Table.Cell>{agent?.strategyName} ({agent?.strategy_version})</Table.Cell>
                                        <Table.Cell><ChainBadge chainId={agent.chainId} /></Table.Cell>
                                        <Table.Cell>{new Date(agent?.created_at).toLocaleDateString()}</Table.Cell>
                                    </Table.Row>
                                );
                            })
                        ) : (
                            <Table.Row>
                                <Table.Cell colSpan={8}>No agents found</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table.Root>
            )}
        </Flex>
    </PageContentContainer>
}
