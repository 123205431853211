import Safe from "@safe-global/protocol-kit";
import {
  ALMANAK_AGENT_ROLE_KEY,
  ALMANAK_AGENT_ROLE_NAME,
} from "./WalletCreationFlow";
import { applyMembers, applyTargets } from "zodiac-roles-sdk";
import { allow, apply } from "defi-kit/eth";

export const getApplyRoleTRX = async (
  zodiacModuleAddress: string,
  permissions?: any
) => {
  const EURE = "0xcB444e90D8198415266c6a2724b7900fb12FC56E";
  const USDC = "0xddafbb505ad214d7b80b1f830fccc89b60fb7a83";

  const perms = await allow.cowswap.swap({
    buy: [EURE, USDC],
    sell: [EURE, USDC],
  });
  //marcia add WETH and PENDLE permissions
  const mockPermissions = [
    await allow.uniswap_v3.deposit({
      tokens: ["WETH", "PENDLE"],
      fees: ["0.3%"],
    }),
  ];
  const deafaultPermissions = [ ...mockPermissions];

  //TODO: don't use default permissions. this is temporary as permissions returned by API does not work
  const usedPermissions = deafaultPermissions ?? permissions;

  const calls = await apply(ALMANAK_AGENT_ROLE_KEY, usedPermissions, {
    address: zodiacModuleAddress as `0x${string}`,
    mode: "replace",
    log: console.debug,
  });
  return calls;
};

export const createZodiacPermissioTxnWithPermission = async (
  zodiacModuleAddress: string,
  roleNameKey: `0x${string}`,
  chainId: any,
  mode: "replace" | "extend" | "remove",
  permissionTargets: any //json string "targets"
) => {

  const calls = await applyTargets(roleNameKey, permissionTargets, {
    chainId: chainId,
    address: zodiacModuleAddress as `0x${string}`,
    mode: mode,
  });
  return callsToTransactionsConvert(zodiacModuleAddress, calls);
};
function callsToTransactionsConvert(
  zodiacModuleAddress: string,
  calls: `0x${string}`[]
) {
  return calls.map((data) => ({
    to: zodiacModuleAddress,
    value: "0",
    data,
  }));
}
export const getDelegateRoleTRX = async (
  zodiacModuleAddress: string,
  chainId: any,
  roleName: string,
  members: `0x${string}`[]
) => {
  const calls = await applyMembers(ALMANAK_AGENT_ROLE_KEY, members, {
    chainId: chainId,
    address: zodiacModuleAddress as `0x${string}`,
    mode: "replace",
    currentMembers: [],
  });
  const transactions = calls.map((data) => ({
    to: zodiacModuleAddress,
    value: "0",
    data,
  }));

  return transactions;
};

export const delegateRole = async (
  provider: any,
  almanakEOAAddress: string,
  safeAddress: string,
  chainId: string
) => {
  if (!provider) {
    console.error("provider not initialized!");
    return;
  }

  console.log(
    "delegateRole start",
    provider,
    almanakEOAAddress,
    safeAddress,
    chainId
  );
  const protocolKit = await Safe.init({
    provider: provider,
    safeAddress: safeAddress,
  });

  const modules = await protocolKit.getModules();
  const delegateRolTrx = await getDelegateRoleTRX(
    modules[0],
    chainId,
    ALMANAK_AGENT_ROLE_NAME,
    [almanakEOAAddress as `0x${string}`]
  );


  /**
   * mock example grant permission to role while delegate role is created
   */

  //   const permissionTransactions = await createZodiacPermissioTxnWithPermission(
  //     modules[0],
  //     ALMANAK_AGENT_ROLE_KEY,
  //     chainId,
  //     "replace",
  //     [
  //       {
  //         address: "0x6b175474e89094c44da98b954eedeac495271d0f",
  //         clearance: 2,
  //         executionOptions: 0,
  //         functions: [
  //           {
  //             selector: "0x095ea7b3",
  //             executionOptions: 0,
  //             wildcarded: false,
  //             condition: {
  //               $$id: "0x00a50030000000000000000000000000bebc44782c7db0a1a60cb6fe97d0b483032ff1c7",
  //               paramType: 5,
  //               operator: 5,
  //               children: [
  //                 {
  //                   $$id: "0x0030000000000000000000000000bebc44782c7db0a1a60cb6fe97d0b483032ff1c7",
  //                   paramType: 1,
  //                   operator: 16,
  //                   compValue:
  //                     "0x000000000000000000000000bebc44782c7db0a1a60cb6fe97d0b483032ff1c7",
  //                 },
  //               ],
  //             },
  //           },
  //           {
  //             selector: "0xa9059cbb",
  //             executionOptions: 0,
  //             wildcarded: false,
  //             condition: {
  //               $$id: "0x00a50030000000000000000000000000bebc44782c7db0a1a60cb6fe97d0b483032ff1c7",
  //               paramType: 5,
  //               operator: 5,
  //               children: [
  //                 {
  //                   $$id: "0x0030000000000000000000000000bebc44782c7db0a1a60cb6fe97d0b483032ff1c7",
  //                   paramType: 1,
  //                   operator: 16,
  //                   compValue:
  //                     "0x000000000000000000000000bebc44782c7db0a1a60cb6fe97d0b483032ff1c7",
  //                 },
  //               ],
  //             },
  //           },
  //         ],
  //       },
  //     ]
  //   );
  // console.log("permissionTransactions", permissionTransactions);
  const transactions = [...delegateRolTrx];

  const safeTransaction = await protocolKit.createTransaction({
    transactions: transactions,
  });
  const txResponse = await protocolKit.executeTransaction(safeTransaction);

  return txResponse;
};
