import {Chip, darken} from '@mui/material';
import {Flex, Spinner, Text} from "@radix-ui/themes";
import React from "react";

const WalletStatus = ({wallet}: any) => {
    const getStatusColor = (status: string) => {
        switch (status) {
            case "PENDING":
                return "#FFFACD"; // Light Yellow
            case "SUBMITTED":
                return "#ADD8E6"; // Light Blue
            case "CREATED":
                return "#90EE90"; // Light Green
            case "READY":
                return "#32CD32"; // Medium Green
            case "IN_USE":
                return "#1E90FF"; // Dark Blue
            default:
                return "#D3D3D3"; // Default color for undefined statuses (Light Grey)
        }
    };

    const status = wallet?.status || 'unknown';
    const color = getStatusColor(status);
    const displayStatus = status.toLowerCase().replace(/_/g, ' ');
    const isLoading = status === 'SUBMITTED';

    return (
        <Flex
            style={{
                padding: '2px 8px',
                borderRadius: '4px',
                textTransform: "capitalize",
                backgroundColor: color,
                color: darken(color, 0.8),
                display: "flex",
                gap: "8px",
                alignItems: "center",
                width: "fit-content"
            }}>
            {isLoading && <Spinner size={"2"}/>}
            <Text size="2" style={{ whiteSpace: "nowrap" }}>
                {displayStatus}
            </Text>
        </Flex>
    );
};

export default WalletStatus;