import Box from "@mui/material/Box";
import {
    FullScreenContainer,
    FullScreenContainerSmall
} from "../simulation/create-simulation/setup-simulation/SetupSimulation";
import {AddCircleOutline, ArrowBackIosNew, EditOutlined} from "@mui/icons-material";
import {Button, TextField, Tooltip} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Divider from "@mui/material/Divider";
import HelpIcon from '@mui/icons-material/Help';
import {PanelBox} from "../simulation/create-simulation/styled";
import * as React from "react";
import FloatingControls from "../../components/FloatingControls";
import {
    AddWalletModal,
    WalletDetails
} from "../../modals/AddWalletModal";
import {useAppState} from "../../state/AppStateProvider";
import {useEffect, useState} from "react";
import {generateWallets, updateGroupSimulation} from "../../api/hasura";
import {StrategyContainer} from "../strategy/StrategyLibrary";
import LoadingButton from "@mui/lab/LoadingButton";
import {ModelContainer} from "../strategy/ModelContainer";
import {HoldingsTable} from "../../components/data-display/HoldingsTable";
import {TitleValueColumn} from "../../components/data-display/TitleValueColumn";


const defaultHoldings = [
    {mint: true, token: "ETH", amount: "10000000000000000000", displayAmount: "10"},
    {mint: true, token: "WETH", amount: "10000000000000000000", displayAmount: "10"},
    {mint: true, token: "USDT", amount: "10000000000", displayAmount: "10000"},
    {mint: true, token: "WBTC", amount: "1000000000", displayAmount: "10"}
];
export const AgentOverview = () => {
    const navigate = useNavigate();
    const {simulationId, id} = useParams();

    const {
        setSnackBar,
        selectedSimulation,
        selectedAgent,
        setSelectedAgent,
        setSelectedSimulation,
    } = useAppState();

    const isNewAgent = id === "new"

    const [isAddWalletLoading, setIsAddWalletLoading] = useState(false);
    const [isAddingAgent, setIsAddingAgent] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState<WalletDetails | null>(null);
    const [aliasError, setAliasError] = useState<string | null>(null);

    useEffect(() => {
        if (!selectedSimulation) {
            navigate(`/simulation/${simulationId}`, {replace: true});
        }

    }, [])

    const saveAgentChanges = async () => {
        if (!!aliasError) {
            setSnackBar({open: true, message: aliasError, severity: "error"})
            return;
        }

        if (!selectedSimulation?.id) {
            setSnackBar({open: true, message: "No simulation selected", severity: "error"})
            navigate(-1);
            return;
        }

        if (!selectedAgent?.address) {
            setSnackBar({open: true, message: "Please add a wallet", severity: "error"})
            return;
        }

        if (!selectedAgent?.class) {
            setSnackBar({open: true, message: "Please add a strategy", severity: "error"})
            return;
        }

        const params = Object.keys(selectedAgent.settings).map((key: any) => ({
            key,
            value: selectedAgent.settings[key]
        }));

        if (params.find((s: any) => s.value === undefined || s.value === null  || s.value === "")) {
            setSnackBar({open: true, message: "Please fill all strategy parameters", severity: "error"})
            return;
        }

        setIsAddingAgent(true);

        if (isNewAgent) {
            const newId = !selectedAgent.alias || selectedAgent.alias !== "" ? selectedAgent?.class + "-" + Math.random().toString(36).substring(7) : selectedAgent.alias;
            const updatedAgents = [...selectedSimulation?.frontend_state.agentConfigs, {
                ...selectedAgent,
                alias: selectedAgent?.alias?.trim() !== "" ? selectedAgent.alias : undefined,
                id: newId,
            }];
            const frontendState = {
                ...selectedSimulation?.frontend_state,
                selectedAgentConfigs: updatedAgents,
                agentConfigs: updatedAgents
            };

            const updatedSimulation = {
                ...selectedSimulation,
                frontend_state: frontendState
            }
            setSelectedSimulation(updatedSimulation);

            const res = await updateGroupSimulation(selectedSimulation?.id!, {frontend_state: frontendState});
            navigate(-1);
            setSelectedAgent(null);
            setSnackBar({open: true, message: "Agent created", severity: "success"})
        } else {
            const updatedAgents = selectedSimulation?.frontend_state.agentConfigs?.map(a => a.id === selectedAgent.id ?
                {
                    ...selectedAgent,
                    alias: selectedAgent?.alias?.trim() !== "" ? selectedAgent.alias : undefined,
                } : a
            );

            const frontendState = {
                ...selectedSimulation?.frontend_state,
                selectedAgentConfigs: updatedAgents,
                agentConfigs: updatedAgents
            }
            const updatedSimulation = {
                ...selectedSimulation,
                frontend_state: frontendState,
            }
            setSelectedSimulation(updatedSimulation);
            const res = await updateGroupSimulation(selectedSimulation?.id!, {frontend_state: frontendState});
            navigate(-1);
            setSelectedAgent(null);
            setSnackBar({open: true, message: "Agent updated", severity: "success"})
        }
        setIsAddingAgent(false);
    }

    const cancel = () => {
        navigate(-1);
        setSelectedAgent(null);
    }

    const addWallet = async () => {

        try {
            setIsAddWalletLoading(true);
            const newWalletRes = await generateWallets(1)
            const address = newWalletRes.data.data.generateManyWalletAddress.wallet_address_array[0]
            setSelectedAgent(prevState => ({
                ...prevState,
                address,
                holdings: defaultHoldings
            }));
            // setSelectedWallet({address, holdings: [{mint: false, token: "", amount: "0"}]})
        } catch (e: any) {
            setSnackBar({open: true, message: e.message, severity: "error"})
        } finally {
            setIsAddWalletLoading(false);
        }

    }

    const editWallet = () => {
        if (selectedAgent?.holdings && selectedAgent?.address) {
            setSelectedWallet({holdings: selectedAgent.holdings, address: selectedAgent.address})
        } else {
            console.error("No agent was selected. Incorrect / inconsistent state");
        }
    }

    const saveWalletChanges = (walletDetails: WalletDetails) => {
        setSelectedAgent(prevState => ({
            ...prevState,
            holdings: walletDetails.holdings,
            address: walletDetails.address,
        }));
        setSelectedWallet(null)
    };

    const browseStrategies = () => {
        setSelectedAgent(selectedAgent);
        navigate("strategies")
    }

    const updateAlias = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === "") {
        }
        setSelectedAgent(prevState => ({...prevState, alias: value}));
        if (value !== "") {
            const isUnique = selectedSimulation?.frontend_state.agentConfigs?.find(a => a.alias === e.target.value) === undefined;
            if (!isUnique) {
                setAliasError("An agent with such alias already exists");
            } else {
                setAliasError(null);
            }
        }
    }

    return <FullScreenContainerSmall>
        <Box>
            <Button variant="text"
                    sx={{px: 0}}
                    onClick={() => navigate(-1)}
                    startIcon={<ArrowBackIosNew/>}>Back</Button>
        </Box>

        <Box sx={{my: 2, display: "flex", justifyContent: "space-between"}}>
            <Box sx={{display: "flex", gap: 2}}>
                <span style={{
                    fontSize: "18px",
                    fontWeight: "700"
                }}>{isNewAgent ? "Create New Agent" : "Edit Agent"}</span>

                {!isNewAgent && <>
                    <Divider style={{height: "26px"}} orientation="vertical"/>
                    <span style={{fontSize: "18px"}}>ID: {id ?? "-"} </span>
                </>
                }
            </Box>
            <Box>
                <Tooltip
                    title="Agent is a representation of a user trading within the pool, and performing specified strategy.">
                    <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
                        <HelpIcon sx={{width: "20px", height: "20px"}}/>
                        <span style={{fontWeight: "bold", fontSize: "14px"}}>What's an agent</span>
                    </Box>
                </Tooltip>
            </Box>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
            <PanelBox sx={{p: 2}}>
                <Box>
                    <span style={{fontSize: "16px", fontWeight: "bold"}}>About</span>
                </Box>
                <Box sx={{display: "flex", py: 2, gap: 2}}>
                    <Box sx={{display: "flex", flexDirection: "column", width: "240px", gap: 1}}>
                        <span>Alias (optional)</span>
                        <TextField helperText={aliasError}
                                   error={!!aliasError} value={selectedAgent?.alias} onChange={updateAlias}
                                   size={"small"}/>
                    </Box>
                </Box>
            </PanelBox>

            <PanelBox sx={{p: 2}}>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                        <div style={{fontSize: "16px", fontWeight: "bold"}}>Step 1: Add wallet</div>
                        <Tooltip
                            title="The Wallet is the on-chain address the agent will use to execute blockchain transactions.">
                            <HelpIcon sx={{width: "20px", height: "20px"}}/>
                        </Tooltip>
                    </Box>
                    {selectedAgent?.address ?
                        <Button onClick={editWallet} variant="outlined" startIcon={<EditOutlined/>}>
                            Edit Wallet
                        </Button> :
                        <LoadingButton loading={isAddWalletLoading}
                                       onClick={addWallet}
                                       variant="outlined"
                                       startIcon={<AddCircleOutline/>}>
                            New Wallet
                        </LoadingButton>}
                </Box>
                {!selectedAgent?.address &&
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", py: 6, gap: 2}}>
                    No wallet added yet
                </Box>}
                {selectedAgent?.address &&
                <Box sx={{display: "flex", py: 2, flexDirection: "column"}}>
                    <TitleValueColumn title={"Wallet Address"} value={selectedAgent?.address}/>
                </Box>}

                <Box>
                    <HoldingsTable holdings={selectedAgent?.holdings} />
                </Box>
            </PanelBox>

            <PanelBox sx={{p: 2}}>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                        <div style={{fontSize: "16px", fontWeight: "bold"}}>Step
                            2: {selectedAgent?.class ? "Review" : "Add"} Strategy
                        </div>
                        <Tooltip
                            title="The Strategy is the behaviour of the Agent. It represents custom code implementing specific logic for the agent's strategy.">
                            <HelpIcon sx={{width: "20px", height: "20px"}}/>
                        </Tooltip>
                    </Box>
                    <Button onClick={browseStrategies} variant="outlined" startIcon={<AddCircleOutline/>}>
                        Browse Library
                    </Button>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", py: 6, gap: 2}}>
                    {selectedAgent?.class ?
                        <StrategyContainer strategyClass={selectedAgent?.class} settings={selectedAgent?.settings}
                                           isAdded/> :
                        <Box>No strategy selected yet</Box>}
                </Box>
            </PanelBox>

            {!!selectedAgent?.settings?.model_1_alias && <PanelBox sx={{p: 2}}>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                        <div style={{fontSize: "16px", fontWeight: "bold"}}>Step 3: Review Model</div>
                        <Tooltip
                            title="The Model is a mathematical tool at the service of the Strategy. It transforms inputs into outputs.">
                            <HelpIcon sx={{width: "20px", height: "20px"}}/>
                        </Tooltip>
                    </Box>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", py: 6, gap: 2}}>
                    <ModelContainer modelAlias={selectedAgent?.settings.model_1_alias}/>
                </Box>
            </PanelBox>}
        </Box>

        <FloatingControls primary={{
            title: isNewAgent ? "Create Agent" : "Save changes",
            onClick: saveAgentChanges,
            isLoading: isAddingAgent
        }}
                          secondary={{title: "Cancel", onClick: cancel}}/>

        {selectedWallet &&
        <AddWalletModal isOpen={!!selectedWallet}
                        data={selectedWallet}
                        onClose={() => setSelectedWallet(null)}
                        onSave={saveWalletChanges}
        />}
    </FullScreenContainerSmall>
}
