import Safe from "@safe-global/protocol-kit";
import {createZodiacPermissioTxnWithPermission} from "../views/wallets/safeFunctions";
import {ALMANAK_AGENT_ROLE_KEY} from "../views/wallets/WalletCreationFlow";
import {Eip1193Provider} from "@safe-global/protocol-kit/dist/src/types/safeProvider";

export const grantZodiacPermissions = async (safeAddress: string, provider: Eip1193Provider, chainId: string, permissions: any) => {

    try {

        const protocolKit = await Safe.init({
            provider: provider,
            safeAddress: safeAddress,
        });

        const modules = await protocolKit.getModules();
        const expectedZodiacModuleAddress = modules[0];

        const transactions = await createZodiacPermissioTxnWithPermission(
            expectedZodiacModuleAddress,
            ALMANAK_AGENT_ROLE_KEY,
            chainId,
            "replace",
            permissions
        );

        if (transactions.length === 0) {
            return {
                status: false, //meaning no on chain operations were executed
                message: "No transactions to execute",
                error: false, //not necessarily an error, if no permissions to grant will return this
            };
        }
        const safeTransaction = await protocolKit.createTransaction({
            transactions: transactions,
        });

        const txResponse = await protocolKit.executeTransaction(safeTransaction);

        return {
            status: true,
            message: "Permissions created and submitted on-chain",
            txResponse: txResponse,
            error: false,
        };
        // const delegatedRole = await delegateRole(null, "almanakEOAAddress", selectedWallet, "chainId");

    } catch (error: any) {
        throw new Error("Could not grant permissions. " + error?.message || "");
    }
};
