import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import {
  deployLiveAgent,
  terminateLiveAgent,
  redeployLiveAgent,
  getAgentTransactions,
  getLiveAgentById,
  pauseLiveAgent,
  stratTeardownLiveAgent,
  stopLiveAgent,
  getSupportedChains,
  getSafeWalletIdByAddress,
  getSafeTransactionHistory,
  fetchAgentPnl, unpauseAgent, getEthBalanceForWallet, getAgentMetrics, archiveAgent
} from "../../api/hasura";
import { PageContentContainer } from "../styled/styled";
import {
  Flex,
  Text,
  Button,
  Card,
  Tabs,
  Box,
  Callout,
  Spinner,
  Table,
  IconButton,
  DropdownMenu, Dialog,
} from "@radix-ui/themes";
import {
  ArrowLeftIcon,
  ExclamationTriangleIcon,
  CopyIcon,
  ExternalLinkIcon,
  PauseIcon,
  OpenInNewWindowIcon,
  ChevronDownIcon,
  PersonIcon,
  GearIcon,
  ReloadIcon,
  StopIcon,
  RocketIcon,
  PlayIcon,
  PlusIcon,
  Pencil1Icon, DotsVerticalIcon, ArchiveIcon,
} from "@radix-ui/react-icons";
import { useAppState } from "../../state/AppStateProvider";
import { AgentStatus } from "../../components/AgentStatus";
import { formatDateValue, formatMonetaryValue, formatPubKey } from "../../utils/commonUtils";
import { formatTokenAmount } from "../../utils/tokenUtils";
import {
  openTransaction,
  WalletDetails,
  WalletTransaction,
} from "../wallets/WalletDetails";
import { AgentLogs } from "./AgentLogs";
import AgentOrStrategyFilesReader from "./AgentFilesReader/AgentOrStrategyFilesReader";
import {LDKeyAgentPnL, LDKeyAgentTransactionsInsteadSafe} from "../../components/layout/Sidebar";

export interface Agent {
  id: string;
  name: string;
  status: string;
  artifact_id_version_id: string;
  created_at: string;
  archived: boolean;
  config: {
    agent_config: any;
  };
  pnl: {
    pnlInUsd: number;
    pnlInPercentage: number;
  };
  artifact_id_version: {
    artifact: {
      name: string;
      id: string;
    };
    id: string;
    name: string;
  };
}



export const AgentDetails = () => {
  const { setSnackBar, featureFlags, user, setAgentIdCurrentlyBeingRenamed } = useAppState();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isAgentActionLoading, setIsAgentActionLoading] = useState(false);
  const [isLogsFullscreen, setIsLogsFullscreen] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);

  const [selectedAgentAction, setSelectedAgentAction] = useState<{ title: string, action: () => void, description: string, details?: string } | null>(null);



  const isPnLEnabled = featureFlags?.[LDKeyAgentPnL];
  const isAgentTransactionsUsedInsteadOfSafeWallet = featureFlags?.[LDKeyAgentTransactionsInsteadSafe];


  const agentId = id as string;
  const userId = user?.id;

  const {
    data: agentDetails,
    isLoading,
    mutate: mutateAgent,
  } = useSWR<Agent>(agentId ? `agents/${agentId}` : null, () =>
    getLiveAgentById(agentId)
  );

  const walletAddressUsedByAgent =
      agentDetails?.config?.agent_config?.ALMANAK_PLATFORM_WALLETS?.[0]
          ?.SAFE_ACCOUNT_ADDRESS;



  const {
    data: agentMetrics
  } = useSWR(id && userId && walletAddressUsedByAgent? `agents/${id}/metrics` : null, () => getAgentMetrics(agentId, userId!, walletAddressUsedByAgent));

  const { data: pnlData, isLoading: isPnlLoading } = useSWR(
      id && isPnLEnabled ? `agent-${id}-pnl` : null,
      () => fetchAgentPnl(id as string)
  );

  const { data: agentTransactions, isLoading: isAgentTransactionsLoading } =
    useSWR(`agent-${id}-transactions`, () =>
      getAgentTransactions(id as string)
    );


  const { data: safeWallet } = useSWR(
    walletAddressUsedByAgent
      ? `safe-wallet-${walletAddressUsedByAgent}-id`
      : null,
    () => getSafeWalletIdByAddress(walletAddressUsedByAgent)
  );

  const safeWalletId = safeWallet?.id;
  const safeWalletChainId = safeWallet?.chain_id;
  const eoaAddress = safeWallet?.external_own_accounts?.[0]?.address;

  const {
    data: walletTransactions,
    isLoading: isTransactionsLoading,
    isValidating: isTransactionsValidating,
    mutate: mutateWalletTransactions,
  } = useSWR(
    safeWallet ? `wallets-${walletAddressUsedByAgent}-transactions` : null,
    () => getSafeTransactionHistory(walletAddressUsedByAgent, safeWalletChainId)
  );

  const { data: supportedChains } = useSWR(
    "supported-chains",
    getSupportedChains
  );

  const {
    data: EOAWalletGasWei
  } = useSWR(walletAddressUsedByAgent && safeWalletChainId && eoaAddress ? `EOAWalletGas-${walletAddressUsedByAgent}` : null, () => getEthBalanceForWallet(eoaAddress!, safeWalletChainId))


  const getBlockExplorerUrl = (chainId: string) => {
    const chain = supportedChains?.find((chain) => chain.chain_id === chainId);
    return chain?.block_explorer_url || "https://arbiscan.io"; // Default to Arbiscan
  };

  const toggleLogsFullscreen = () => {
    setIsLogsFullscreen(!isLogsFullscreen);
  };

  if (isLoading) {
    return (
      <PageContentContainer>
        <Flex direction="column" align="center" gap="3" py="8">
          <Text size="3" weight="medium" color="gray">
            Loading Agent Details
          </Text>
          <Spinner size="2" />
        </Flex>
      </PageContentContainer>
    );
  }

  if (!agentDetails) {
    return (
      <PageContentContainer>
        <Text>Agent not found</Text>
      </PageContentContainer>
    );
  }

  const onDeployAgent = async () => {
    try {
      setIsAgentActionLoading(true);

      const deployedAgent = await deployLiveAgent(agentDetails.id);

      if (deployedAgent.valid) {
        setSnackBar({
          open: true,
          message: deployedAgent.message,
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          message: `${deployedAgent.message.substr(0, 150)}...`,
          severity: "error",
        });
      }
    } catch (e) {
      console.error("Error deploying agent", e);
      setSnackBar({
        open: true,
        message: "Error deploying agent",
        severity: "error",
      });
    } finally {
      setIsAgentActionLoading(false);
      mutateAgent();
    }
  };
  const onRedeployAgent = async () => {
    try {
      setIsAgentActionLoading(true);
      const redeployedAgent = await redeployLiveAgent(agentDetails.id);
      if (redeployedAgent.valid) {
        setSnackBar({
          open: true,
          message: redeployedAgent.message,
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          message: `${redeployedAgent.message.substr(0, 150)}...`,
          severity: "error",
        });
      }
    } catch (e) {
      console.error("Error redeploying agent", e);
      setSnackBar({
        open: true,
        message: "Error redeploying agent",
        severity: "error",
      });
    } finally {
      setIsAgentActionLoading(false);
      mutateAgent();
    }
  };
  const onTerminateAgent = async () => {
    try {
      setIsAgentActionLoading(true);
      const terminatedAgent = await terminateLiveAgent(agentDetails.id);
      if (terminatedAgent.valid) {
        setSnackBar({
          open: true,
          message: terminatedAgent.message,
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          message: `${terminatedAgent.message.substr(0, 150)}...`,
          severity: "error",
        });
      }
    } catch (e) {
      console.error("Error terminating agent", e);
      setSnackBar({
        open: true,
        message: "Error terminating agent",
        severity: "error",
      });
    } finally {
      setIsAgentActionLoading(false);
      mutateAgent();
    }
  };
  const onPauseAgent = async () => {
    try {
      setIsAgentActionLoading(true);
      const pausedAgent = await pauseLiveAgent(agentDetails.id);
      if (pausedAgent.valid) {
        setSnackBar({
          open: true,
          message: pausedAgent.message,
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          message: `${pausedAgent.message.substr(0, 150)}...`,
          severity: "error",
        });
      }
    } catch (e) {
      console.error("Error pausing agent", e);
      setSnackBar({
        open: true,
        message: "Error pausing agent",
        severity: "error",
      });
    } finally {
      setIsAgentActionLoading(false);
      mutateAgent();
    }
  };
  const onUnpauseAgent = async () => {
    try {
      setIsAgentActionLoading(true);
      const unpausedAgent = await unpauseAgent(agentDetails.id);
      if (unpausedAgent.valid) {
        setSnackBar({
          open: true,
          message: unpausedAgent.message,
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          message: `${unpausedAgent.message.substr(0, 150)}...`,
          severity: "error",
        });
      }
    } catch (e: any) {
      console.error("Error unpausing agent agent", e);
      setSnackBar({
        open: true,
        message: "Error pausing agent:" + e.message,
        severity: "error",
      });
    } finally {
      setIsAgentActionLoading(false);
      mutateAgent();
    }
  };
  const onTeardownAgent = async () => {
    try {
      setIsAgentActionLoading(true);
      const teardownAgent = await stratTeardownLiveAgent(agentDetails.id);
      if (teardownAgent.valid) {
        setSnackBar({
          open: true,
          message: teardownAgent.message,
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          message: `${teardownAgent.message.substr(0, 150)}...`,
          severity: "error",
        });
      }
    } catch (e) {
      console.error("Error tearing down agent", e);
      setSnackBar({
        open: true,
        message: "Error tearing down agent",
        severity: "error",
      });
    } finally {
      setIsAgentActionLoading(false);
      mutateAgent();
    }
  };
  const onStopAgent = async () => {
    try {
      setIsAgentActionLoading(true);
      const stoppedAgent = await stopLiveAgent(agentDetails.id);
      if (stoppedAgent?.valid) {
        setSnackBar({
          open: true,
          message: stoppedAgent?.message,
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          message: `${stoppedAgent?.message?.substr(0, 150)}...`,
          severity: "error",
        });
      }
    } catch (e: any) {
      console.error("Error stopping agent", e);
      setSnackBar({
        open: true,
        message: e.message ?? "Error stopping agent",
        severity: "error",
      });
    } finally {
      setIsAgentActionLoading(false);
      mutateAgent();
    }
  };


  const agentActions = {
    "deploy": {
      title: "Deploy Agent",
      description: "This action deploys the agent and starts executing the strategy.",
      action: onDeployAgent,
    },
    "teardown": {
      title: "Teardown Agent",
      description: "This action initiates a clean teardown of the strategy.",
      details: "Changes config.json file (initiate_teardown=true)",
      action: onTeardownAgent,
    },
    "redeploy": {
      title: "Redeploy Agent",
      description: "This action restarts the machine and the strategy will resume its current state.",
      action: onRedeployAgent,
    },
    "terminate": {
      title: "Terminate Agent",
      description: "This action terminates the agent and releases the wallets.",
      action: onTerminateAgent,
    },
    "pause": {
      title: "Pause Agent",
      description: "This action pauses the agent at the end of its current sequence of actions",
      details: "Changes config.json file (pause_strategy=true)",
      action: onPauseAgent,
    },
    "resume": {
      title: "Resume Agent",
      description: "This action resumes the agent and it's actions",
      details: "Changes config.json file (pause_strategy=false)",
      action: onUnpauseAgent,
    },
    "stop": {
      title: "Stop Agent",
      description: "This action immediately shuts down the machine to halt all execution.",
      action: onStopAgent,
    },
  }

  const showRenameAgentModal = () => {
    setAgentIdCurrentlyBeingRenamed(agentId);
  }

  const toggleArchiving = async () => {

    setIsArchiving(true);

    try {
      const archivedAgent = await archiveAgent(agentDetails.id, agentDetails.archived);

      setSnackBar({
        open: true,
        message: `Agent ${agentDetails.archived ? "restored" : "archived"}`,
        severity: "success",
      });
      console.log("archivedAgent", archivedAgent);
    } catch (e: any) {
        console.error("Error archiving agent", e);
        setSnackBar({
            open: true,
            message: e.message,
            severity: "error",
        });
    } finally {
        setIsArchiving(false);
        mutateAgent();
    }
  }
  const onAgentAction = (action: string) => {
    // @ts-ignore
    setSelectedAgentAction(agentActions[action]);
  }

  const isTerminateVisible = agentDetails.status === "ERROR_PAUSE" ||
      agentDetails.status === "NORMAL_PAUSE" || agentDetails.status === "EMERGENCY_STOPPED";

  const isArchiveVisible = agentDetails.status === "TERMINATED" || agentDetails.status === "PENDING";

  const isRedeployVisible = agentDetails.status === "ERROR_PAUSE" || agentDetails.status === "EMERGENCY_STOPPED";


  const agentActionConfirmationModal = <Dialog.Root open={!!selectedAgentAction}>
    <Dialog.Content>
      <Dialog.Title>{selectedAgentAction?.title}</Dialog.Title>


      <Flex direction={"column"} gap={"1"} my="2" mt="4">
        <Text size={"3"} weight={"bold"}>Are you sure you want to {selectedAgentAction?.title}? </Text>
        <Text size={"3"}>{selectedAgentAction?.description}</Text>
        <Text my={"1"} size={"2"}>{selectedAgentAction?.details}</Text>
      </Flex>
      <Flex gap="3" justify="end">
        <Button onClick={() => {
            selectedAgentAction?.action();
            setSelectedAgentAction(null);
        }}>
            {selectedAgentAction?.title}
        </Button>
        <Button onClick={() => setSelectedAgentAction(null)} variant="soft" color="gray">
          Cancel
        </Button>
      </Flex>
    </Dialog.Content>
  </Dialog.Root>;


  return (
    <PageContentContainer>
      <Flex direction="column" gap="2">
        <Flex justify="between" align="center">
          <Flex gap={"16px"} align={"center"} justify={"between"} width={"100%"}>
            <Flex gap={"16px"} align={"center"}>
                <Button variant={"outline"} onClick={() => navigate("/agents")}>
                  <ArrowLeftIcon />
                  Back
                </Button>
                <Text size="6" weight="bold">
                  {agentDetails.name || "Unnamed Agent"}
                </Text>
                <div>
                    <AgentStatus status={agentDetails.status} />
                </div>

              {agentDetails?.archived && <AgentStatus status={"ARCHIVED"} />}
            </Flex>

            <Flex gap={"2"}>
              {agentDetails?.status !== "TERMINATED" && <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <Button loading={isAgentActionLoading} variant="soft">
                    Agent Actions
                    <ChevronDownIcon />
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content style={{ minWidth: "144px" }}>

                  {agentDetails.status === "PENDING" && (
                      <DropdownMenu.Item onClick={() => onAgentAction("deploy")} disabled={isAgentActionLoading}>
                          <RocketIcon />
                          Deploy Agent
                      </DropdownMenu.Item>)
                  }

                  <DropdownMenu.Item onClick={() => onAgentAction("teardown")} disabled={isAgentActionLoading}>
                    <GearIcon />
                    Teardown Agent
                  </DropdownMenu.Item>

                  {isRedeployVisible && (
                      <DropdownMenu.Item onClick={() => onAgentAction("redeploy")} disabled={isAgentActionLoading}>
                          <ReloadIcon />
                          Redeploy Agent
                      </DropdownMenu.Item>)
                  }

                  {isTerminateVisible && (
                        <DropdownMenu.Item onClick={() => onAgentAction("terminate")} disabled={isAgentActionLoading}>
                            <StopIcon />
                            Terminate
                        </DropdownMenu.Item>)
                  }

                  {agentDetails.status === "RUNNING" && (
                      <DropdownMenu.Item onClick={() => onAgentAction("pause")} disabled={isAgentActionLoading}>
                          <PauseIcon />
                          Pause Agent
                      </DropdownMenu.Item>)
                  }

                  {agentDetails.status === "NORMAL_PAUSE" && (
                      <DropdownMenu.Item onClick={() => onAgentAction("resume")} disabled={isAgentActionLoading}>
                          <PlayIcon />
                          Resume Agent
                      </DropdownMenu.Item>)
                  }

                  {<DropdownMenu.Item color={"red"} onClick={() => onAgentAction("stop")} disabled={isAgentActionLoading}>
                    <ExclamationTriangleIcon />
                          Emergency Stop
                   </DropdownMenu.Item>
                  }
                </DropdownMenu.Content>

              </DropdownMenu.Root>
              }

            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="soft" loading={isArchiving}>
                  <DotsVerticalIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content style={{ minWidth: "144px" }}>
                <DropdownMenu.Item onClick={showRenameAgentModal}>
                  Rename
                </DropdownMenu.Item>
                {isArchiveVisible && <DropdownMenu.Item color={agentDetails?.archived ? "green" : "red"} onClick={toggleArchiving}>
                  {agentDetails?.archived ? "Restore Agent" : "Archive Agent"}
                </DropdownMenu.Item>}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            </Flex>

          </Flex>

          <></>
        </Flex>

        {agentDetails.status === "PENDING" && (
          <Flex direction={"row"} align={"center"} gap={"2"}>
            <Callout.Root color="yellow">
              <Flex align={"center"} gap={"2"}>
                <Callout.Icon>
                  <ExclamationTriangleIcon />
                </Callout.Icon>
                <Callout.Text>
                  <Flex justify={"between"} align={"center"} gap={"8"}>
                    <span>Agent is pending deployment.</span>
                    <Button
                      color="indigo"
                      disabled={isAgentActionLoading}
                      onClick={onDeployAgent}
                    >
                      <RocketIcon />
                      Deploy Agent
                      {isAgentActionLoading && <Spinner />}
                    </Button>
                  </Flex>
                </Callout.Text>
              </Flex>
            </Callout.Root>
          </Flex>
        )}

        {agentDetails?.status !== "TERMINATED" && Number(EOAWalletGasWei) === 0 && <Flex width={"100%"}>
          <Callout.Root color="yellow">
            <Callout.Icon>
              <ExclamationTriangleIcon/>
            </Callout.Icon>
            <Callout.Text>
              <Flex gap={"8"}>
                <Text>
                  Externally Owned Wallet has no ETH in it. It is required to execute transactions.
                  Please top up the wallet.
                </Text>
              </Flex>
            </Callout.Text>
          </Callout.Root>


        </Flex>
        }

        {/*{(isAgentPaused) && (*/}
        {/*  <Flex direction={"row"} align={"center"}>*/}
        {/*    <Callout.Root*/}
        {/*      color={agentDetails.status === "ERROR_PAUSE" ? "red" : "yellow"}*/}
        {/*    >*/}
        {/*      <Flex align={"center"} gap={"2"}>*/}
        {/*        <Callout.Icon>*/}
        {/*          <ExclamationTriangleIcon />*/}
        {/*        </Callout.Icon>*/}
        {/*        <Callout.Text>*/}
        {/*          <Flex justify={"between"} align={"center"} gap={"8"}>*/}
        {/*            <span>*/}
        {/*              {agentDetails.status === "ERROR_PAUSE"*/}
        {/*                ? "Agent is in error paused state. You can terminate the agent to release wallets or redeploy to retry."*/}
        {/*                : "Agent is paused. You can terminate the agent or redeploy to resume operations."}*/}
        {/*            </span>*/}
        {/*            /!*<Flex gap="3">*!/*/}
        {/*            /!*  <Button*!/*/}
        {/*            /!*    color="red"*!/*/}
        {/*            /!*    disabled={isDeploying}*!/*/}
        {/*            /!*    onClick={onTerminateAgent}*!/*/}
        {/*            /!*  >*!/*/}
        {/*            /!*    Terminate*!/*/}
        {/*            /!*    {isDeploying && <Spinner />}*!/*/}
        {/*            /!*  </Button>*!/*/}
        {/*            /!*  <Button*!/*/}
        {/*            /!*    color="indigo"*!/*/}
        {/*            /!*    disabled={isDeploying}*!/*/}
        {/*            /!*    onClick={onRedeployAgent}*!/*/}
        {/*            /!*  >*!/*/}
        {/*            /!*    Redeploy*!/*/}
        {/*            /!*    {isDeploying && <Spinner />}*!/*/}
        {/*            /!*  </Button>*!/*/}
        {/*            /!*  </Flex>*!/*/}
        {/*            </Flex>*/}
        {/*          </Callout.Text>*/}
        {/*        </Flex>*/}
        {/*      </Callout.Root>*/}
        {/*    </Flex>*/}
        {/*  )}*/}
        {/*{agentDetails.status === "RUNNING" && (*/}
        {/*  <Flex direction="column" gap="2">*/}
        {/*    <Flex direction={"row"} align={"center"}>*/}
        {/*      <Callout.Root color="green">*/}
        {/*        <Flex align={"center"} gap={"8px"}>*/}
        {/*          <Callout.Icon>*/}
        {/*            <ExclamationTriangleIcon />*/}
        {/*          </Callout.Icon>*/}
        {/*          <Callout.Text>*/}
        {/*            <Flex justify={"between"} align={"center"} gap={"8"}>*/}
        {/*              <span>*/}
        {/*                Agent is running. You can pause the agent to stop*/}
        {/*                operations.*/}
        {/*              </span>*/}
        {/*              <Flex gap="3">*/}
        {/*                <Tooltip content="Trigger a safe strategy pause for the agent. The agent can be redeployed later to resume.">*/}
        {/*                  <Button*/}
        {/*                    variant="outline"*/}
        {/*                    color="indigo"*/}
        {/*                    disabled={isDeploying}*/}
        {/*                    onClick={onPauseAgent}*/}
        {/*                  >*/}
        {/*                    <PauseIcon />*/}
        {/*                    Pause Agent*/}
        {/*                    {isDeploying && <Spinner />}*/}
        {/*                  </Button>*/}
        {/*                </Tooltip>*/}
        {/*                <Tooltip content="Trigger a safe teardown of the agent and clean up all resources. This action cannot be undone.">*/}
        {/*                  <Button*/}
        {/*                    variant="outline"*/}
        {/*                    color="red"*/}
        {/*                    disabled={isDeploying}*/}
        {/*                    onClick={onTeardownAgent}*/}
        {/*                  >*/}
        {/*                    Teardown Agent*/}
        {/*                    {isDeploying && <Spinner />}*/}
        {/*                  </Button>*/}
        {/*                </Tooltip>*/}
        {/*              </Flex>*/}
        {/*            </Flex>*/}
        {/*          </Callout.Text>*/}
        {/*        </Flex>*/}
        {/*      </Callout.Root>*/}
        {/*    </Flex>*/}

        {/*    <Flex direction={"row"} align={"center"}>*/}
        {/*      <Callout.Root color="red">*/}
        {/*        <Flex align={"center"} gap={"8px"}>*/}
        {/*          <Callout.Icon>*/}
        {/*            <ExclamationTriangleIcon />*/}
        {/*          </Callout.Icon>*/}
        {/*          <Callout.Text>*/}
        {/*            <Flex justify={"between"} align={"center"} gap={"8"}>*/}
        {/*              <span>*/}
        {/*                This is an emergency stop button to shut down the live*/}
        {/*                agent instance.*/}
        {/*              </span>*/}
        {/*              <Tooltip content="Immediately stop the agent's machine instance. Complications may arise with stopping the machine instance. For safety, we recommend using the pause/teardown action instead.">*/}
        {/*                <Button*/}
        {/*                  color="red"*/}
        {/*                  disabled={isDeploying}*/}
        {/*                  onClick={onStopAgent}*/}
        {/*                >*/}
        {/*                  Stop Agent*/}
        {/*                  {isDeploying && <Spinner />}*/}
        {/*                </Button>*/}
        {/*              </Tooltip>*/}
        {/*            </Flex>*/}
        {/*          </Callout.Text>*/}
        {/*        </Flex>*/}
        {/*      </Callout.Root>*/}
        {/*    </Flex>*/}
        {/*  </Flex>*/}
        {/*)}*/}

        {!isLogsFullscreen && (
          <Card>
            <Flex direction="column" gap="4">
              <Flex justify="between" align="center">
                <Text size="2" color="gray">Agent Name</Text>
                <Flex gap={"2"} align={"center"}><Text size="2">{agentDetails?.name || 'Unnamed Agent'}</Text>
                  {/*<Button onClick={showRenameAgentModal} variant={"ghost"}><Pencil1Icon /></Button>*/}
                </Flex>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="2" color="gray">
                  Agent ID
                </Text>
                <Flex align="center" gap="2">
                  <Text size="2" style={{ fontFamily: "monospace" }}>
                    {agentDetails.id}
                  </Text>
                  <IconButton
                    size="1"
                    variant="outline"
                    onClick={() => {
                      navigator.clipboard.writeText(agentDetails.id);
                      setSnackBar({
                        open: true,
                        message: "Agent ID copied to clipboard",
                        severity: "success",
                      });
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Flex>
              </Flex>
              <Flex justify="between" align="center">
                <Text size="2" color="gray">
                  Strategy Version ID
                </Text>
                <Flex align="center" gap="2">
                  <Text size="2" style={{ fontFamily: "monospace" }}>
                    {agentDetails?.artifact_id_version?.artifact?.name} -{" "}
                    {agentDetails?.artifact_id_version?.name}
                  </Text>
                  <IconButton
                    size="1"
                    variant="outline"
                    onClick={() => {
                      navigate(
                        `/strategy-library/${agentDetails.artifact_id_version.artifact.id}?version=${agentDetails.artifact_id_version.name}`
                      );
                    }}
                  >
                    <ExternalLinkIcon />
                  </IconButton>
                </Flex>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="2" color="gray">
                  Created At
                </Text>
                <Text size="2">
                  {new Date(agentDetails.created_at).toLocaleString()}
                </Text>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="2" color="gray">
                  Status
                </Text>
                <AgentStatus status={agentDetails.status} />
              </Flex>
              {isPnLEnabled && (
                <>
                  <Flex justify="between" align="center">
                    <Text size="2" color="gray">
                      APY (24h)
                    </Text>
                    {isPnlLoading ? (
                      <Spinner size="1" />
                    ) : (
                      (() => {
                        const apy = pnlData?.data?.apy24h || 0;
                        return apy === 0 ? (
                          <Text size="2">-</Text>
                        ) : (
                          <Text
                            size="2"
                            color={apy > 0 ? 'green' : 'red'}
                          >
                            {apy.toFixed(2)}%
                          </Text>
                        );
                      })()
                    )}
                  </Flex>

                  <Flex justify="between" align="center">
                    <Text size="2" color="gray">
                      PnL (USD)
                    </Text>
                    {isPnlLoading ? (
                      <Spinner size="1" />
                    ) : (
                      (() => {
                        const pnl = pnlData?.data?.pnlInUsd || 0;
                        return pnl === 0 ? (
                          <Text size="2">-</Text>
                        ) : (
                          <Text
                            size="2"
                            color={pnl > 0 ? 'green' : 'red'}
                          >
                            {pnl < 0 ? '-' : ''}${formatMonetaryValue(Math.abs(pnl), { maxFractionDigits: 2 })}
                          </Text>
                        );
                      })()
                    )}
                  </Flex>
                </>
              )}
            </Flex>
          </Card>
        )}

        <Tabs.Root defaultValue="logs">
          {!isLogsFullscreen && <Tabs.List>
            {/*<Tabs.Trigger value="dashboard">Dashboard</Tabs.Trigger>*/}
            <Tabs.Trigger value="logs">Logs</Tabs.Trigger>
            {isAgentTransactionsUsedInsteadOfSafeWallet ?
                <Tabs.Trigger value="agent-transactions">Transactions</Tabs.Trigger> :
                <Tabs.Trigger value="transactions">Transactions</Tabs.Trigger>
            }
            <Tabs.Trigger value="troubleshooting">Troubleshooting</Tabs.Trigger>
            <Tabs.Trigger value="wallet">Wallet</Tabs.Trigger>
          </Tabs.List>}

          <Box pt={"1"}>
            <Tabs.Content value="dashboard">
              <Text>Dashboard content coming soon...</Text>
            </Tabs.Content>

            <Tabs.Content value="troubleshooting">
              <AgentOrStrategyFilesReader agentId={id} />
            </Tabs.Content>

            <Tabs.Content value="agent-transactions">
              {isTransactionsLoading ? (
                <>
                  <Spinner />
                  <Text>Loading transactions...</Text>
                </>
              ) : (
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColumnHeaderCell>Time</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        Transaction
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Gas Used</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        Transaction Hash
                      </Table.ColumnHeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {!agentTransactions?.transactions ||
                    agentTransactions.transactions.length === 0 ? (
                      <Table.Row>
                        <Table.Cell colSpan={4}>
                          No transactions found
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      agentTransactions.transactions.map((tx: any) => (
                        <Table.Row
                          style={{
                            cursor: "pointer",
                          }}
                          key={tx.hash}
                        >
                          <Table.Cell>
                            {new Date(
                              Number(tx.timeStamp) * 1000
                            ).toLocaleString()}
                          </Table.Cell>
                          <Table.Cell>
                            <Flex direction="column" gap="1">
                              <Text size="2" weight="medium">
                                {tx.functionName ?? "Contract Interaction"}
                              </Text>
                            </Flex>
                          </Table.Cell>
                          <Table.Cell>
                            {/* Use non-line breaking space so that ETH is on the same row as the digits*/}
                            {formatTokenAmount(Number(tx.gasUsed) / 1e9)}
                            {`\u00A0`}
                            {supportedChains?.find(
                              (chain) =>
                                chain.chain_id === agentTransactions.chainId
                            )?.currency || "ETH"}
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              variant="ghost"
                              onClick={() => {
                                const explorerUrl = getBlockExplorerUrl(
                                  agentTransactions.chainId
                                );
                                window.open(
                                  `${explorerUrl}/tx/${tx.hash}`,
                                  "_blank"
                                );
                              }}
                            >
                              {formatPubKey(tx.hash)}
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))
                    )}
                  </Table.Body>
                </Table.Root>
              )}
            </Tabs.Content>

            <Tabs.Content value="transactions">
              <Callout.Root color="blue">
                <Flex align={"center"} gap={"2"}>
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    <Flex justify={"between"} align={"center"} gap={"8"}>
                      <span>
                        These transactions are executed by the Safe wallet.
                      </span>
                    </Flex>
                  </Callout.Text>
                </Flex>
              </Callout.Root>

              {isTransactionsLoading ? (
                <Flex
                  align={"center"}
                  style={{ margin: "16px 8px" }}
                  gap={"8px"}
                >
                  Loading transactions... <Spinner />
                </Flex>
              ) : (
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>TX Hash</Table.ColumnHeaderCell>
                      {/*<Table.ColumnHeaderCell>User Address</Table.ColumnHeaderCell>*/}
                      <Table.ColumnHeaderCell>Chain</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Time</Table.ColumnHeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {!isTransactionsLoading &&
                    (!walletTransactions || walletTransactions.length === 0) ? (
                      <Table.Row>
                        <Table.Cell colSpan={5}>
                          No transactions found
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      walletTransactions?.map((tx: WalletTransaction) => (
                        <Table.Row
                          style={{
                            cursor: "pointer",
                          }}
                          key={tx.tx_hash}
                          onClick={() => openTransaction(tx)}
                        >
                          <Table.Cell>{tx.tx_name ?? "-"}</Table.Cell>
                          <Table.Cell>
                            {formatPubKey(tx.tx_hash)}{" "}
                            <IconButton ml={"2"} variant="ghost">
                              <OpenInNewWindowIcon />
                            </IconButton>
                          </Table.Cell>
                          {/*<Table.Cell>{formatPubKey(tx.user_address)}</Table.Cell>*/}
                          <Table.Cell>{tx.chain}</Table.Cell>
                          <Table.Cell>
                            {formatDateValue(new Date(tx.timestamp), true)}
                          </Table.Cell>
                        </Table.Row>
                      ))
                    )}
                  </Table.Body>
                </Table.Root>
              )}
            </Tabs.Content>

            <Tabs.Content value="logs">
              <AgentLogs
                agentId={id}
                isFullscreen={isLogsFullscreen}
                toggleFullscreen={toggleLogsFullscreen}
              />
            </Tabs.Content>

            <Tabs.Content value="wallet">
              {safeWalletId ? (
                <WalletDetails passedId={safeWalletId} isEmbedded />
              ) : (
                <Text>No wallet found</Text>
              )}
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      </Flex>
      {agentActionConfirmationModal}
    </PageContentContainer>
  );
};
