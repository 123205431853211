import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Card, Button, Text, Badge, Flex } from '@radix-ui/themes';
import { useAppState } from '../../state/AppStateProvider';
import { PanelBox } from "../simulation/create-simulation/styled";
import { Alert, TextField } from "@mui/material";
import { getApiKey, resetApiKey } from "../../api/hasura";
import { formatDateValue } from "../simulation/create-simulation/PoolDetails";
import {TitleValueColumn} from "../../components/data-display/TitleValueColumn";

// Moved this here from user-profile as the userProfile page will not be in use soon
export type ApiKey = {
    jwt: string;
    created_at: string;
    updated_at: string;
    user_id: string;
    active: boolean;
}

export default function ApiKeys() {
    const { user, setSnackBar } = useAppState();
    const [apiKey, setApiKey] = useState<ApiKey | null>(null);
    const [isRegeneratingKey, setIsRegeneratingKey] = useState(false);

    useEffect(() => {
        fetchApiKey();
    }, []);

    const fetchApiKey = async () => {
        const apiKey = await getApiKey();
        setApiKey(apiKey);
    };

    const regenerateKey = async () => {
        setIsRegeneratingKey(true);
        try {
            const newKey = await resetApiKey();
            setApiKey(newKey);
            setSnackBar({ open: true, message: "API key regenerated", severity: "success" });
        } catch (error) {
            setSnackBar({ open: true, message: "Failed to regenerate API key", severity: "error" });
        } finally {
            setIsRegeneratingKey(false);
        }
    };

    const copyApiKey = async () => {
        if (!apiKey?.jwt) {
            setSnackBar({ open: true, message: "No API key found", severity: "error" });
            return;
        }
        await navigator.clipboard.writeText(apiKey?.jwt);
        setSnackBar({ open: true, message: "Copied to clipboard" });
    };

    return (
        <Box sx={{ maxWidth: '740px' }}>
            <Box sx={{ my: 2, display: "flex", justifyContent: "space-between" }}>
                <Text size="5" weight="bold">API Keys</Text>
            </Box>
            <Card style={{ padding: '24px' }}>
                <Text size="4" weight="bold">API Key Management</Text>
                {apiKey?.active ? (
                    <Box sx={{ display: "flex", flexDirection: "column", py: 2, gap: 1 }}>
                        <Flex align="center" gap="2">
                            <Badge color="green">Active</Badge>
                            <Text size="2">Created {formatDateValue(apiKey?.created_at, true)}</Text>
                        </Flex>

                        <Box sx={{
                            display: "flex",
                            my: 2,
                            alignItems: "center",
                            gap: 1,
                            flexDirection: "row",
                            maxWidth: "656px"
                        }}>
                            <TextField
                                name="api-key"
                                fullWidth
                                label="API Key"
                                aria-readonly={true}
                                placeholder="API Key"
                                value={apiKey?.jwt ?? ""}
                            />
                            <Button onClick={copyApiKey} disabled={!apiKey?.jwt}>
                                Copy
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        flexWrap: "wrap",
                        gap: "8px 32px",
                        maxWidth: "655px"
                    }}>
                        <Box sx={{ my: 1, fontSize: "16px", fontWeight: "500" }}>
                            API Key is required for the use of the Almanak SDK.
                        </Box>
                        <Alert severity={"info"}>
                            Generating an API key will invalidate the previous key. Once the key is
                            generated, please save it, as we do not store the keys for security reasons.
                        </Alert>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                size="3"
                                loading={isRegeneratingKey}
                                onClick={regenerateKey}
                            >
                                Generate API Key
                            </Button>
                        </Box>
                    </Box>
                )}
            </Card>
        </Box>
    );
}
