import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LDKeyAgents } from "../../components/layout/Sidebar";
import { useAppState } from "../../state/AppStateProvider";
import { PageContentContainer } from "../styled/styled";
import { Box } from "@mui/material";
import { ethers } from "ethers";
import {
    Flex,
    Text,
    Button,
    Select,
    TextField,
    Callout,
    Spinner,
    Card,
    Tabs,
    Table,
    IconButton,
    Dialog, Badge,
} from "@radix-ui/themes";
import {
    createLiveAgent,
    deployLiveAgent,
    fetchArtifactFilesWithUrl, getAllWalletBalances,
    getArtifactLibraryItems, getEthBalanceForWallet,
    getSmartWallets, getStrategyVersionPresets,
    getSupportedChains,
} from "../../api/hasura";
import useSWR from "swr";
import { ChainBadge } from "../wallets/ChainBadge";
import {
  ArrowLeftIcon,
  ExclamationTriangleIcon,
  OpenInNewWindowIcon,
} from "@radix-ui/react-icons";
import CodeMirror from "@uiw/react-codemirror";

import { json } from "@codemirror/lang-json";
import ErrorBoundary from "../../components/error-handling/ErrorBoundary";
import {SupportedChain, WalletBalancesResponse} from "../wallets/WalletsOverview";
import {useWallets} from "@privy-io/react-auth";
import {grantZodiacPermissions} from "../../utils/zodiacUtils";
import { VersionSecurityStatus } from "../../widgets/StrategyLibraryWidget";

const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.textContent = styles;
document.head.appendChild(styleSheet);

const  TransactionWaitingModal = React.memo(
  ({
    txHash,
    choosenChain,
  }: {
    txHash: string;
    choosenChain: SupportedChain | null;
  }) => (
    <Dialog.Root open={true}>
      <Dialog.Content
        style={{
          maxWidth: 450,
          animation: "fadeIn 150ms ease-out",
        }}
      >
        <Flex direction="column" gap="3">
          <Dialog.Title>Transaction Pending </Dialog.Title>
            <Flex direction="row" gap="1" align={"center"}>
                <Spinner size="1" />
                <Text size="3" weight={"medium"}>Loading...</Text>
            </Flex>
          <Flex gap="3" align="center">



            <Flex direction="column" gap="2">
              <Text size={"3"}>
                Waiting for transaction to be included in the block.
              </Text>
              <Flex direction="column" gap="2">
                <Text size="2" color="gray">
                  Transaction Hash:
                </Text>




                <Text size="2" style={{ wordBreak: "break-all" }}>
                  {txHash}
                </Text>
                <div>
                    <IconButton
                        size="1"
                        variant="ghost"
                        onClick={() =>
                          window.open(
                            `${choosenChain?.block_explorer_url}/tx/${txHash}`,
                            "_blank"
                          )
                        }
                >
                        Open in Explorer
                  <OpenInNewWindowIcon />
                </IconButton>
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
);


const availableArbitrumConfigurations = [
    "MAGIC-WETH",
    "PENDLE-WETH",
    "WETH-ARB",
    "WETH-USDC",
    "XAI-WETH"
];

const availableBaseConfigurations = [
    "AIXBT-USDC",
    "WETH-AIXBT",
    "WETH-USDC"
];


const availableConfigurations = {
    "8453": availableBaseConfigurations, // Base Chain ID
    "42161": availableArbitrumConfigurations, // Arbitrum Chain ID
}

export const AgentDeployment = () => {
  const navigate = useNavigate();
  const { theme, featureFlags, provider, setSnackBar } = useAppState();
  const {wallets} = useWallets();

  const isAgentsEnabled = featureFlags?.[LDKeyAgents];

  const [agentName, setAgentName] = useState("");
  const [selectedStrategy, setSelectedStrategy] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);
  const [selectedStrategyVersion, setSelectedStrategyVersion] = useState<string | null>(null);
  const [availableConfigurationsForSelectedWallet, setAvailableConfigurationsForSelectedWallet] = useState<string[] | null>(null);
  const [selectedPool, setSelectedPool] = useState("");
  const [selectedPreset, setSelectedPreset] = useState("");
  const [agentConfigError, setAgentConfigError] = useState<string | null>(null);

  const [configFileUrl, setConfigFileUrl] = useState("");
  const [permissionsFileUrl, setPermissionsFileUrl] = useState("");
  const [environmentFileUrl, setEnvironmentFileUrl] = useState("");

  const [confirmationModal, setConfirmationModal] = useState<{title: string, onConfirm: () => void, description: string} | null>(null)

  const [selectedTab, setSelectedTab] = useState<"permissions" | "config" | "env">(
    "config"
  );

    const connectedWallet = wallets[0];

  const { data: artifactList, isLoading: isLoadingArtifacts } = useSWR(
    "strategy-templates",
    getArtifactLibraryItems
  );

    const {
        data: walletBalances,
        isLoading: isBalancesLoading,
    } = useSWR<WalletBalancesResponse>('wallet-balances', getAllWalletBalances);


    const artifactVersions = useMemo(() => {
    const artifact = artifactList?.find(
      (artifact: any) => artifact.id === selectedStrategy
    );
    return artifact?.artifact_versions ?? [];
  }, [selectedStrategy, artifactList]);


  const latestArtifactVersion = useMemo(() => {
    const artifact = artifactList?.find(
      (artifact: any) => artifact.id === selectedStrategy
    );
    return artifact?.latest_version_artifact;
  }, [selectedStrategy, artifactList]);

  const selectedArtifactVersion = useMemo(() => {
    const artifact = artifactList?.find(
      (artifact: any) => artifact.id === selectedStrategy
    );


    const latestVersion =
      artifact?.latest_version_artifact ?? artifact?.artifact_versions?.[artifact?.artifact_versions?.length - 1];

    if (selectedStrategyVersion) {
        const selectedVersion = artifact?.artifact_versions?.find(
            (version: any) => version.id === selectedStrategyVersion
        );
        return {
            ...selectedVersion,
            artifactId: artifact?.id,
            version: selectedVersion?.name,
            artifactName: artifact?.name,
            uri: selectedVersion?.uri,
        };
    }


    return {
      ...latestVersion,
      artifactId: artifact?.id,
      version: latestVersion?.name,
      artifactName: artifact?.name,
      uri: latestVersion?.uri,
    };
  }, [selectedStrategy, artifactList, selectedStrategyVersion]);


  const { data: artifactFilesWithUrl } = useSWR(
    selectedArtifactVersion ? `${selectedArtifactVersion?.id}-files` : null,
    () =>
      fetchArtifactFilesWithUrl(
        selectedArtifactVersion?.artifactName,
        selectedArtifactVersion?.version
      )
  );


  const {
      data: strategyVersionPresets,
      isLoading: isStrategyVersionPresetsLoading,
  } = useSWR(
      selectedArtifactVersion?.artifactId && selectedArtifactVersion?.version ? `artifact-presets-${selectedArtifactVersion?.artifactId}-${selectedArtifactVersion?.version}` : null,
      () => getStrategyVersionPresets(selectedArtifactVersion?.artifactId, selectedArtifactVersion?.version));


  const fileFetcher = async (fileUrl: string) => {
      const res = await fetch(fileUrl);
      return res.json();
  };

  const { data: configFileContents, isLoading: configFileContentsLoading } = useSWR(configFileUrl ? configFileUrl : null, () => fileFetcher(configFileUrl));

  const { data: permissionsFileContents, isLoading: permissionsFileContentsLoading } = useSWR(permissionsFileUrl ? permissionsFileUrl : null, () => fileFetcher(permissionsFileUrl));

  const { data: envFileContents, isLoading: envFileContentsLoading } = useSWR(environmentFileUrl ? environmentFileUrl : null, () => fileFetcher(environmentFileUrl));





  const [customConfiguration, setCustomConfiguration] = useState<any>(configFileContents);
  const [customPermissions, setCustomPermissions] = useState<string | null>(null);
  const [customEnvContents, setCustomEnvContents] = useState<string | null>(null);

  const {
    data: walletList,
    isLoading: isLoadingWallets,
    mutate: mutateWallets,
    isValidating: isWalletsValidating,
  } = useSWR(`READY-user-wallets`, () => getSmartWallets("ALL", "READY"));

  const selectedWalletDetails = useMemo(() => {
    return walletList?.find((wallet: any) => wallet.id === selectedWallet);
  }, [selectedWallet, walletList]);


    const filteredStrategyVersionPresets = useMemo(() => {
        const availablePresetsForWallet = strategyVersionPresets?.filter((preset: any) => preset.chainId === selectedWalletDetails?.chain_id);
        return availablePresetsForWallet;
    }, [strategyVersionPresets, selectedWallet]);

    const isAccessControlVisible = useMemo(() => {
    const ret = selectedWallet !== "" && selectedStrategy !== "";
    return ret;
  }, [selectedWallet, selectedStrategy, selectedPreset]);

  const isAgentReadyToDeploy = useMemo(() => {
    return selectedWallet !== "" && 
           selectedStrategy !== "" && 
           agentName !== "" && 
           !!selectedArtifactVersion && 
           !!selectedArtifactVersion.id;
  }, [selectedWallet, selectedStrategy, agentName, selectedPreset, selectedArtifactVersion]);

  const hasNoWallets = useMemo(() => {
    return !isLoadingWallets && (!walletList || walletList.length === 0);
  }, [isLoadingWallets, walletList]);

  const [isWaitingForTx, setIsWaitingForTx] = useState(false);
  const [currentTxHash, setCurrentTxHash] = useState<string>("");

  const { data: supportedChains } = useSWR(
    "supported-chains",
    getSupportedChains,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 3600000, // Cache for 1 hour (in milliseconds)
      // or
      staleTime: 3600000, // Consider data fresh for 1 hour
    }
  );
  const [choosenChain, setChoosenChain] = useState<SupportedChain | null>(null);

  const usedAgentConfiguration = customConfiguration ?? configFileContents;
  const usedAgentEnvironment = customEnvContents ?? envFileContents;
  const usedAgentPermissions = customPermissions || permissionsFileContents;

  console.log("selectedWalletDetails", selectedWalletDetails);

  const handleDeploy = async () => {
    try {
        const config = {
            agent_config: {
                ALMANAK_STRATEGY_PERMISSIONS: usedAgentPermissions,
                ALMANAK_STRATEGY_CONFIG: usedAgentConfiguration,
                ALMANAK_STRATEGY_ENV: usedAgentEnvironment,
                ALMANAK_PLATFORM_WALLETS: [
                    {
                        CHAIN_ID: selectedWalletDetails?.chain_id,
                        CHAIN_TYPE: "EVM",
                        EOA_ADDRESS:
                        selectedWalletDetails?.external_own_accounts?.[0].address,
                        SAFE_ACCOUNT_NAME: selectedWalletDetails.name,
                        SAFE_ACCOUNT_ADDRESS: selectedWalletDetails?.address,
                    },
                ],
            },
            strategy_gcs_location: selectedArtifactVersion.uri,
        };

        const strategyVersion = selectedArtifactVersion?.version;
        const agent = await createLiveAgent(
            agentName,
            selectedArtifactVersion?.id,
            config,
            strategyVersion
        );

        const deployedAgent = await deployLiveAgent(agent.id);

        setSnackBar({
            open: true,
            message: "Agent Deploying",
            severity: "success",
        });
        // After successful deployment, navigate back to the overview
        navigate(`/agents/${agent?.id}`);
    } catch (error: any) {
        throw new Error("Could not deploy agent. " + error?.message || "");
    }
  };

  const onPermissionsChange = (value: string) => {
      try {
          // Parse the string value back to an object since we need it as an object later
          const parsedPermissions = JSON.parse(value);
          setCustomPermissions(parsedPermissions);
      } catch (e) {
          // Optionally handle invalid JSON
          console.error("Invalid Permissions:", e);
          // setSnackBar({
          //       open: true,
          //       message: "Invalid Permissions",
          //       severity: "error",
          // });
      }
  }

  const onConfigChange = (value: string) => {
    try {
      setAgentConfigError(null);
      // Parse the string value back to an object since we need it as an object later
      const parsedConfig = JSON.parse(value);
      setCustomConfiguration(parsedConfig);
    } catch (e: any) {
      // Optionally handle invalid JSON
      console.error("Invalid JSON configuration:", e);
      setAgentConfigError(e.message);
    }
  };

  const onEnvChange = (value: string) => {
    setCustomEnvContents(value);
  };

  const validateAndSubmitAgent = async () => {

    if (!isAgentReadyToDeploy) {
      setSnackBar({
        open: true,
        message: "Please fill in all required fields",
        severity: "error",
      });
      return;
    }

    if (agentConfigError) {
        setSnackBar({
            open: true,
            message: "Invalid Agent configuration: " + agentConfigError,
            severity: "error",
        });
        return;
    }

      if (!usedAgentConfiguration || usedAgentConfiguration === "" || Object.keys(usedAgentConfiguration).length === 0) {
        setSnackBar({
            open: true,
            message: "Agent configuration is required!",
            severity: "error",
        });
        return;
    }

    if (!usedAgentPermissions || usedAgentPermissions === "" || Object.keys(usedAgentPermissions).length === 0) {
        setConfirmationModal({
           onConfirm: submitAgent,
           title: "No Permissions Defined",
           description: "You have not defined any permissions for this agent. Are you sure you want to deploy the agent?",
        });
        return;
    }

    setIsDeploying(true);

    const selectedWalletAddress = walletList?.find((wallet: any) => wallet.id === selectedWallet)?.address;

    const safeWalletBalance = walletBalances?.balances?.find(
        (balance) => balance?.address === selectedWalletAddress
    );


    const eoaWalletGasWei = await getEthBalanceForWallet(selectedWalletDetails?.external_own_accounts?.[0].address, selectedWalletDetails?.chain_id);

    const isEoaWalletEmpty = Number(eoaWalletGasWei) === 0;
    const isSafeWalletEmpty = safeWalletBalance?.usd_value === 0;

    if (isSafeWalletEmpty || isEoaWalletEmpty) {

        let title = "";
        let description = "";

        if (isSafeWalletEmpty && isEoaWalletEmpty) {
            title = "Both Safe and EOA wallets are empty!";
            description = "You have no funds in Safe wallet and EOA wallet has no ETH for gas! Make sure you have enough funds in your wallets to deploy the agent.";
        } else if (isSafeWalletEmpty) {
            title = "Safe wallet is empty!";
            description = "You have no funds in Safe wallet! Make sure you have enough funds in your Safe wallet to deploy the agent.";
        } else {
            title = "EOA wallet is empty!";
            description = "You have no funds in EOA wallet! Make sure you have enough funds in your EOA wallet to deploy the agent.";
        }

        setConfirmationModal({
            onConfirm: submitAgent,
            title,
            description
        });
        setIsDeploying(false);
        return;
    }

    submitAgent();
  }

  const grantAgentPermissions = async () => {


      //get the chain id from the selectedWalletDetails
      const chainId = selectedWalletDetails?.chain_id;
      //get the rpc url from the supportedChains
      const selectedChain = supportedChains?.find(
          (chain: any) => chain.chain_id === chainId
      );
      const rpcUrl = selectedChain?.rpc_url;

      setChoosenChain(selectedChain || null); // Handle undefined case by setting to null

      await connectedWallet?.switchChain(Number(chainId));

      const txResponse = await grantZodiacPermissions(selectedWalletDetails?.address, provider, selectedWalletDetails?.chain_id, usedAgentPermissions); // this deploy and grant permissions to the agent role

      if (txResponse.error === false && txResponse.status === true) {
          const txHash = txResponse.txResponse?.hash;
          //create a ethereum provider to call RPC to check if the tx is included in the block
          //TODO: allow context awareness on which chain we're on and use the correct RPC
          const rpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
          if (!txHash) {
              throw new Error("No transaction created when granting permissions.");
          }
          //wait for the tx to be included in the block
          const txReceipt = await waitForTransaction(txHash, rpcProvider);
          console.log("Transaction confirmed:", {
              blockNumber: txReceipt.blockNumber,
              hash: txReceipt.transactionHash,
          });
      }

  }
  const submitAgent = async () => {


    setIsDeploying(true);
    try {

      //optionally grant permissions if they are defined
      if (usedAgentPermissions) {
          await grantAgentPermissions();
      }

      //deploy agent
      await handleDeploy();
    } catch (e: any) {
      console.error("submitAgent error", e);
      setSnackBar({
        open: true,
        message: e.message,
        severity: "error",
      });
    } finally {
      setIsDeploying(false);
    }
  };

  const waitForTransaction = async (
    txHash: string,
    provider: ethers.providers.Provider,
    timeoutSeconds = 300
  ) => {
    setCurrentTxHash(txHash);
    setIsWaitingForTx(true);

    try {
      const startTime = Date.now();
      for (let i = 0; i < timeoutSeconds; i++) {
        const txReceipt = await provider.getTransactionReceipt(txHash);
        if (txReceipt?.status === 1) {
          // Ensure modal stays visible for at least 3 seconds
          const elapsedTime = Date.now() - startTime;
          if (elapsedTime < 3000) {
            await new Promise((resolve) =>
              setTimeout(resolve, 3000 - elapsedTime)
            );
          }
          return txReceipt;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      throw new Error("Transaction confirmation timeout");
    } finally {
      setIsWaitingForTx(false);
      setCurrentTxHash("");
    }
  };

// Update onStrategySelect to select the latest successful version if available
const onStrategySelect = (value: string) => {
  setCustomEnvContents("");
  setCustomPermissions(null);
  setCustomConfiguration(null);
  setSelectedPreset("");
  setConfigFileUrl("");
  setPermissionsFileUrl("");
  setEnvironmentFileUrl("");
  setSelectedStrategy(value);

  const artifact = artifactList?.find((artifact: any) => artifact.id === value);
  const successfulVersions = artifact?.artifact_versions?.filter(
    (version: any) => version.scan_status === "SECURITY_SCAN_SUCCESS"
  );

  if (successfulVersions?.length > 0) {
    // Select the latest successful version
    setSelectedStrategyVersion(successfulVersions[0].id);
  } else {
    // If no successful versions exist, clear the selection
    setSelectedStrategyVersion("");
    setSnackBar({
      open: true,
      message: "No security-verified versions are available. Please wait for security scanning to complete. It should be ready in a few minutes.",
      severity: "info",
    });
  }
};

  const onVersionSelect = (value: string) => {

      setSelectedStrategyVersion(value);
  }

  const onSelectWallet = (value: string) => {
      setSelectedWallet(value);


      const wallet = walletList?.find((wallet: any) => wallet.id === value);

      const chainId = wallet.chain_id as keyof typeof availableConfigurations;

      const availableConfigurationsForChain = availableConfigurations[chainId];


      setAvailableConfigurationsForSelectedWallet(availableConfigurationsForChain);
  }

  const onPresetSelect = (value: string) => {
        setSelectedPreset(value);

        //.configUrl from the preset object
      const preset = strategyVersionPresets?.find((preset: any) => preset.key === value);
        const configFile = preset?.configUrl;
        const permissionsFile = preset?.permissionsUrl;
        const envFile = preset?.envUrl;
        setConfigFileUrl(configFile);
        setPermissionsFileUrl(permissionsFile)
        setEnvironmentFileUrl(envFile)
  }


  const scanStatusMapping: Record<VersionSecurityStatus, { color: string; text: string }> = {
    [VersionSecurityStatus.SECURITY_SCAN_SUCCESS]: { color: 'green', text: 'Verified' },
    [VersionSecurityStatus.SECURITY_SCAN_FAIL]: { color: 'red', text: 'Failed Scan' },
    [VersionSecurityStatus.SCANNING]: { color: 'blue', text: 'Scanning...' },
    [VersionSecurityStatus.AWAITING_SECURITY_SCAN]: { color: 'gray', text: 'Awaiting Scan' }
  };


  return (
    <PageContentContainer>
      {isWaitingForTx && (
        <TransactionWaitingModal
          txHash={currentTxHash}
          choosenChain={choosenChain}
        />
      )}

      <Flex direction="column" gap="6" align={"center"}>
        <Flex justify="start" gap={"2"} align="center" width={"100%"}>
          <Button variant={"outline"} onClick={() => navigate("/agents")}>
            <ArrowLeftIcon />
            Back
          </Button>
          <Text size="6" weight="bold">
            Deploy New Agent
          </Text>
        </Flex>
        <Flex
          direction="column"
          gap="4"
          style={{ width: "100%", maxWidth: "800px" }}
        >
          <Flex direction="column" gap={"1"}>
            <Text size="2" weight="bold">Agent Name</Text>
            <TextField.Root
              placeholder="Enter Agent name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
            >
            </TextField.Root>
          </Flex>


          <Flex direction="column" gap={"1"}>
            <Text size="2" weight="bold">Select Strategy</Text>
            <Select.Root
              value={selectedStrategy}
              onValueChange={onStrategySelect}
            >
              <Select.Trigger placeholder="Select a strategy" />
              <Select.Content>
                {isLoadingArtifacts ? (
                  <Select.Item value="loading">Loading...</Select.Item>
                ) : (
                  artifactList?.map((artifact: any) => (
                    <Select.Item key={artifact.id} value={artifact.id}>
                      {artifact.name}
                    </Select.Item>
                  ))
                )}
              </Select.Content>
            </Select.Root>
          </Flex>

          {selectedStrategy && <Flex direction="column" gap={"1"}>
            <Text size="2" weight="bold">Select Strategy Version</Text>
            <Select.Root
              disabled={isLoadingArtifacts}
              value={selectedArtifactVersion?.id}
              onValueChange={onVersionSelect}
            >
              <Select.Trigger placeholder="Select a version" />
              <Select.Content>
                {artifactVersions?.map((artifact: any) => {
                  const isLatest = artifact.id === latestArtifactVersion?.id;
                  const status = artifact.scan_status as VersionSecurityStatus;
                  const { color: scanStatusBadgeColor, text: scanStatusText } = scanStatusMapping[status];

                  const isSelectable = status === VersionSecurityStatus.SECURITY_SCAN_SUCCESS;

                  return (
                    <Select.Item
                      key={artifact.id}
                      value={artifact.id}
                      disabled={!isSelectable}
                      style={{
                        opacity: isSelectable ? 1 : 0.6,
                        cursor: isSelectable ? 'pointer' : 'not-allowed'
                      }}
                    >
                      <Flex align="center" gap="2" justify="between" style={{ width: '100%' }}>
                        <span>{artifact.name}</span>
                        <Flex gap="2">
                          <Badge color={scanStatusBadgeColor as any}>
                            {scanStatusText}
                          </Badge>
                          {isLatest && <Badge color="green">Latest</Badge>}
                        </Flex>
                      </Flex>
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select.Root>

            {!artifactVersions?.some((v: any) => v.scan_status === 'SECURITY_SCAN_SUCCESS') && (
              <Callout.Root color="yellow">
                <Callout.Icon>
                  <ExclamationTriangleIcon />
                </Callout.Icon>
                <Callout.Text>
                  No security-verified versions are available. Please wait for security scanning to complete. It should be ready in a few minutes.
                </Callout.Text>
              </Callout.Root>
            )}
          </Flex>}

          {selectedStrategy && <Flex direction="column" gap={"1"}>
            <Text size="2" weight="bold">Select Wallet to be Used</Text>

              {hasNoWallets && (
                  <Callout.Root color="red">
                      <Callout.Icon>
                          <ExclamationTriangleIcon />
                      </Callout.Icon>
                      <Callout.Text>
                          No wallets ready to deploy! Please create and setup wallet
                          before deploying an agent.
                      </Callout.Text>
                  </Callout.Root>
              )}

            <Select.Root value={selectedWallet} onValueChange={onSelectWallet}>
            <Select.Trigger placeholder="Select a wallet" />
            <Select.Content>
              {isLoadingWallets ? (
                <Select.Item value="loading">Loading...</Select.Item>
              ) : (
                walletList?.map((wallet: any) => {
                    const walletBalance = walletBalances?.balances?.find(
                        (balance) => balance?.address === wallet?.address
                    );
                    return <Select.Item key={wallet.id} value={wallet.id}>
                            <Flex width="100%" align="center" gap="2">
                                {wallet.name}
                                <ChainBadge chainId={wallet.chain_id}/>
                                <Badge>${walletBalance?.usd_value?.toFixed(2)}</Badge>
                            </Flex>
                        </Select.Item>

                })
              )}
            </Select.Content>
          </Select.Root>
          </Flex>}

          {/*{selectedWallet && <Flex direction="column" gap={"1"}>*/}
          {/*    <Text size="2" weight="bold">Select Pool</Text>*/}
          {/*    <Select.Root*/}
          {/*        value={selectedPool}*/}
          {/*        onValueChange={onPoolSelect}*/}
          {/*    >*/}
          {/*        <Select.Trigger placeholder="Select a pool" />*/}
          {/*        <Select.Content>*/}
          {/*            {isLoadingArtifacts ? (*/}
          {/*                <Select.Item value="loading">Loading...</Select.Item>*/}
          {/*            ) : (*/}
          {/*                availableConfigurationsForSelectedWallet?.map((configuration: any) => (*/}
          {/*                    <Select.Item key={configuration} value={configuration}>*/}
          {/*                        {configuration}*/}
          {/*                    </Select.Item>*/}
          {/*                ))*/}
          {/*            )}*/}
          {/*        </Select.Content>*/}
          {/*    </Select.Root>*/}
          {/*</Flex>}*/}


            {selectedWallet && filteredStrategyVersionPresets?.length > 0 && <Flex direction="column" gap={"1"}>
                <Text size="2" weight="bold">Select Preset</Text>
                <Select.Root
                    value={selectedPreset}
                    onValueChange={onPresetSelect}
                >
                    <Select.Trigger placeholder="Select a preset" />
                    <Select.Content>
                        {isStrategyVersionPresetsLoading ? (
                            <Select.Item value="loading">Loading...</Select.Item>
                        ) : (
                            filteredStrategyVersionPresets?.map((preset: any) => (
                                <Select.Item key={preset.key} value={preset.key}>
                                    {preset.title}
                                </Select.Item>
                            ))
                        )}
                    </Select.Content>
                </Select.Root>
            </Flex>}

            {selectedWallet && !isStrategyVersionPresetsLoading && <>

                {strategyVersionPresets?.length === 0 && (
                <Callout.Root color="yellow">
                    <Callout.Icon>
                        <ExclamationTriangleIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        No presets are available for the selected strategy on <ChainBadge chainId={selectedWalletDetails?.chain_id} /> chain.
                    </Callout.Text>
                </Callout.Root>
                )}

                {filteredStrategyVersionPresets?.length === 0 && strategyVersionPresets?.length !== 0 && (
                    <Callout.Root color="yellow">
                        <Callout.Icon>
                            <ExclamationTriangleIcon />
                        </Callout.Icon>
                        <Callout.Text>
                            No presets are available for the selected strategy on <ChainBadge chainId={selectedWalletDetails?.chain_id} /> chain. There is a preset available on <ChainBadge chainId={strategyVersionPresets?.[0]?.chainId} /> {strategyVersionPresets?.length > 1 ? `and ${strategyVersionPresets?.length - 1} other chain(s)` : "chain"}.
                        </Callout.Text>
                    </Callout.Root>
                )}
            </>}


            {isAccessControlVisible && (
            <Tabs.Root
              defaultValue="config"
              value={selectedTab}
              onValueChange={(value: string) =>
                setSelectedTab(value as "permissions" | "config")
              }
            >
              <Tabs.List>
                <Tabs.Trigger value="config">
                  Configuration
                </Tabs.Trigger>
                <Tabs.Trigger value="permissions">
                    Permissions
                </Tabs.Trigger>
                <Tabs.Trigger value="env">
                    Environment
                </Tabs.Trigger>
              </Tabs.List>

              <Box style={{ marginTop: "16px" }}>
                <Tabs.Content value="permissions">

                {/*{!permissionsFileContentsLoading && selectedArtifactVersion && !permissionsFileContents && (*/}
                {/*    <Callout.Root color="yellow">*/}
                {/*        <Callout.Icon>*/}
                {/*            <ExclamationTriangleIcon />*/}
                {/*        </Callout.Icon>*/}
                {/*        <Callout.Text>*/}
                {/*            The strategy has no permissions file defined!*/}
                {/*        </Callout.Text>*/}
                {/*    </Callout.Root>*/}
                {/*)}*/}

                  {permissionsFileContentsLoading && <Spinner />}

                  {!permissionsFileContentsLoading && (
                    <ErrorBoundary>
                      <CodeMirror
                          onChange={onPermissionsChange}
                          value={JSON.stringify(
                          customPermissions ?? permissionsFileContents ?? {},
                          null,
                          2
                        )}
                        theme={theme}
                        extensions={[json()]}
                      />
                    </ErrorBoundary>
                  )}
                </Tabs.Content>

                <Tabs.Content value="config">
                    {configFileContentsLoading && <Spinner />}

                    {/*{!isConfigurationValid && (*/}
                    {/*    <Callout.Root color="red">*/}
                    {/*        <Callout.Icon>*/}
                    {/*            <ExclamationTriangleIcon />*/}
                    {/*        </Callout.Icon>*/}
                    {/*        <Callout.Text>*/}
                    {/*            The strategy has no configuration file, or the file is empty! You must provide a valid configuration to deploy an agent.*/}
                    {/*        </Callout.Text>*/}
                    {/*    </Callout.Root>*/}
                    {/*)}*/}

                  {!configFileContentsLoading && (
                    <CodeMirror
                      readOnly={false}
                      onChange={onConfigChange}
                      value={JSON.stringify(customConfiguration ?? configFileContents ?? {}, null, 2)}
                      theme={theme}
                      extensions={[json()]}
                    />
                  )}
                </Tabs.Content>

                  <Tabs.Content value="env">
                      {envFileContentsLoading && <Spinner />}

                      {/*{!envFileContentsLoading && !envFileContents && (*/}
                      {/*    <Callout.Root color="yellow">*/}
                      {/*        <Callout.Icon>*/}
                      {/*            <ExclamationTriangleIcon />*/}
                      {/*        </Callout.Icon>*/}
                      {/*        <Callout.Text>*/}
                      {/*            The strategy has no environment file, or the file is empty!*/}
                      {/*        </Callout.Text>*/}
                      {/*    </Callout.Root>*/}
                      {/*)}*/}

                      {!envFileContentsLoading && (
                          <CodeMirror
                              readOnly={false}
                              onChange={onEnvChange}
                              value={customEnvContents ?? envFileContents ?? ""}
                              theme={theme}
                          />
                      )}
                  </Tabs.Content>


              </Box>
            </Tabs.Root>
          )}

          <Button
            disabled={!isAgentReadyToDeploy || isDeploying}
            onClick={validateAndSubmitAgent}
          >
            Deploy Agent
            {isDeploying && <Spinner />}
          </Button>

          {/*<Button*/}
          {/*    onClick={handleDeploy}*/}
          {/*    disabled={hasNoWallets || !isAgentReadyToDeploy}*/}
          {/*>*/}
          {/*    Deploy Agent*/}
          {/*</Button>*/}
        </Flex>
      </Flex>

      {confirmationModal && (
          <Dialog.Root open={true}>
              <Dialog.Content>
                  <Dialog.Title>{confirmationModal.title}</Dialog.Title>
                  <Dialog.Description>{confirmationModal.description}</Dialog.Description>
                  <Flex justify={"end"} gap={"2"} mt={"4"}>
                      <Button
                          variant={"outline"}
                          onClick={() => {
                              setConfirmationModal(null);
                          }}
                      >
                          Cancel
                      </Button>
                      <Button
                          onClick={() => {
                              confirmationModal.onConfirm();
                              setConfirmationModal(null);
                          }}
                      >
                          Deploy Agent Anyway
                      </Button>
                  </Flex>
              </Dialog.Content>
          </Dialog.Root>
      )}
    </PageContentContainer>
  );
};
