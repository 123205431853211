import {IconButton} from "@radix-ui/themes";
import {FlexBox} from "../../views/simulation/create-simulation/styled";
import * as React from "react";
import {useAppState} from "../../state/AppStateProvider";

const SocialButtons = () => {

    const {theme} = useAppState();

    const openDiscord = () => {
        window.open("https://discord.gg/c4jY28WrEB", "_blank")
    }

    const openTelegram = () => {
        window.open("https://t.me/+G1O9NFuz-AAzYmQy", "_blank")
    }

    const openX = () => {
        window.open("https://x.com/Almanak__", "_blank")
    }

    const openZealy = () => {
        window.open("https://zealy.io/cw/almanak/invite/a-T3Bp1m77bDi0-S2gDyq", "_blank")
    }

    return (
        <FlexBox className={theme === "dark" ? "invert" : ""} width={"100%"}
                 sx={{justifyContent: "space-around", p: 2}}>

            <IconButton variant={"ghost"} onClick={openDiscord}>
                <img width={20} src={"/social/discord.png"}/>

            </IconButton>
            <IconButton variant={"ghost"} onClick={openTelegram}>
                <img width={20} src={"/social/telegram.png"}/>
            </IconButton>
            <IconButton variant={"ghost"} onClick={openX}>
                <img width={20} src={"/social/twitter.png"}/>
            </IconButton>

            {/*<IconButton variant={"ghost"} onClick={openZealy}>*/}
            {/*    <img width={20} src={"/social/zealy.png"}/>*/}
            {/*</IconButton>*/}
        </FlexBox>
    )
}
export default SocialButtons;
