import {CenteredColumnFlexBox} from "../../views/simulation/create-simulation/styled";
import * as React from "react";
import {Flex, Tooltip} from "@radix-ui/themes";
import {InfoCircledIcon} from "@radix-ui/react-icons";

type TitleValueColumnProps = {
    title: string;
    value: string | number | any;
    size?: string;
    explanation?: string;
}

export const TitleValueColumn = (props: TitleValueColumnProps) => {
    const {explanation, size, value, title} = props;
    return <CenteredColumnFlexBox sx={{paddingRight: 1, py: 1}}>
        <Flex>
            <span style={{fontSize: size ?? "14px", fontWeight: "bold"}}>{title}</span>
            {explanation && <Tooltip content={explanation}><InfoCircledIcon style={{marginLeft: "4px"}} /></Tooltip>}
        </Flex>
        <span style={{fontSize: size ?? "14px"}}>{value}</span>
    </CenteredColumnFlexBox>
}
