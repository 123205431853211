import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {GroupSimulation, startGroupSimulation} from "../../../api/hasura";
import {ErrorOutlineSharp} from "@mui/icons-material";
import {useState} from "react";
import {ESimulationStatus} from "../../../state/types";


export const SimulationFailedView = ({simulation}: { simulation: GroupSimulation }) => {

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [isRestarting, setIsRestarting] = useState(false);
    const onToggleShowDetails = () => {
        setIsDetailsOpen(prevState => !prevState);
    }

    const onRestartSimulation = async () => {
        if (simulation?.id && simulation?.status === ESimulationStatus.Failed) {
            setIsRestarting(true);
            const res = await startGroupSimulation(simulation.id);
            setIsRestarting(false);
        }
    };
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        py: 2,
        margin: "auto",
        textAlign: "center",
        gap: 2,
        marginTop: "120px"
    }}>
        <Box sx={{textAlign: "center"}}><ErrorOutlineSharp style={{width: "64px", height: "64px", color: "#D32F2F"}}/></Box>
        <span style={{color: "#D32F2F", fontSize: "20px", fontWeight: "bold"}}>Uh-oh! The simulation failed</span>
        <span style={{textAlign: "center", margin: "auto", maxWidth: "720px"}}>Your changes were saved but we could not load your results due to a technical issue on our end. Please try running the simulation again. If the issue keeps happening, contact support.</span>

        <Box sx={{my: 2, display: "flex", alignItems: "center", justifyContent: "center", gap: 2}}>
            {/*<LoadingButton loading={isRestarting} onClick={onRestartSimulation} startIcon={<RestartAlt/>} color="primary" variant={"contained"} size="large"> Try Again</LoadingButton>*/}
            <Button onClick={onToggleShowDetails} variant={"outlined"} size="large"> {isDetailsOpen ? "Hide Details" : "Show details"}</Button>
        </Box>

        {isDetailsOpen && <Box sx={{display: "flex", flexDirection: "column", gap: 2, textAlign: "start"}}>
            <div>
                <span style={{fontWeight: "bold"}}>status_reason: </span><br/>
                <span style={{whiteSpace: "pre-line", lineHeight: "32px"}}>{simulation.status_reason ?? "N/A"}</span>
            </div>
            <div><
                span style={{fontWeight: "bold"}}>system_notes: </span> <br/>
                <span style={{whiteSpace: "pre-line"}}>
                    {simulation.system_notes ?? "N/A"}
                </span>
            </div>
        </Box>
        }
    </Box>
}
