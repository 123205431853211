import {useAppState} from "../state/AppStateProvider";
import RenameWalletModal from "./RenameWalletModal";
import RenameAgentModal from "./RenameAgentModal";

const ModalManager = () => {
    const {walletIdCurrentlyBeingRenamed, agentIdCurrentlyBeingRenamed} = useAppState();

    return (
        <>
            {walletIdCurrentlyBeingRenamed && <RenameWalletModal walletId={walletIdCurrentlyBeingRenamed} />}
            {agentIdCurrentlyBeingRenamed && <RenameAgentModal agentId={agentIdCurrentlyBeingRenamed} />}
        </>
    );
}

export default ModalManager;
