import * as React from "react";
import {useEffect, useState} from "react";
import {PageContentContainer} from "../views/styled/styled";
import {CenteredRowFlexBox, FlexBox} from "../views/simulation/create-simulation/styled";
import {useNavigate} from "react-router-dom";
import {LDKeyStrategyLibraryMaintenance, useAppState} from "../state/AppStateProvider";
import useSWR from "swr";
import {createANewStrategy, getArtifactLibraryItems, getPublicArtifactLibraryItems} from "../api/hasura";
import Fuse from "fuse.js";
import {ArtifactItem} from "../views/strategy/ArtifactItem";
import Box from "@mui/material/Box";
import {LDKeyStrategyLibraryWidget, openAlmanakDocs} from "../components/layout/Sidebar";
import {Button, Callout, Card, Dialog, Flex, Spinner, Tabs, Text, TextField} from "@radix-ui/themes";
import {ExclamationTriangleIcon, MagnifyingGlassIcon, OpenInNewWindowIcon} from "@radix-ui/react-icons";
import FakeDoorButton from "../components/fake-door-button";

// Fuse.js configuration
const options = {
    includeScore: true,
    threshold: 0.3,
    findAllMatches: true,
    ignoreLocation: true,
    // Fields to search in
    keys: ['name', 'description'],
};

export type ArtifactFile = {
    id: string;
    uri: string
    authenticated_url: string;
    date_created: string;
    description: string;
}

export enum VersionSecurityStatus {
    AWAITING_SECURITY_SCAN = "AWAITING_SECURITY_SCAN",
    SCANNING = "SCANNING",
    SECURITY_SCAN_SUCCESS = "SECURITY_SCAN_SUCCESS",
    SECURITY_SCAN_FAIL = "SECURITY_SCAN_FAIL"
}

export type LibraryArtifactVersion = {
    artifact_files: ArtifactFile[],
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null }
    chain_id?: string;
    scan_status: VersionSecurityStatus;
}
export type LibraryArtifact = {
    latest_version_artifact: LibraryArtifactVersion,
    artifact_versions: LibraryArtifactVersion[],
    is_starred_by_user: boolean;
    latest_version_name?: string;
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    latest_public_version: string | null,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null },
    stars_count: number,
    artifact_stars: ArtifactStar[]
}

export type ArtifactStar = {
    artifact: LibraryArtifact;
    artifact_id: string;
    user_id: string;
    created_at: string;
    id: string;
}

export const StrategyLibraryWidget = () => {

    // const formController = useForm<StrategyLibraryForm>({
    //         defaultValues: {},
    //         reValidateMode: "onChange"
    //     }
    // );
    const navigate = useNavigate();

    const {featureFlags, user, setSnackBar} = useAppState();
    const isStrategyLibraryMaintenanceMode = featureFlags?.[LDKeyStrategyLibraryMaintenance];
    const isStrategyLibraryEnabled = featureFlags?.[LDKeyStrategyLibraryWidget];

    const [searchValue, setSearchValue] = useState("");
    const [selectedTab, setSelectedTab] = useState("private");
    const [searcher, setSearcher] = useState<Fuse<LibraryArtifact>>();
    const userId = user?.id;

    const [strategyName, setStrategyName] = useState("");
    const [strategyDescription, setStrategyDescription] = useState("");
    const [isCreatingStrategy, setIsCreatingStrategy] = useState(false);
    const [isInitialCreationModalOpen, setIsInitialCreationModalOpen] = useState(false);
    const [isCreateStrategyLoading, setIsCreateStrategyLoading] = useState(false);
    const [error, setError] = useState("");


    const {
        data: artifactList,
        isLoading,
    } = useSWR(userId ? "strategy-templates" : null, getArtifactLibraryItems)


    const {
        data: rawPublicArtifacts,
        isLoading: isPublicArtifactsLoading
    } = useSWR(userId ? "public-artifacts" : null, () => getPublicArtifactLibraryItems(userId!));

    const publicArtifacts = rawPublicArtifacts?.map((artifact: any) => ({
        ...artifact,
        is_public: true,
    })) || [];


    const openDetails = (strategy: LibraryArtifact) => {
        navigate(`/strategy-library/${strategy.id}`)
    }


    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onTabChange = (tab: string) => {
        setSelectedTab(tab);
        setSearchValue(""); // Reset search when changing tabs
    };

    // Initialize/update the searcher when artifactList changes
    useEffect(() => {
        if (artifactList) {
            setSearcher(new Fuse(artifactList, options));
        }
    }, [artifactList]);


    const onCreateStrategy = async () => {
        if (!strategyName.trim()) {
            setError("Strategy name is required.");
            return;
        }
        setError(""); // Clear error if valid

        setIsCreateStrategyLoading(true);
        try {
            const newStrat = await createANewStrategy(strategyName, strategyDescription);
            setSnackBar({message: "Strategy Created", severity: "success", open: true});
            navigate(`/strategy-library/${newStrat.id}`);
            setIsCreatingStrategy(false);

        } catch (e: any) {
            setSnackBar({message: e.message, severity: "error", open: true});
            console.error("Error creating strategy", e);
        } finally {
            setIsCreateStrategyLoading(false);
        }
    }

    const displayedArtifacts = React.useMemo(() => {
        if (!artifactList) return [];

        let baseList = artifactList;

        switch (selectedTab) {
            case 'private':
                baseList = artifactList.filter((artifact: LibraryArtifact) => artifact.author === user?.id);
                break;
            case 'starred':
                baseList = artifactList.filter((artifact: LibraryArtifact) =>
                    artifact.artifact_stars?.some(star => star.user_id === user?.id)
                );
                break;
            default:
                baseList = publicArtifacts;
        }

        if (searchValue && searcher) {
            const result = searcher.search(searchValue);
            return result
                .map(r => r.item)
                .filter(item => baseList.some((b: LibraryArtifact) => b.id === item.id));
        }

        return baseList;
    }, [publicArtifacts, artifactList, selectedTab, searchValue, searcher, user?.id]);


    const initialCreateStrategyModal = <Dialog.Root open={isInitialCreationModalOpen}>
        <Dialog.Content>
            <Dialog.Title>Create a new Strategy</Dialog.Title>

            <Flex direction={"column"} gap={"1"} my="2" mt="4">
                <Text weight="bold" size="4">Manual Creation</Text>
                <Text size={"3"}>Create new Agent Strategies using the Almanak SDK</Text>
                <div>
                    <Button onClick={() => {
                        setIsInitialCreationModalOpen(false);
                        setIsCreatingStrategy(true)
                    }}
                    >
                        Create Now
                    </Button>
                </div>

            </Flex>

            <Flex direction={"column"} gap={"1"} my="2" mt="6">
                <Text weight="bold" size="4">Using Natural Language</Text>
                <Text size={"3"}>Create new Agent Strategies using Artificial Intelligence</Text>

                <FakeDoorButton variant="outline" triggerTitle="Create with AI" featureKey="create-strategy-with-ai"/>


            </Flex>
            <Flex gap="3" justify="end">
                <Dialog.Close>
                    <Button onClick={() => setIsInitialCreationModalOpen(false)} variant="soft" color="gray">
                        Close
                    </Button>
                </Dialog.Close>
            </Flex>
        </Dialog.Content>
    </Dialog.Root>;


    // a modal with 2 input fields (strategy name and description)
    const strategyCreationModal = (
        <Dialog.Root open={isCreatingStrategy}>
            <Dialog.Content>
                <Dialog.Title>Create a new Strategy</Dialog.Title>

                <Flex direction={"column"} gap={"4"} mb={"4"}>
                    <TextField.Root
                        placeholder="Strategy name"
                        value={strategyName}
                        onChange={(e) => {
                            setStrategyName(e.target.value);
                            setError(""); // Clear error on input change
                        }}
                    />
                    {error && <Text color="red">{error}</Text>}

                    <TextField.Root
                        placeholder="Strategy description"
                        value={strategyDescription}
                        onChange={(e) => setStrategyDescription(e.target.value)}
                    />
                </Flex>

                <Flex gap="3" justify="end">
                    <Button
                        loading={isCreateStrategyLoading}
                        onClick={onCreateStrategy}
                        disabled={!strategyName.trim()} // Disable if empty
                    >
                        Create
                    </Button>
                    <Dialog.Close>
                        <Button onClick={() => setIsCreatingStrategy(false)} variant="soft" color="gray">
                            Close
                        </Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );

    return <PageContentContainer>


        <CenteredRowFlexBox sx={{justifyContent: "space-between"}}>
            <h2>Strategies</h2>

            <Button onClick={() => setIsInitialCreationModalOpen(true)}>Create Strategy</Button>
            {initialCreateStrategyModal}

            {strategyCreationModal}
        </CenteredRowFlexBox>

        <Box>

            {isStrategyLibraryMaintenanceMode &&
                <Callout.Root color="red">
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        Strategy Library is currently in maintenance mode. Unexpected errors may occur.
                    </Callout.Text>
                </Callout.Root>
            }



            <Tabs.Root style={{marginBottom: "8px"}} defaultValue="private" value={selectedTab}
                       onValueChange={(value: string) => onTabChange(value)}>
                <Tabs.List>
                    <Tabs.Trigger value="private">My strategies</Tabs.Trigger>
                    <Tabs.Trigger value="all">Public Strategies</Tabs.Trigger>
                    <Tabs.Trigger value="starred">Starred</Tabs.Trigger>
                </Tabs.List>
            </Tabs.Root>



            <Box sx={{my: 2, maxWidth: "420px"}}>
                <TextField.Root placeholder="Find strategies..." value={searchValue}
                                onChange={onSearch}>
                    <TextField.Slot>
                        <MagnifyingGlassIcon/>
                    </TextField.Slot>
                </TextField.Root>
            </Box>
            
            {displayedArtifacts?.length === 0 &&
                <Callout.Root color="blue">
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        No strategies found. Please try a different search.
                    </Callout.Text>
                </Callout.Root>
            }

        </Box>
        {isLoading && <FlexBox sx={{justifyContent: "center", alignItems: "center", gap: 2, m: 4}}>
            Loading Strategies <Spinner/>
        </FlexBox>
        }
        <Card style={{padding: 0}}>
            {displayedArtifacts?.map((artifact: LibraryArtifact) =>
                <ArtifactItem key={artifact?.id} artifact={artifact}/>
            )}
        </Card>
    </PageContentContainer>

}
