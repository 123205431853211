import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HandleReferral = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const inviteCode = urlParams.get('code');

        if (inviteCode) {
            localStorage.setItem('inviteCode', inviteCode);
        }
        navigate('/home');
    }, [navigate]);

    return null;
};

export default HandleReferral;
